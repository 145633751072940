import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

// Memoized form field components
const FormField = React.memo(({ label, type = "text", placeholder, name, value, onChange }) => (
  <div className="mb-4">
    <label className="block text-gray-400 text-sm mb-2">{label}</label>
    <input 
      type={type} 
      className="w-full bg-[#1C1C1E] text-white px-4 py-3 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#0A84FF] border border-white/10"
      placeholder={placeholder}
      name={name}
      value={value || ''}
      onChange={onChange}
    />
  </div>
));

const SelectFormField = React.memo(({ label, placeholder, name, value, onChange, options }) => (
  <div className="mb-4">
    <label className="block text-gray-400 text-sm mb-2">{label}</label>
    <select
      className="w-full bg-[#1C1C1E] text-white px-4 py-3 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#0A84FF] border border-white/10"
      name={name}
      value={value || ''}
      onChange={onChange}
    >
      <option value="">{placeholder}</option>
      {options?.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
    </select>
  </div>
));

function EditProfile() {
  const navigate = useNavigate();
  
  // Basic state
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [bannerPhoto, setBannerPhoto] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showBannerModal, setShowBannerModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Professional account state
  const [showProfessionalFields, setShowProfessionalFields] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [professionalDetails, setProfessionalDetails] = useState({
    country: '',
    mobileNumber: '',
    businessType: '',
    businessCategory: '',
    storeAddress: '',
    pickupAddressLine1: '',
    pickupAddressLine2: '',
    city: '',
    state: '',
    pincode: '',
    gstin: '',
    accountHolderName: '',
    accountNumber: '',
    bankName: '',
    ifscCode: ''
  });

  // File input refs
  const profileInputRef = useRef(null);
  const bannerInputRef = useRef(null);

  // Constants
  const defaultProfilePhoto = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666666'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z'/%3E%3C/svg%3E";
  const businessTypes = [
    "Individual",
    "Company",
    "Partnership",
    "Proprietorship"
  ];

  const categories = [
    "Electronics & Accessories",
    "Clothing & Fashion",
    "Home & Kitchen",
    "Books & Media",
    "Beauty & Personal Care",
    "Sports & Outdoors",
    "Toys & Games",
    "Automotive",
    "Health & Wellness",
    "Jewelry & Watches",
    "Pet Supplies",
    "Arts & Crafts",
    "Food & Beverages",
    "Office Products",
    "Musical Instruments"
  ];

  const countries = [
    "India",
    "United States",
    "United Kingdom"
  ];

  // Handlers
  const handleProfessionalDetailsChange = useCallback((field, value) => {
    setProfessionalDetails(prev => ({
      ...prev,
      [field]: value
    }));
  }, []);

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhoto(reader.result);
        setShowProfileModal(false);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  };

  const handleBannerUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPhoto(reader.result);
        setShowBannerModal(false);
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  };

  const removeProfilePhoto = () => {
    setProfilePhoto(null);
    setShowProfileModal(false);
    if (profileInputRef.current) {
      profileInputRef.current.value = '';
    }
  };

  const removeBannerPhoto = () => {
    setBannerPhoto(null);
    setShowBannerModal(false);
    if (bannerInputRef.current) {
      bannerInputRef.current.value = '';
    }
  };

  const handleToggleProfessional = () => {
    setShowProfessionalFields(!showProfessionalFields);
  };
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const canSwitchToProfessional = () => {
    if (!showProfessionalFields) return false;
    
    const requiredFields = [
      professionalDetails.country,
      professionalDetails.mobileNumber,
      professionalDetails.businessType,
      professionalDetails.businessCategory,
      professionalDetails.storeAddress,
      professionalDetails.pickupAddressLine1,
      professionalDetails.city,
      professionalDetails.state,
      professionalDetails.pincode,
      professionalDetails.accountHolderName,
      professionalDetails.accountNumber,
      professionalDetails.bankName,
      professionalDetails.ifscCode
    ];

    return requiredFields.every(field => field.trim() !== '') && agreeToTerms;
  };

  const handleCancel = () => {
    navigate(-1);
  };

  // Save handler from the second file
  const handleSave = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const auth = getAuth();
      // Wait for auth state to be ready
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });
      
      if (!auth.currentUser?.email) {
        throw new Error('Please sign in to continue');
      }
      const email = auth.currentUser?.email;
      
      if (!email) {
        throw new Error('User not authenticated');
      }
  
      // Create JSON payload instead of FormData
      const jsonData = {
        email,
        name,
        username,
        description,
        category,
        // Convert base64 image data if available
        profileimage: profilePhoto || null,
        bannerimage: bannerPhoto || null
      };
  
      const response = await fetch(`${backendURL}/userdetail/edit_images`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update profile');
      }
  
      const data = await response.json();
      console.log('Profile updated successfully:', data);
  
    } catch (err) {
      setError(err.message);
      console.error('Error updating profile:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Rest of the component remains the same as the first file
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-[#1C1C1E]/60 backdrop-blur-xl border-b border-white/[0.08]">
        <div className="flex items-center justify-between px-4 h-14">
          <button 
            onClick={handleCancel}
            className="text-[#ED234A]"
          >
            Cancel
          </button>
          <h1 className="text-lg font-semibold text-[#ED234A]">Edit Profile</h1>
          <button 
            onClick={handleSave}
            disabled={isLoading || showProfessionalFields}
            className={`font-medium ${showProfessionalFields || isLoading ? 'text-gray-500 cursor-not-allowed' : 'text-[#ED234A]'}`}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>

      {/* Error Display */}
      {error && (
        <div className="px-4 py-2 bg-red-500/20 text-red-500 rounded-lg mt-4 mx-4">
          {error}
        </div>
      )}

      {/* Content */}
      <div className="p-4">
        {/* Banner Photo */}
        <div className="relative mb-16">
          <div 
            className="w-full h-32 rounded-xl bg-[#1C1C1E] overflow-hidden"
            onClick={() => setShowBannerModal(true)}
          >
            {bannerPhoto ? (
              <img src={bannerPhoto} alt="Banner" className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <svg className="w-8 h-8 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" 
                  />
                </svg>
              </div>
            )}
          </div>

          {/* Profile Photo */}
          <div 
            className="absolute left-4 bottom-0 transform translate-y-1/2"
            onClick={() => setShowProfileModal(true)}
          >
            <div className="w-24 h-24 rounded-full bg-[#1C1C1E] border-4 border-black overflow-hidden">
              {profilePhoto ? (
                <img src={profilePhoto} alt="Profile" className="w-full h-full object-cover" />
              ) : (
                <img src={defaultProfilePhoto} alt="Default Profile" className="w-full h-full" />
              )}
            </div>
          </div>
        </div>

        {/* Basic Info */}
        <div className="space-y-4 mb-8">
          <FormField
            label="Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
          />
          
          <FormField
            label="Username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Enter your username"
          />

          {/* Professional Account Toggle */}
          <div className="py-4">
            <button
              onClick={handleToggleProfessional}
              className="w-full flex items-center justify-between p-4 bg-[#1C1C1E] rounded-xl border border-white/10"
            >
              <span className="text-white">Switch to Professional/Seller Account</span>
              <div className={`w-10 h-6 rounded-full p-1 transition-colors duration-200 ease-in-out ${showProfessionalFields ? 'bg-[#ED234A]' : 'bg-gray-600'}`}>
                <div className={`w-4 h-4 rounded-full bg-white transform transition-transform duration-200 ease-in-out ${showProfessionalFields ? 'translate-x-4' : 'translate-x-0'}`} />
              </div>
            </button>
          </div>

          {/* Professional Account Fields */}
          {showProfessionalFields && (
            <div className="space-y-6 border border-white/10 rounded-xl p-4 bg-[#1C1C1E]">
              <h3 className="text-lg font-semibold mb-4">Professional Account Details</h3>
              
              {/* Profile Description */}
              <div className="mb-6">
                <FormField
                  label="Profile Description"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Tell us about yourself or your business"
                />
                <p className="text-white/60 text-sm mt-2">This description will be visible on your profile page</p>
              </div>

              {/* Basic Details */}
              <SelectFormField
                label="Country"
                name="country"
                value={professionalDetails.country}
                onChange={(e) => handleProfessionalDetailsChange('country', e.target.value)}
                options={countries}
                placeholder="Select your country"
              />

              <FormField
                label="Mobile Number"
                name="mobileNumber"
                value={professionalDetails.mobileNumber}
                onChange={(e) => handleProfessionalDetailsChange('mobileNumber', e.target.value)}
                placeholder="+91"
                type="tel"
              />

              <h4 className="text-white/60 text-sm font-medium mt-6 mb-4">Business Information</h4>

              <SelectFormField
                label="Business Type"
                name="businessType"
                value={professionalDetails.businessType}
                onChange={(e) => handleProfessionalDetailsChange('businessType', e.target.value)}
                options={businessTypes}
                placeholder="Select Business Type"
              />

              <SelectFormField
                label="Category"
                name="businessCategory"
                value={professionalDetails.businessCategory}
                onChange={(e) => handleProfessionalDetailsChange('businessCategory', e.target.value)}
                options={categories}
                placeholder="Select Category"
              />

              {/* Address Information */}
              <h4 className="text-white/60 text-sm font-medium mt-6 mb-4">Store Address</h4>

              <FormField
                label="Store Name"
                name="storeAddress"
                value={professionalDetails.storeAddress}
                onChange={(e) => handleProfessionalDetailsChange('storeAddress', e.target.value)}
                placeholder="Enter your store name"
              />

              <FormField
                label="Pickup Address Line 1"
                name="pickupAddressLine1"
                value={professionalDetails.pickupAddressLine1}
                onChange={(e) => handleProfessionalDetailsChange('pickupAddressLine1', e.target.value)}
                placeholder="Line 1"
              />

              <FormField
                label="Pickup Address Line 2"
                name="pickupAddressLine2"
                value={professionalDetails.pickupAddressLine2}
                onChange={(e) => handleProfessionalDetailsChange('pickupAddressLine2', e.target.value)}
                placeholder="Line 2"
              />

              <FormField
                label="City"
                name="city"
                value={professionalDetails.city}
                onChange={(e) => handleProfessionalDetailsChange('city', e.target.value)}
                placeholder="Enter your city"
              />

              <FormField
                label="State"
                name="state"
                value={professionalDetails.state}
                onChange={(e) => handleProfessionalDetailsChange('state', e.target.value)}
                placeholder="Enter your state"
              />

              <FormField
                label="Pincode"
                name="pincode"
                value={professionalDetails.pincode}
                onChange={(e) => handleProfessionalDetailsChange('pincode', e.target.value)}
                placeholder="Enter your pincode"
                type="number"
              />

              {/* GSTIN */}
              <div className="mb-6">
                <p className="text-white/60 text-sm mb-4">GSTIN is optional, we don't believe in paperwork.</p>
                <FormField
                  label="GSTIN"
                  name="gstin"
                  value={professionalDetails.gstin}
                  onChange={(e) => handleProfessionalDetailsChange('gstin', e.target.value)}
                  placeholder="Enter your GSTIN"
                />
              </div>

              {/* Bank Details */}
              <h4 className="text-white/60 text-sm font-medium mt-6 mb-4">Bank Details</h4>

              <FormField
                label="Account Holder Name"
                name="accountHolderName"
                value={professionalDetails.accountHolderName}
                onChange={(e) => handleProfessionalDetailsChange('accountHolderName', e.target.value)}
                placeholder="Enter your account holder name"
              />

              <FormField
                label="Account Number"
                name="accountNumber"
                value={professionalDetails.accountNumber}
                onChange={(e) => handleProfessionalDetailsChange('accountNumber', e.target.value)}
                placeholder="Enter your account number"
                type="number"
              />

              <FormField
                label="Bank Name"
                name="bankName"
                value={professionalDetails.bankName}
                onChange={(e) => handleProfessionalDetailsChange('bankName', e.target.value)}
                placeholder="Enter your bank name"
              />

              <FormField
                label="IFSC Code"
                name="ifscCode"
                value={professionalDetails.ifscCode}
                onChange={(e) => handleProfessionalDetailsChange('ifscCode', e.target.value)}
                placeholder="Enter your IFSC code"
              />

              {/* Terms Agreement */}
              <div className="flex items-center gap-3 mt-6">
                <input
                  id="terms-checkbox"
                  name="terms"
                  type="checkbox"
                  checked={agreeToTerms}
                  onChange={(e) => setAgreeToTerms(e.target.checked)}
                  className="w-5 h-5 rounded bg-[#1C1C1E] border-white/10"
                />
                <label htmlFor="terms-checkbox" className="text-sm text-white/70">
                  I agree to the terms of services and privacy policy
                </label>
              </div>

              {/* Switch to Professional Button */}
              <button
                disabled={!canSwitchToProfessional()}
                className={`w-full py-4 rounded-xl font-medium mt-6 ${
                  canSwitchToProfessional()
                    ? 'bg-[#ED234A] text-white hover:bg-[#ED234A]/90'
                    : 'bg-gray-600 text-white/50 cursor-not-allowed'
                }`}
              >
                {canSwitchToProfessional() ? 'Switch to Professional Account' : 'Complete All Required Fields'}
              </button>
            </div>
          )}
        </div>

        {/* Profile Photo Modal */}
        {showProfileModal && (
          <div className="fixed inset-0 bg-black/90 backdrop-blur-lg flex items-end justify-center z-50 p-4">
            <div className="w-full max-w-sm bg-[#1C1C1E] rounded-t-3xl overflow-hidden border border-white/10 transform transition-transform duration-300 translate-y-0 motion-safe:animate-[slideUp_0.3s_ease-out]">
              {/* Modal Header */}
              <div className="p-6 text-center border-b border-white/10">
                <h3 className="text-xl font-semibold text-white">Update Profile Photo</h3>
              </div>

              {/* Modal Content */}
              <div className="p-4">
                <button
                  onClick={() => profileInputRef.current?.click()}
                  className="w-full flex items-center gap-4 p-4 hover:bg-white/5 rounded-2xl transition-colors duration-200 group"
                >
                  <div className="w-12 h-12 rounded-2xl bg-[#ED234A]/10 flex items-center justify-center group-hover:bg-[#ED234A]/20 transition-colors">
                    <svg className="w-6 h-6 text-[#ED234A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" 
                      />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <p className="font-medium text-white text-base">Choose from gallery</p>
                    <p className="text-sm text-white/50">Upload a photo from your device</p>
                  </div>
                </button>

                {profilePhoto && (
                  <button
                    onClick={removeProfilePhoto}
                    className="w-full flex items-center gap-4 p-4 hover:bg-white/5 rounded-2xl transition-colors duration-200 mt-2 group"
                  >
                    <div className="w-12 h-12 rounded-2xl bg-red-500/10 flex items-center justify-center group-hover:bg-red-500/20 transition-colors">
                      <svg className="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                        />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <p className="font-medium text-red-500 text-base">Remove current photo</p>
                      <p className="text-sm text-white/50">Delete your profile picture</p>
                    </div>
                  </button>
                )}
              </div>

              {/* Modal Footer */}
              <div className="p-4 border-t border-white/10">
                <button
                  onClick={() => setShowProfileModal(false)}
                  className="w-full bg-white/5 text-white font-medium py-4 rounded-2xl hover:bg-white/10 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Banner Photo Modal */}
        {showBannerModal && (
          <div className="fixed inset-0 bg-black/90 backdrop-blur-lg flex items-end justify-center z-50 p-4">
            <div className="w-full max-w-sm bg-[#1C1C1E] rounded-t-3xl overflow-hidden border border-white/10 transform transition-transform duration-300 translate-y-0 motion-safe:animate-[slideUp_0.3s_ease-out]">
              {/* Modal Header */}
              <div className="p-6 text-center border-b border-white/10">
                <h3 className="text-xl font-semibold text-white">Update Banner Photo</h3>
              </div>

              {/* Modal Content */}
              <div className="p-4">
                <button
                  onClick={() => bannerInputRef.current?.click()}
                  className="w-full flex items-center gap-4 p-4 hover:bg-white/5 rounded-2xl transition-colors duration-200 group"
                >
                  <div className="w-12 h-12 rounded-2xl bg-[#ED234A]/10 flex items-center justify-center group-hover:bg-[#ED234A]/20 transition-colors">
                    <svg className="w-6 h-6 text-[#ED234A]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" 
                      />
                    </svg>
                  </div>
                  <div className="flex-1">
                    <p className="font-medium text-white text-base">Choose from gallery</p>
                    <p className="text-sm text-white/50">Upload a photo from your device</p>
                  </div>
                </button>

                {bannerPhoto && (
                  <button
                    onClick={removeBannerPhoto}
                    className="w-full flex items-center gap-4 p-4 hover:bg-white/5 rounded-2xl transition-colors duration-200 mt-2 group"
                  >
                    <div className="w-12 h-12 rounded-2xl bg-red-500/10 flex items-center justify-center group-hover:bg-red-500/20 transition-colors">
                      <svg className="w-6 h-6 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" 
                        />
                      </svg>
                    </div>
                    <div className="flex-1">
                      <p className="font-medium text-red-500 text-base">Remove current photo</p>
                      <p className="text-sm text-white/50">Delete your banner picture</p>
                    </div>
                  </button>
                )}
              </div>

              {/* Modal Footer */}
              <div className="p-4 border-t border-white/10">
                <button
                  onClick={() => setShowBannerModal(false)}
                  className="w-full bg-white/5 text-white font-medium py-4 rounded-2xl hover:bg-white/10 transition-colors"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* File inputs */}
        <input
          id="profile-photo-input"
          name="profile-photo"
          type="file"
          ref={profileInputRef}
          onChange={handleProfileUpload}
          accept="image/*"
          className="hidden"
        />
        
        <input
          id="banner-photo-input"
          name="banner-photo"
          type="file"
          ref={bannerInputRef}
          onChange={handleBannerUpload}
          accept="image/*"
          className="hidden"
        />
      </div>
    </div>
  );
}

export default EditProfile;