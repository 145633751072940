import React, { useEffect, useState } from 'react'
import live from '../LIVE.png'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import cart from '../Shopping Cart.png'
import logo from '../scrollFlogo.png'
import { 
  Settings, 
  UserRoundPen,
  Plus,
  MessageCircle,
  Search,
  Menu,
  Bookmark,
  Share2,
  Wallet,
  ShoppingCart,
  MapPin,
  Truck,
  LogOut,
  ChevronLeft,
  Check
} from 'lucide-react'
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const MenuSection = ({ title, children }) => (
  <div className="mb-6">
    <h3 className="text-white/40 text-xs font-medium uppercase tracking-wider mb-2 px-4">{title}</h3>
    <div className="space-y-1">
      {children}
    </div>
  </div>
);

const MenuItem = ({ icon, text, onClick }) => (
  <button
    onClick={onClick}
    className="w-full px-4 py-2.5 flex items-center gap-3 text-left text-white 
      hover:bg-white/10 active:bg-white/20 transition-colors"
  >
    <div className="w-[22px] h-[22px] flex items-center justify-center text-white/80">
      {icon}
    </div>
    <span className="text-sm font-medium">{text}</span>
  </button>
);

const UpperNavbar = () => {
  const auth = getAuth();
  const [isScrollpage, setIsScrollpage] = useState(false);
  const [isForyou, setIsForyou] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isadmin, setIsadmin] = useState(false);
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const username = useParams();
  // console.log(id);
  useEffect(() => {
    const value = pathname.includes('/scroll');
    setIsScrollpage(value);
  }, [pathname]);

  useEffect(() => {
    const value = pathname.includes('/scroll/foryou');
    setIsForyou(value);
  }, [pathname]);
   
  const currentUser = auth.currentUser;
  const checkIfAdminPage = async () => {
    if (!currentUser) return;

    try {
      const db = getFirestore();
      const q = query(
        collection(db, 'UsersDetail'), 
        where('email', '==', currentUser.email)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        setIsadmin(userData.username === username.username);
      }
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };

  useEffect(() => {
    checkIfAdminPage();
  }, [username]); 

  const handleShareProfile = async () => {
    const profileUrl = window.location.origin + '/userdetail/' + username.id;
    try {
      await navigator.clipboard.writeText(profileUrl);
      setShowCopiedToast(true);
      setTimeout(() => setShowCopiedToast(false), 2000);
      setIsMenuOpen(false);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  return (
    <div className="relative">
      {/* Apple-style blur background */}
      <div className={`fixed top-0 left-0 right-0 h-12 bg-[#1c1c1e]/60 backdrop-blur-xl z-20
        border-b border-white/[0.08] shadow-sm shadow-black/5 ${isForyou ? 'z-50' : ''}`}>
        <div className="flex justify-between items-center h-full px-3">
          {/* Left Side */}
          <div className="flex items-center gap-3">
            {/* <button 
              onClick={() => Navigate(-1)}
              className="p-1 hover:bg-white/10 rounded-full transition-colors"
            >
              <ChevronLeft className="w-6 h-6 text-white" />
            </button> */}
            {isScrollpage ? (
              <img src={cart} alt='cart' className="h-5" />
            ) : (
              <img src={logo} alt="log.png" className='w-20 h-6 mt-1'/>
            )}
          </div>

          {/* Middle: Navigation Links (For Scroll Page) */}
          {isScrollpage && (
            <div className="absolute left-1/2 transform -translate-x-1/2 flex gap-3">
              <Link to='following'>
              <button className="text-gray-400 hover:text-white transition-colors text-sm font-semibold">
                Following
              </button>
              </Link>
              <Link to='foryou'>
                <button className="text-gray-400 hover:text-white transition-colors text-sm font-semibold">
                  For you
                </button>
              </Link>
            </div>
          )}
          
          {/* Right Side */}
          <div className="flex items-center gap-2">
            {isadmin && (
              <button 
                onClick={() => Navigate('/PostVideoPage')}
                className="p-1 hover:bg-white/10 rounded-full transition-colors"
              >
                <Plus className="w-6 h-6 text-white" />
              </button>
            )}
            
            {/* <button className="p-1 hover:bg-white/10 rounded-full transition-colors relative">
              <MessageCircle className="w-6 h-6 text-white" />
              <span className="absolute -top-0.5 -right-0.5 bg-[#fe2c55] text-white text-[10px] 
                w-4 h-4 flex items-center justify-center rounded-full font-bold shadow-sm">
                12
              </span>
            </button> */}
            
            <button className="p-1 hover:bg-white/10 rounded-full transition-colors" onClick={()=>{Navigate('/Discover')}}>
              <Search className="w-6 h-6 text-white" />
            </button>
            
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-1 hover:bg-white/10 rounded-full transition-colors"
            >
              <Menu className="w-6 h-6 text-white" />
            </button>
          </div>
        </div>
      </div>

      {/* Spacer to prevent content from going under the navbar */}
      <div className="h-12"></div>

      {/* Side Menu */}
      {isMenuOpen && (
        <>
          {/* Backdrop */}
          <div 
            className="fixed inset-0 bg-black/60 backdrop-blur-sm z-30"
            onClick={() => setIsMenuOpen(false)}
          />
          
          {/* Menu Panel */}
          <div className="fixed top-0 right-0 bottom-0 w-72 bg-[#1c1c1e] z-40
            shadow-xl border-l border-white/[0.08] animate-slide-right
            flex flex-col">
            {/* Header */}
            <div className="p-4 border-b border-white/[0.08]">
              <div className="flex justify-between items-center">
                <div>
                  <h4 className="text-white text-lg font-semibold">@Someone</h4>
                  <p className="text-gray-400 text-sm">Louis Vuitton</p>
                </div>
                <button 
                  onClick={() => setIsMenuOpen(false)}
                  className="p-2 hover:bg-white/10 rounded-full transition-colors"
                >
                  <span className="text-white/90 text-xl">&times;</span>
                </button>
              </div>
            </div>

            {/* Scrollable Content */}
            <div className="flex-1 overflow-y-auto py-4">
              <MenuSection title="Profile">
                <Link to='/EditProfile'> 
                  <MenuItem icon={<UserRoundPen size={18} />} text="Edit Profile" />
                </Link>
                <Link to='/Saved'>
                  <MenuItem icon={<Bookmark size={18} />} text="Saved" />
                </Link>
                <MenuItem icon={<Share2 size={18} />} text="Share Profile" onClick={handleShareProfile} />
              </MenuSection>

              <MenuSection title="Shopping">
                <Link to='/Cart'>
                <MenuItem icon={<ShoppingCart size={18} />} text="Cart" />
                </Link>
                <Link to='/ManageProducts'>
                <MenuItem icon={<ShoppingCart size={18} />} text="ManageProducts" />
                </Link>
                <Link to='/Wallet'>
                  <MenuItem icon={<Wallet size={18} />} text="Wallet" />
                </Link>
                <Link to='/AddAddress'>
                <MenuItem icon={<MapPin size={18} />} text="Your Address" />
                </Link>
                <MenuItem icon={<Truck size={18} />} text="Track Orders" />
                <Link to='/OrderManagement'>
                <MenuItem icon={<Truck size={18} />} text="OrderList" />
                </Link>
              </MenuSection>
            </div>

            {/* Bottom Section */}
            <div className="border-t border-white/[0.08] p-4 space-y-2">
              <MenuItem 
                icon={<LogOut size={18} />} 
                text="Logout" 
                onClick={() => signOut(auth)}
              />
              <Link to='/Settings'>
                <MenuItem 
                  icon={<Settings size={18} />} 
                  text="Settings" 
                />
              </Link>
            </div>
          </div>
        </>
      )}

      {/* Copied Toast */}
      {showCopiedToast && (
        <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 z-50
          bg-[#2c2c2e] text-white px-4 py-2 rounded-lg shadow-lg
          flex items-center gap-2">
          <Check size={16} className="text-green-500" />
          <span className="text-sm">Profile URL copied!</span>
        </div>
      )}
    </div>
  );
}

export default UpperNavbar;
