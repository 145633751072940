import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Play, Eye, Heart, MessageCircle } from 'lucide-react';

const VideoThumbnail = ({ scroll, onClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="relative aspect-square rounded-xl overflow-hidden group cursor-pointer transform transition-all duration-300 hover:scale-[1.02]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {/* Loading State */}
      {isLoading && (
        <div className="absolute inset-0 bg-gradient-to-br from-gray-800 to-gray-900 animate-pulse">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin" />
          </div>
        </div>
      )}

      {/* Thumbnail Image */}
      <img 
        src={scroll.thumbnailUrl} 
        alt="Video thumbnail"
        className={`w-full h-full object-cover transition-transform duration-700 ${
          isHovered ? 'scale-110' : 'scale-100'
        }`}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
      />

      {/* Overlay Gradient */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

      {/* Play Button Overlay */}
      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <div className="w-12 h-12 bg-white/20 backdrop-blur-sm rounded-full flex items-center justify-center">
          <Play className="w-6 h-6 text-white fill-white ml-1" />
        </div>
      </div>

      {/* Video Stats */}
      <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
        <div className="flex items-center gap-4 text-white/90">
          <div className="flex items-center gap-1">
            <Eye className="w-4 h-4" />
            <span className="text-sm font-medium">
              {formatNumber(scroll.views || 0)}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <Heart className="w-4 h-4" />
            <span className="text-sm font-medium">
              {formatNumber(scroll.likes || 0)}
            </span>
          </div>
          <div className="flex items-center gap-1">
            <MessageCircle className="w-4 h-4" />
            <span className="text-sm font-medium">
              {formatNumber(scroll.comments || 0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

// Utility function to format numbers
const formatNumber = (num) => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  }
  return num.toString();
};

const ProfileScroll = () => {
  const [scrollData, setScrollData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { username } = useParams();
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const ITEMS_PER_PAGE = 12;

  const fetchVideos = async () => {
    try {
      const apiUrl = `${backendURL}/userdetail/scrollsdata/${username}`;
      const response = await fetch(apiUrl);
      
      if (!response.ok) throw new Error("Failed to fetch videos");
      
      const maindata = await response.json();
      const data = maindata.scrolls;
      
      const startIndex = (page - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      
      if (page === 1) {
        setScrollData(data);
      } else {
        setScrollData(prev => [...prev, ...data]);
      }
      
      setHasMore(endIndex < data.length);
      setPage(prev => prev + 1);
    } catch (error) {
      console.error("Error fetching videos:", error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPage(1);
    setScrollData([]);
    setIsLoading(true);
    fetchVideos();
  }, [username]);

  const handleVideoClick = (videoId) => {
    navigate(`/Scrolls/${username}`);
  };

  if (isLoading) {
    return (
      <div className="h-[300px] flex items-center justify-center bg-black">
        <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin" />
      </div>
    );
  }

  return (
    <div 
      id="scrollableDiv"
      className="min-h-[300px] bg-black overflow-y-auto scrollbar-hide"
    >
      <InfiniteScroll
        dataLength={scrollData.length}
        next={fetchVideos}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center p-4">
            <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin" />
          </div>
        }
        endMessage={
          scrollData.length > 0 ? (
            <div className="text-center py-6">
              <p className="text-white/60 text-sm">No more videos to show</p>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center h-[300px]">
              <p className="text-white/60 text-lg mb-2">No videos found</p>
              <p className="text-white/40 text-sm">This user hasn't posted any videos yet</p>
            </div>
          )
        }
        scrollableTarget="scrollableDiv"
      >
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {scrollData.map((scroll) => (
            <VideoThumbnail
              key={scroll.id}
              scroll={scroll}
              onClick={() => handleVideoClick(scroll.id)}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ProfileScroll;