import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Plus, ShoppingCart, Search, MessageCircle, Heart, Share2, Bookmark, X, ChevronRight } from 'lucide-react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ReelsPlayer from './ReelsPlayer';
import { getAuth } from 'firebase/auth';
import Navbar from './Navbar';
import UpperNavbar from './UpperNavbar';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import LikeButton from './LikeButton';
import CommentSystem from './CommentSystem';
const ScrollFollowing = () => {
  const { pathname } = useLocation();
  // const isForyou = pathname.includes('/scroll/foryou');
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [likes, setLikes] = useState({});
  const [bookmarks, setBookmarks] = useState({});
  const [fullscreenVideo, setFullscreenVideo] = useState(null);
  const loadingRef = useRef(false);
  const containerRef = useRef(null);
  const email = getAuth().currentUser?.email;
  const videoRefs = useRef({});
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [showComments, setShowComments] = useState(false);
const [activeScrollId, setActiveScrollId] = useState(null);
  const[ProductDetails,setProductDetails]=useState([]);
  const { documentId } = useParams();
  const validatePath = (pathname) => {
    if (!pathname || typeof pathname !== 'string') {
      return false;
    }
  
    // Sanitize the path
    const cleanPath = pathname
      .toLowerCase()
      .trim()
      .replace(/\/+/g, '/');  // normalize multiple slashes
    
    // Modified validation to handle dynamic routes
    if (cleanPath.length > 100) {
      return false;
    }
    
    // Check for dangerous characters
    if (/[<>'"%;()]/.test(cleanPath)) {
      return false;
    }
    
    // Check if it's a specific product route
    if (documentId) {
      return cleanPath.startsWith('/scroll/foryou/specific/');
    }
    
    // Check against static paths
    return cleanPath === '/scroll/foryou' || cleanPath === '/scroll/following';
  };
  
  // Modified isForyou check
  const isForyou = pathname && validatePath(pathname) && (
    pathname.toLowerCase() === '/scroll/foryou' || 
    pathname.toLowerCase().startsWith('/scroll/foryou/specific/')
  );

  // Modified handleVideoClick to clear fullscreen when switching sections
  
  // Usage
  // const isForyou = pathname && validatePath(pathname) && (pathname.toLowerCase() === '/scroll/foryou' || pathname.toLowerCase() === '/scroll/foryou/specific/:documentId' );
  console.log(isForyou);
  const fetchProductData = async (productId) => {
    if (!productId) return;
    
    try {
      const url = `${backendURL}/userdetail/Product_details?productid=${productId}`;
      const resp = await fetch(url);
      if (!resp.ok) {
        console.log("Error occurred while fetching product details");
        return;
      }
      const maindata = await resp.json();
      setProductDetails(prevDetails => ({
        ...prevDetails,
        [productId]: maindata.productdetails
      }));
    } catch (error) {
      console.log("Error occurred while fetching product details:", error);
    }
  };
  // const {username}=useParams()
  const firebase=useFirebase();
  console.log(firebase.currentId);
  const username=firebase.currentId
  const fetchVideos = async (pageNum) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);
    try {
      const response = await fetch(`${backendURL}/userdetail/scrollsdata/${username}`);
      if (!response.ok) throw new Error("Failed to fetch videos");
      const maindata = await response.json();
      const data=maindata.scrolls;
      
      if (data.length === 0) {
        setHasMore(false);
      } else {
        setVideos(prev => pageNum === 1 ? data : [...prev, ...data]);
        setHasMore(true);
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    if(!isForyou){
    fetchVideos(1);
    }
  }, [username]);
  console.log(videos);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollHeight - scrollTop <= clientHeight * 1.5 && hasMore && !loading) {
        setPage(prev => prev + 1);
        fetchVideos(page + 1);
      }
    }
  };

  const toggleLike = (videoId) => {
    setLikes(prev => ({ ...prev, [videoId]: !prev[videoId] }));
  };

  const toggleBookmark = (videoId) => {
    setBookmarks(prev => ({ ...prev, [videoId]: !prev[videoId] }));
  };

  const handleVideoClick = (video) => {
    if (isForyou) {
      // If we're in foryou section, navigate to foryou instead of showing fullscreen
      navigate('/scroll/foryou');
      return;
    }
    
    const reorderedVideos = [
      video,
      ...videos.filter(v => v.id !== video.id)
    ];
    setVideos(reorderedVideos);
    setFullscreenVideo(video);
  };

  // Add cleanup for fullscreen when component unmounts or section changes
  useEffect(() => {
    return () => {
      setFullscreenVideo(null);
    };
  }, [pathname]);

  // Modified close fullscreen to handle navigation
  const closeFullscreen = () => {
    setFullscreenVideo(null);
    if (isForyou) {
      navigate('/scroll/following');
    }
  };
 const navigate=useNavigate();
  // Add navigation handlers for section switching
  const handleSectionChange = (path) => {
    setFullscreenVideo(null); // Clear fullscreen state
    navigate(path);
  };

  // Modify the section links in the UI
  const renderNavigationLinks = () => (
    <div className="flex gap-8">
      <button 
        onClick={() => handleSectionChange('/scroll/following')}
        className={`text-[15px] font-medium transition-all ${
          !isForyou ? 'text-white border-b-2 border-white pb-1' : 'text-white/70'
        }`}
      >
        Following
      </button>
      <button 
        onClick={() => handleSectionChange('/scroll/foryou')}
        className={`text-[15px] font-medium transition-all ${
          isForyou ? 'text-white border-b-2 border-white pb-1' : 'text-white/70'
        }`}
      >
        For you
      </button>
    </div>
  );


  const handleIntersection = useCallback((entries) => {
    entries.forEach(entry => {
      const video = entry.target;
      if (entry.isIntersecting) {
        video.play().catch(err => console.log("Autoplay failed:", err));
      } else {
        video.pause();
      }
    });
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.6
    });

    const currentVideoRefs = videoRefs.current;
    Object.values(currentVideoRefs).forEach(video => {
      if (video) observer.observe(video);
    });

    return () => {
      Object.values(currentVideoRefs).forEach(video => {
        if (video) observer.unobserve(video);
      });
    };
  }, [handleIntersection, videos]);

  // Add a new function to handle fullscreen scroll
  const handleFullscreenScroll = useCallback((entries) => {
    entries.forEach(entry => {
      const video = entry.target;
      if (entry.isIntersecting) {
        video.play().catch(err => console.log("Autoplay failed:", err));
      } else {
        video.pause();
        video.currentTime = 0; // Reset video position when scrolled away
      }
    });
  }, []);

  // Add useEffect for fullscreen video scroll handling
  useEffect(() => {
    if (!fullscreenVideo) return;

    const observer = new IntersectionObserver(handleFullscreenScroll, {
      threshold: 0.7 // Higher threshold for more precise video switching
    });

    const videos = document.querySelectorAll('.fullscreen-video');
    videos.forEach(video => observer.observe(video));

    return () => {
      videos.forEach(video => observer.unobserve(video));
    };
  }, [fullscreenVideo, handleFullscreenScroll]);

  return (
    <div className="min-h-screen bg-black">
      {/* Upper Navigation Bar - Reverted to original gradient */}
      {/* <div className="fixed top-0 left-0 right-0 z-50">
        <div className="bg-gradient-to-b from-black/90 to-transparent">
          <div className="px-4 py-3 flex justify-between items-center">
            <Link to="/cart">
              <ShoppingCart className="text-white w-6 h-6" />
            </Link>
            
            <div className="flex gap-8">
              <Link 
                to="/scroll/following"
                className={`text-[15px] font-medium transition-all ${
                  !isForyou ? 'text-white border-b-2 border-white pb-1' : 'text-white/70'
                }`}
              >
                Following
              </Link>
              <Link 
                to="/scroll/foryou"
                className={`text-[15px] font-medium transition-all ${
                  isForyou ? 'text-white border-b-2 border-white pb-1' : 'text-white/70'
                }`}
              >
                For you
              </Link>
            </div>

            <div className="flex items-center gap-4">
              <div className="relative">
                <MessageCircle className="text-white w-6 h-6" />
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] rounded-full w-4 h-4 flex items-center justify-center">
                  12
                </span>
              </div>
              <Search className="text-white w-6 h-6" />
            </div>
          </div>
        </div>
      </div> */}

      {/* Rest of the content */}
      {isForyou ? (
        <div className="pt-[52px]">
          <ReelsPlayer />
        </div>
      ) : (
        <>
            {/* Videos Section */}
            <div 
              ref={containerRef}
              className="space-y-[1px] bg-[#1c1c1e]"
              onScroll={handleScroll}
            >
              {videos.map(video => {
              const productId = video.productTags?.[0]?.productId;
              const details = ProductDetails[productId] || {};
              console.log(details)
              return (
                <div key={video.id} className="bg-black">
                  {/* Brand Info */}
                  <div className="px-4 py-3 flex items-center gap-3">
                    <div className="w-10 h-10 rounded-full overflow-hidden ring-2 ring-white/10">
                      <img 
                        src={video.thumbnailUrl || "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150"}
                        alt="Brand" 
                        className="w-full h-full object-cover" 
                      />
                    </div>
                    <div>
                      <h3 className="text-white font-semibold text-sm">{video.brandName || "Brand Name"}</h3>
                      <div className="flex items-center gap-2 mt-0.5">
                        <span className="text-white/60 text-xs">{video.followers || "0k"} Followers</span>
                      </div>
                    </div>
                  </div>

                  {/* Video Container */}
                  <div 
                    className="relative aspect-[3/4] cursor-pointer bg-black"
                    onClick={() => handleVideoClick(video)}
                  >
                    <video
                      ref={el => videoRefs.current[video.id] = el}
                      src={video.videoUrl}
                      className="w-full h-full object-cover"
                      poster={video.thumbnail}
                      loop
                      playsInline
                      muted
                    />

                    {/* Product Card */}
                    <div className="absolute bottom-4 left-4 w-[75%]">
                    <div className="flex items-center gap-1 text-white text-xs mb-2">
                      <span>Explore Similar</span>
                      <ChevronRight className="w-4 h-4" />
                    </div>
                    <div className="bg-black/40 backdrop-blur-sm rounded-xl p-2 flex items-center gap-3">
                      <img
                        src={details.image || "https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?w=300"}
                        alt="Product"
                        className="w-16 h-16 rounded-lg object-cover"
                      />
                      <div className="flex-1">
                        <h3 className="text-white text-sm font-medium line-clamp-1">
                          {details.name || "Fashion Item"}
                        </h3>
                        <p className="text-white/70 text-xs line-clamp-1">
                          {details.description || "Premium Quality"}
                        </p>
                        <p className="text-white font-semibold text-sm mt-0.5">
                        ₹{details.price || "99.99"}
                        </p>
                      </div>
                      <div className="flex items-center justify-center w-8 h-8">
                        <ChevronRight className="w-5 h-5 text-white/70" />
                      </div>
                    </div>
                  </div>
                  </div>

                  {/* Interaction Section */}
                  <div className="px-4 py-3 space-y-3">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-6">
                      <LikeButton 
                      mainpage={false}
  itemId={video.id}
  initialLikeCount={video.likeCount || 0}
  initialIsLiked={video.isLikedByUser || false}
  onLikeToggle={(isLiked) => {
    // Optional: Update local state if needed
    setVideos(prev => prev.map(v => 
      v.id === video.id 
        ? { ...v, isLikedByUser: isLiked, likeCount: isLiked ? (v.likeCount || 0) + 1 : (v.likeCount || 0) - 1 }
        : v
    ));
  }}
/>

<button 
  className="flex items-center gap-2 group"
  onClick={(e) => {
    e.stopPropagation();
    setActiveScrollId(video.id);
    setShowComments(true);
  }}
>
  <MessageCircle className="w-6 h-6 text-white/80 group-hover:text-white" />
  <span className="text-white/80 group-hover:text-white text-sm font-medium">
    {video.commentCount || '0'}
  </span>
</button>

                        <button className="flex items-center gap-2 group">
                          <Share2 className="w-6 h-6 text-white/80 group-hover:text-white" />
                          <span className="text-white/80 group-hover:text-white text-sm font-medium">{video.shares || '0k'}</span>
                        </button>
                      </div>

                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleBookmark(video.id);
                        }}
                        className="group"
                      >
                        <Bookmark className={`w-6 h-6 ${bookmarks[video.id] ? 'text-white fill-white' : 'text-white/80 group-hover:text-white'}`} />
                      </button>
                    </div>

                    <div>
                      <p className="text-white/90 text-sm font-medium mb-1">{video.caption || "Video caption goes here"}</p>
                      <p className="text-white/60 text-sm">{video.hashtags || "#trending #fashion"}</p>
                      <div className="flex items-center gap-2 mt-2 text-white/60 text-xs">
                        <span>Original Audio</span>
                        <span>•</span>
                        <span>{video.songName || "Song Name"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );})}

              {loading && (
                <div className="flex justify-center items-center py-8">
                  <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin"></div>
                </div>
              )}
            </div>
        </>
      )}

      {/* Fullscreen Video Modal */}
      {fullscreenVideo && !isForyou && (
        <div className="fixed inset-0 bg-black z-50">
          {/* Upper navbar in fullscreen */}
          <div className="fixed top-0 left-0 right-0 z-50">
            <div className="bg-gradient-to-b from-black/90 to-transparent">
              <div className="px-4 py-3 flex justify-between items-center">
                <Link to="/cart">
                  <ShoppingCart className="text-white w-6 h-6" />
                </Link>
                
                <div className="flex gap-8">
                  <Link 
                    to="/scroll/following"
                    className={`text-[15px] font-medium transition-all ${
                      !isForyou ? 'text-white border-b-2 border-white pb-1' : 'text-white/70'
                    }`}
                  >
                    Following
                  </Link>
                  <Link 
                    to="/scroll/foryou"
                    className={`text-[15px] font-medium transition-all ${
                      isForyou ? 'text-white border-b-2 border-white pb-1' : 'text-white/70'
                    }`}
                  >
                    For you
                  </Link>
                </div>

                <div className="flex items-center gap-4">
                  <div className="relative">
                    <MessageCircle className="text-white w-6 h-6" />
                    <span className="absolute -top-2 -right-2 bg-red-500 text-white text-[11px] rounded-full w-4 h-4 flex items-center justify-center">
                      12
                    </span>
                  </div>
                  <Search className="text-white w-6 h-6" />
                </div>
              </div>
            </div>
          </div>

          <div 
            className="h-full snap-y snap-mandatory overflow-y-scroll scrollbar-none"
            onScroll={handleScroll}
          >
            {videos.map((video, index) => (
              <div 
                key={video.id} 
                className="h-screen w-full snap-start snap-always relative"
              >
                <video
                  src={video.videoUrl}
                  className="fullscreen-video absolute inset-0 w-full h-full object-cover"
                  autoPlay={index === 0}
                  loop
                  playsInline
                  muted={false}
                  controls={false}
                  onClick={(e) => {
                    e.preventDefault();
                    const video = e.target;
                    if (video.paused) {
                      video.play();
                    } else {
                      video.pause();
                    }
                  }}
                />

                {/* Product Card - Adjusted position */}
                <Link to={`/ProductDisplayWTags/${video.id}`}>
                <div className="absolute bottom-24 left-4 w-[70%]">
                  <div className="flex items-center gap-1 text-white text-xs mb-2">
                    <span>Explore Similar</span>
                    <ChevronRight className="w-4 h-4" />
                  </div>
                  <div className="bg-black/40 backdrop-blur-sm rounded-xl p-2 flex items-center gap-3">
                    <img
                      src={video.image || "https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?w=300"}
                      alt="Product"
                      className="w-16 h-16 rounded-lg object-cover"
                    />
                    <div className="flex-1">
                      <h3 className="text-white text-sm font-medium line-clamp-1">
                        {video.name || "Fashion Item"}
                      </h3>
                      <p className="text-white/70 text-xs line-clamp-1">
                        {video.description || "Premium Quality"}
                      </p>
                      <p className="text-white font-semibold text-sm mt-0.5">
                        ₹{video.price || "99.99"}
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-8 h-8">
                      <ChevronRight className="w-5 h-5 text-white/70" />
                    </div>
                  </div>
                </div>
                </Link>

                {/* Vertical Interaction Buttons - Adjusted position and spacing */}
                <div className="absolute right-4 bottom-24 flex flex-col gap-4">
                <LikeButton 
                mainpage={true}
  itemId={video.id}
  initialLikeCount={video.likeCount || 0}
  initialIsLiked={video.isLikedByUser || false}
  onLikeToggle={(isLiked) => {
    // Optional: Update local state if needed
    setVideos(prev => prev.map(v => 
      v.id === video.id 
        ? { ...v, isLikedByUser: isLiked, likeCount: isLiked ? (v.likeCount || 0) + 1 : (v.likeCount || 0) - 1 }
        : v
    ));
  }}
/>
<button 
  className="flex items-center gap-2 group flex-col"
  onClick={(e) => {
    e.stopPropagation();
    setActiveScrollId(video.id);
    setShowComments(true);
  }}
>
  <MessageCircle className="w-6 h-6 text-white/80 group-hover:text-white" />
  <span className="text-white/80 group-hover:text-white text-sm font-medium">
    {video.commentCount || '0'}
  </span>
</button>

                  <button className="flex flex-col items-center">
                    <div className="w-10 h-10 rounded-full bg-black/40 backdrop-blur-sm flex items-center justify-center">
                      <Share2 className="w-6 h-6 text-white" />
                    </div>
                    {/* <span className="text-white text-xs mt-1">{video.shares || '132.5k'}</span> */}
                  </button>

                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleBookmark(video.id);
                    }}
                    className="flex flex-col items-center"
                  >
                    <div className="w-10 h-10 rounded-full bg-black/40 backdrop-blur-sm flex items-center justify-center">
                      <Bookmark className={`w-6 h-6 ${bookmarks[video.id] ? 'text-white fill-white' : 'text-white'}`} />
                    </div>
                    <span className="text-white text-xs mt-1">Save</span>
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Adjusted close button position */}
          <button 
            onClick={closeFullscreen}
            className="fixed top-16 right-4 z-[52] p-2 rounded-full bg-black/40 backdrop-blur-sm"
          >
            <X className="w-6 h-6 text-white" />
          </button>

          {/* Bottom Navigation Bar */}
          <Navbar transparent={false} />
        </div>
      )}
      {showComments && (
  <CommentSystem
    scrollId={activeScrollId}
    currentUserId={firebase.currentId}
    onClose={() => setShowComments(false)}
  />
)}
    </div>
  );
};

export default ScrollFollowing;
