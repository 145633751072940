import React, { useState, useRef, useEffect } from 'react';
import { IoChevronBack, IoClose } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import { addDoc, arrayUnion, collection, doc, updateDoc } from 'firebase/firestore';

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [previewImages, setPreviewImages] = useState([]); 
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showVariantModal, setShowVariantModal] = useState(false);
  const [variants, setVariants] = useState([]);
  const [currentVariant, setCurrentVariant] = useState({ name: '', options: '' });
  const [tags, setTags] = useState([]);
  const [currentTag, setCurrentTag] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    category: '',
    price: '',
    comparePrice: '',
    inventory: '',
    shipping: '',
  });
  const backendURL = process.env.REACT_APP_BACKEND_URL;


  const categories = [
    { value: '', label: 'Select Category' },
    { value: 'clothing', label: 'Clothing & Fashion' },
    { value: 'electronics', label: 'Electronics & Gadgets' },
    { value: 'home', label: 'Home & Furniture' },
    { value: 'beauty', label: 'Beauty & Personal Care' },
    { value: 'sports', label: 'Sports & Outdoors' },
    { value: 'toys', label: 'Toys & Games' },
    { value: 'books', label: 'Books & Media' },
    { value: 'jewelry', label: 'Jewelry & Watches' },
    { value: 'automotive', label: 'Automotive' },
    { value: 'garden', label: 'Garden & Tools' },
    { value: 'food', label: 'Food & Beverages' },
    { value: 'health', label: 'Health & Wellness' },
    { value: 'pet', label: 'Pet Supplies' },
    { value: 'art', label: 'Art & Crafts' },
    { value: 'music', label: 'Musical Instruments' },
    { value: 'office', label: 'Office Supplies' },
    { value: 'baby', label: 'Baby & Kids' },
    { value: 'vintage', label: 'Vintage & Collectibles' },
    { value: 'other', label: 'Other' },
  ];
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageSelection = async (e) => {
  const files = Array.from(e.target.files);
  
  // Store the actual files
  setSelectedFiles(prev => [...prev, ...files]);
  
  // Create previews and store base64 strings
  for (const file of files) {
    try {
      const base64String = await convertToBase64(file);
      setPreviewImages(prev => [...prev, base64String]);
    } catch (error) {
      console.error('Error converting image to base64:', error);
    }
  }
};

  const handleRemoveImage = (indexToRemove) => {
    setPreviewImages(prev => prev.filter((_, index) => index !== indexToRemove));
    setSelectedFiles(prev => prev.filter((_, index) => index !== indexToRemove));
  };

  const uploadImagesToServer = async (base64Images) => {
    try {
      const response = await fetch(`${backendURL}/userdetail/product_image`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          images: base64Images
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.error || `Upload failed with status: ${response.status}`);
      }
  
      const data = await response.json();
      if (!data.imageUrls || !Array.isArray(data.imageUrls)) {
        throw new Error('Invalid response format from server');
      }
  
      return data.imageUrls;
    } catch (error) {
      console.error('Image upload error:', error);
      throw error;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleVariantSubmit = () => {
    if (variants.length >= 3) {
      alert('Maximum 3 variants allowed');
      return;
    }
    if (currentVariant.name && currentVariant.options) {
      setVariants([...variants, {
        ...currentVariant,
        options: currentVariant.options.split(',').map(opt => opt.trim())
      }]);
      setCurrentVariant({ name: '', options: '' });
      setShowVariantModal(false);
    }
  };

  const handleTagKeyDown = (e) => {
    if (e.key === 'Enter' && currentTag.trim()) {
      e.preventDefault();
      setTags([...tags, currentTag.trim()]);
      setCurrentTag('');
    }
  };

  const removeTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const removeVariant = (indexToRemove) => {
    setVariants(variants.filter((_, index) => index !== indexToRemove));
  };

  const firebase = useFirebase();

  const handleSave = async () => {
      // Validation
      try {
        // Validation
        if (!formData.title || !formData.price || !formData.inventory || previewImages.length === 0) {
          alert('Please fill in all required fields and add at least one image');
          return;
        }

      if (parseInt(formData.inventory) < 0) {
        alert('Inventory cannot be negative');
        return;
      }

      if (formData.shipping && parseInt(formData.shipping) < 0) {
        alert('Shipping weight cannot be negative');
        return;
      }

      setIsLoading(true);
     
    // Upload images using base64 strings from previewImages state
    const imageUrls = await uploadImagesToServer(previewImages);
      //generate the seller id
      await firebase.checkIfAdminPage();
      // 2. Then create the product document with the image URLs
      const productRef = await addDoc(collection(firebase.firestore, "Products"), {
        sellerId: firebase.sellerid,
        name: formData.title,
        image: imageUrls[0], // First image as main image
        available: parseInt(formData.inventory),
        price: parseFloat(formData.price),
        priceFormatted: `₹${parseFloat(formData.price).toLocaleString('en-IN')}`,
        comparePrice: formData.comparePrice ? `₹${parseFloat(formData.comparePrice).toLocaleString('en-IN')}` : null,
        description: formData.description,
        category: formData.category,
        variants: variants,
        tags: tags,
        shipping: formData.shipping ? `${formData.shipping}g` : '',
        images: imageUrls,
        isNew: false,
        createdAt: Date.now(),
        updatedAt: Date.now(),
      });
      //updating the sellers data and adding product it posted as array
      await updateDoc(doc(firebase.firestore, "UsersDetail", firebase.adminRef), {
        products: arrayUnion(productRef.id)  // Assuming the array field is called 'products'
    });

      // Navigate back on success
      navigate('/');
    } catch (error) {
      console.error('Error saving product:', error);
      alert(`Failed to save product: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000000] to-[#1a1a1a] text-white">
      <div className="w-full max-w-md mx-auto relative">
        {/* Header */}
        <header className="sticky top-0 z-10 backdrop-blur-xl bg-black/70 px-4 pt-4 pb-2 flex justify-between items-center border-b border-white/10">
          <div className="flex items-center space-x-4">
            <button 
              onClick={() => navigate('/')}
              className="p-2 -ml-2 rounded-full transition-all duration-200 active:scale-90 hover:bg-white/10"
            >
              <IoChevronBack className="w-6 h-6" />
            </button>
            <h1 className="text-[22px] font-semibold tracking-tight bg-gradient-to-b from-white to-white/80 bg-clip-text text-transparent">
              Edit product
            </h1>
          </div>
          <button 
            onClick={handleSave}
            className="text-red-500 font-medium text-[17px] transition-colors hover:text-red-400 active:text-red-600"
          >
            Save
          </button>
        </header>

        <div className="p-4 space-y-4">
          {/* Media Upload Section */}
          <div className="space-y-2">
            <label className="text-[17px] text-white/90">Media</label>
            <div className="relative">
            {previewImages.length > 0 ? (
    <div className="grid grid-cols-3 gap-2 mb-2">
        {previewImages.map((img, index) => (
            <div key={index} className="relative aspect-square rounded-xl overflow-hidden group">
                <img 
                    src={img} 
                    alt={`Preview ${index}`} 
                    className="w-full h-full object-cover"
                />
                <button
                    onClick={() => handleRemoveImage(index)}
                    className="absolute top-2 right-2 bg-black/50 rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity"
                >
                    <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
        ))}
    </div>
) : null}
              <button
                onClick={() => fileInputRef.current?.click()}
                className="w-full h-32 border-2 border-dashed border-white/20 rounded-xl flex items-center justify-center hover:border-white/30 transition-colors"
              >
                <svg className="w-8 h-8 text-white/60" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </button>
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleImageSelection}
                multiple
                accept="image/*"
                className="hidden"
              />
            </div>
          </div>

          {/* Form Fields */}
          <div className="space-y-4">
            <div>
              <input
                type="text"
                name="title"
                placeholder="Product Title"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
              />
            </div>

            <div>
              <textarea
                name="description"
                placeholder="Add Description"
                value={formData.description}
                onChange={handleInputChange}
                rows="4"
                className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px] resize-none"
              />
            </div>

            <div>
              <select
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors text-white/90 text-[17px] appearance-none"
              >
                {categories.map((category) => (
                  <option key={category.value} value={category.value} className='text-black/90'>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-2 gap-3">
              <div className="relative">
                <input
                  type="number"
                  name="price"
                  placeholder="Price"
                  value={formData.price}
                  onChange={handleInputChange}
                  className="w-full bg-white/[0.08] rounded-xl pl-8 pr-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
                />
                <span className="absolute left-4 top-1/2 -translate-y-1/2 text-white/60">₹</span>
              </div>
              <div className="relative">
                <input
                  type="number"
                  name="comparePrice"
                  placeholder="Compare-at price"
                  value={formData.comparePrice}
                  onChange={handleInputChange}
                  className="w-full bg-white/[0.08] rounded-xl pl-8 pr-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
                />
                <span className="absolute left-4 top-1/2 -translate-y-1/2 text-white/60">₹</span>
              </div>
            </div>

            {/* Variants Button and Display */}
            <div className="space-y-2">
              <button
                onClick={() => setShowVariantModal(true)}
                className="w-full bg-white/[0.08] rounded-xl px-4 py-3 hover:bg-white/[0.12] transition-colors text-white/90 text-[17px] text-left"
              >
                {variants.length > 0 ? `Variants (${variants.length})` : 'Add Variants'}
              </button>
              {variants.length > 0 && (
                <div className="space-y-2">
                  {variants.map((variant, index) => (
                    <div key={index} className="flex items-center justify-between bg-white/[0.05] rounded-lg p-2">
                      <div>
                        <p className="text-white/90">{variant.name}</p>
                        <p className="text-white/60 text-sm">{variant.options.join(', ')}</p>
                      </div>
                      <button
                        onClick={() => removeVariant(index)}
                        className="p-1 hover:bg-white/10 rounded-full"
                      >
                        <IoClose className="w-5 h-5" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Tags Input */}
            <div className="space-y-2">
              <input
                type="text"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyDown={handleTagKeyDown}
                placeholder="Add tags (press Enter)"
                className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
              />
              {tags.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {tags.map((tag, index) => (
                    <div
                      key={index}
                      className="flex items-center bg-white/10 rounded-full px-3 py-1"
                    >
                      <span className="text-sm text-white/90">{tag}</span>
                      <button
                        onClick={() => removeTag(index)}
                        className="ml-2 hover:text-white/60"
                      >
                        <IoClose className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-3">
                <input
                  type="number"
                  name="inventory"
                  placeholder="Inventory"
                  value={formData.inventory}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
                />
                <div className="flex items-center px-4 bg-white/[0.08] rounded-xl text-white/60">
                  {formData.inventory ? `${formData.inventory} Available` : 'Availability'}
                </div>
              </div>
            </div>

            <div className="relative">
              <input
                type="number"
                name="shipping"
                placeholder="Shipping weight"
                value={formData.shipping}
                onChange={handleInputChange}
                min="0"
                className="w-full bg-white/[0.08] rounded-xl px-4 pr-12 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
              />
              <span className="absolute right-4 top-1/2 -translate-y-1/2 text-white/60">gms</span>
            </div>
          </div>
        </div>
      </div>

      {/* Variant Modal */}
      {showVariantModal && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-[#1C1C1E] rounded-2xl w-[90%] max-w-md p-4 space-y-4">
            <div className="flex justify-between items-center">
              <h2 className="text-[20px] font-semibold">Add Variant</h2>
              <button
                onClick={() => setShowVariantModal(false)}
                className="p-2 hover:bg-white/10 rounded-full"
              >
                <IoClose className="w-6 h-6" />
              </button>
            </div>
            <input
              type="text"
              placeholder="Variant Name (e.g., Size, Color)"
              value={currentVariant.name}
              onChange={(e) => setCurrentVariant({ ...currentVariant, name: e.target.value })}
              className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
            />
            <input
              type="text"
              placeholder="Options (comma-separated)"
              value={currentVariant.options}
              onChange={(e) => setCurrentVariant({ ...currentVariant, options: e.target.value })}
              className="w-full bg-white/[0.08] rounded-xl px-4 py-3 focus:bg-white/[0.12] transition-colors placeholder-white/40 text-[17px]"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowVariantModal(false)}
                className="px-4 py-2 rounded-xl hover:bg-white/10 transition-colors text-white/60"
              >
                Cancel
              </button>
              <button
                onClick={handleVariantSubmit}
                className="px-4 py-2 bg-white/10 rounded-xl hover:bg-white/20 transition-colors"
              >
                Add Variant
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProduct; 