import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { IoChevronBack, IoAdd, IoSearch } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const ManageProducts = () => {
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProductsdata] = useState([]);
  const [authdata, setauthdata] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const auth = getAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const email = auth.currentUser?.email;
        if (!email) {
          console.error('No user email found');
          return;
        }

        // Fetch admin data
        const adminUrl = `${backendURL}/userdetail/admin?email=${email}`;
        const adminResponse = await fetch(adminUrl);
        if (!adminResponse.ok) {
          throw new Error('Failed to fetch admin data');
        }
        const adminData = await adminResponse.json();
        const maindata = adminData.data;
        setauthdata(maindata);

        // Only fetch products if we have the seller ID
        if (maindata?.username) {
          const productsUrl = `${backendURL}/userdetail/product_details/specificuser/${maindata.username}`;
          const productsResponse = await fetch(productsUrl);
          if (!productsResponse.ok) {
            throw new Error('Failed to fetch products');
          }
          const productsData = await productsResponse.json();
          setProductsdata(productsData.productdetails || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [auth, backendURL]);

  const filteredProducts = products
    .filter(product => {
      if (activeFilter === 'new') {
        return product.isNew;
      }
      return true;
    })
    .filter(product => {
      if (!searchQuery) return true;
      return product.name.toLowerCase().includes(searchQuery.toLowerCase());
    });

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleAddProduct = () => {
    navigate('/listproduct');
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#000000] to-[#1a1a1a] text-white flex justify-center items-center">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#000000] to-[#1a1a1a] text-white flex justify-center">
      <div className="w-full max-w-md mx-auto h-full relative">
        <header className="sticky top-0 z-10 backdrop-blur-xl bg-black/70 px-4 pt-4 pb-2 flex justify-between items-center border-b border-white/10">
          <div className="flex items-center space-x-4">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 -ml-2 rounded-full transition-all duration-200 active:scale-90 hover:bg-white/10"
            >
              <IoChevronBack className="w-6 h-6" />
            </button>
            <h1 className="text-[22px] font-semibold tracking-tight bg-gradient-to-b from-white to-white/80 bg-clip-text text-transparent">
              Manage Products
            </h1>
          </div>
          <button 
            onClick={handleAddProduct}
            className="p-2 -mr-2 rounded-full transition-all duration-200 active:scale-90 hover:bg-white/10"
          >
            <IoAdd className="w-6 h-6" />
          </button>
        </header>

        <div className="px-4 py-3">
          <div className="group flex items-center bg-white/[0.08] hover:bg-white/[0.12] rounded-xl px-4 py-2.5 transition-all duration-300 focus-within:bg-white/[0.15] backdrop-blur-xl">
            <IoSearch className="w-5 h-5 text-white/60 group-focus-within:text-white/80 transition-colors" />
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
              className="bg-transparent w-full ml-2.5 focus:outline-none text-white placeholder-white/60 text-[17px] font-regular"
            />
          </div>
        </div>

        <div className="px-4 py-2 flex space-x-2">
          <button 
            onClick={() => handleFilterChange('all')}
            className={`px-6 py-2 rounded-full text-white font-medium text-[15px] transition-all duration-200 active:scale-95 
              ${activeFilter === 'all' 
                ? 'bg-white/[0.15] backdrop-blur-xl hover:bg-white/20' 
                : 'bg-white/[0.08] text-white/80 hover:bg-white/[0.12]'
              }`}
          >
            All
          </button>
          <button 
            onClick={() => handleFilterChange('new')}
            className={`px-6 py-2 rounded-full text-white font-medium text-[15px] transition-all duration-200 active:scale-95 
              ${activeFilter === 'new' 
                ? 'bg-white/[0.15] backdrop-blur-xl hover:bg-white/20' 
                : 'bg-white/[0.08] text-white/80 hover:bg-white/[0.12]'
              }`}
          >
            New
          </button>
        </div>

        <div className="px-4 py-2 space-y-3">
          {filteredProducts.length === 0 ? (
            <div className="text-center py-8 text-white/60">
              <p className="text-[17px]">No products found</p>
              <p className="text-[15px] mt-1">Try adjusting your search or filters</p>
            </div>
          ) : (
            filteredProducts.map((product) => (
              <div
                key={product.id}
                onClick={() => navigate(`/edit-product/${product.id}`)}
                className="group flex items-center space-x-4 bg-white/[0.08] hover:bg-white/[0.12] p-4 rounded-2xl transition-all duration-300 backdrop-blur-xl active:scale-[0.98] cursor-pointer"
              >
                <div className="w-14 h-14 bg-white/10 rounded-xl overflow-hidden flex-shrink-0 ring-1 ring-white/10">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center space-x-2">
                    <h3 className="text-[17px] font-medium truncate text-white/90 group-hover:text-white transition-colors">
                      {product.name}
                    </h3>
                    {product.isNew && (
                      <span className="px-2 py-0.5 bg-white/10 rounded-full text-[12px] font-medium text-white/70">
                        New
                      </span>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="text-[15px] font-medium bg-gradient-to-r from-red-500 to-red-400 bg-clip-text text-transparent">
                      {product.available} Available
                    </p>
                    <p className="text-[15px] font-medium text-white/90">
                      {product.priceFormatted}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageProducts;