import { FirebaseError, initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {addDoc, getFirestore,collection} from "firebase/firestore";
import { createContext,useContext, useState, useEffect } from "react";
import { browserLocalPersistence, getAuth,signInWithEmailAndPassword,createUserWithEmailAndPassword, setPersistence,onAuthStateChanged} from "firebase/auth";
import { query, where, getDocs } from 'firebase/firestore';
// import { useParams } from "react-router-dom";
// import {doc, getDoc } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
//settng up the firebase key

const firebaseConfig = {
    apiKey: process.env.REACT_APP_Api_Key,
    authDomain: process.env.REACT_APP_Auth_Domain,
    projectId: process.env.REACT_APP_Project_Id,
    storageBucket: process.env.REACT_APP_Storage_Bucket,
    messagingSenderId: process.env.REACT_APP_Messaging_SenderId,
    appId: process.env.REACT_APP_App_Id,
    measurementId: process.env.REACT_APP_Measurement_Id
  };
  const firebaseapp=initializeApp(firebaseConfig);
  // const analytics = getAnalytics(firebaseapp);
  const firestore=getFirestore(firebaseapp);

  const firebasecontext=createContext(firebaseapp);
  export default firebasecontext;
  export const useFirebase = () => useContext(firebasecontext);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  export const FirebaseProvider=(props)=>{
    
    
    //signup function 
    const [formData,setFormData]=useState({
      password:"",
      confirmpassword:"",      
      nameofperson:"",
      //common in both seller and user
      email:"",
      birthday:"",
      username:"",
      state:"",
      mobilenumber:"",
      // from here it will be for the seller detail which only for seller option
      storename:"",
      country:"",
      category:"",
      gstin:"",
      storeadress:"",
      businesstype:"",
      pickupadress:{
        line1:"",
        line2:"",
        city:"",
        pincode:0
      },
        bankdetail:{
          accountnumber:0,
          accountHoldername:"",
          ifsccode:0,
          bankname:""
        },
    });
    console.log(formData);
    const handleChange = (e) => {
      e.preventDefault();
      setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //uploading the banner and profile image;
  const [toastMessage, setToastMessage] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [respdata,setRespdata]=useState(null);
  const uploadbannerprofileimg = async (bannerfiles, profilefiles) => {
    if (!bannerfiles || !profilefiles) {
      setToastMessage('No file selected');
      setOpenToast(true);
      return;
    }
  
    // Convert files to base64
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
  
    try {
      const bannerBase64 = await getBase64(bannerfiles);
      const profileBase64 = await getBase64(profilefiles);
  
      const url = `${backendURL}/userdetail/banner_profile_image`;
      
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bannerimage: bannerBase64,
          profileimage: profileBase64
        }),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        setToastMessage('Banner and profile image uploaded successfully!');
        setOpenToast(true);
  
        setRespdata({
          bannerImageUrl: responseData.bannerImageUrl,
          profileImageUrl: responseData.profileImageUrl
        });
      } else {
        const errorData = await response.json();
        setToastMessage(errorData.message || 'Failed to upload images');
        setOpenToast(true);
      }
    } catch (error) {
      console.error('Upload error:', error);
      setToastMessage('Network error. Please try again.');
      setOpenToast(true);
    }
  };
    
    //adding doc in firestore when userlogin
    const handlefirebasestorageforseller=async ()=>{
      try{ 
        console.log(respdata);
        const userDoc=await addDoc(collection(firestore, "UsersDetail"),{
        username:formData.username,
        // Email:email,
        // name:formData.nameofperson,
        email:formData.email,
        following:[], 
        followers:[],
        mobilenumber:formData.mobilenumber,
        birthday:formData.birthday,
        state:formData.state,
        bannerimage:respdata.bannerImageUrl,
        profileimage:respdata.profileImageUrl,
        isSeller:true,
        storename:formData.storename,
        gstin:formData.gstin||"",
        country:formData.country,
        businessType:formData.businesstype,
      category:formData.category,
      storeadress:formData.storeaddress,
      pickupadress:{
        line1:formData.line1 || "",
        line2:formData.line2 || "",
        city:formData.city || "",
        pincode:formData.pincode|| 0,
      },
        bankdetail:{
          accountNumber:formData.accountnumber||0,
          accountHolderName:formData.accountholdername||"",
          ifscCode:formData.ifsccode||0,
          bankName:formData.bankname||""
        },
        createdAt:Date.now(),
        updatedAt:Date.now()
      })
      console.log("stored data:",userDoc);
    }
    catch (error) {
      console.error("Error storing user data:", error);
      throw error;
    }
      }
      //adding doc in firstore when seller login intially 
    const handlefirebasestorageforuser=async ()=>{
      try{ 
        const userDoc=await addDoc(collection(firestore, "UsersDetail"),{
        Username:formData.username,
        // Email:email,
        name:formData.nameofperson,
        email:formData.email,
        following:[], 
        followers:[],
        mobilenumber:formData.mobilenumber,
        birthday:formData.birthday,
        state:formData.state,
        bannerimage:respdata,
        profileimage:respdata,
        isSeller:false,
        createdAt:Date.now(),
        updatedAt:Date.now()
      })
      console.log("stored data:",userDoc);
    }
    catch (error) {
      console.error("Error storing user data:", error);
      throw error;
    }
      }
      //add
   //login function
   const[alwaysloggedin,setalwaysloggedin]=useState(false);
   const[isLoginpage,setIslogin]=useState(true);
   const [sellerid,setsellerid]=useState(null);
   const auth = getAuth(firebaseapp);
   const  handlesignin=async (email,password)=>{
    // e.preventDefault();
   await setPersistence(auth, browserLocalPersistence);
     await signInWithEmailAndPassword(auth, email,password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    if(user){
    setalwaysloggedin(true);
    console.log(user);
    }
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorMessage,errorCode);
  });
}
// //handle sinup
const handlesignup=async ()=>{
  try {
    console.log(formData.password," ",formData.confirmpassword );

    // Basic validation
    if (!formData.email || !formData.password) {
        throw new Error('Please fill in all fields');
    }
    
        if (formData.password !== formData.confirmpassword) {
            throw new Error('Passwords do not match');
        }

        if (formData.password.length < 8) {
            throw new Error('Password must be at least 8 characters long');
        }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
        throw new Error('Please enter a valid email address');
    }
    await createUserWithEmailAndPassword(auth,formData.email,formData.password).then((userCredential)=>{
      const user=userCredential.user;
      if(user){
        alert("successful");
      }
    }).catch((err)=>{
      console.log("error occured",err);
    })
}catch(error){
  console.log('error occured:',error)
  }
}
//handle next page in scrollsingup
const [isSignup,setIssignup]=useState(false);
//handlesingup render on click of singup in login page
const handlesiguprender=()=>{
  return setIssignup(true);
}
const [loading, setLoading] = useState(true);
useEffect(() => {
  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      setalwaysloggedin(true);
    } else {
      setalwaysloggedin(false);
    }
    setLoading(false); // Loading is complete after checking auth state
  });

  return () => unsubscribe(); // Cleanup the listener on unmount
}, []);

//CHECK ADMIN OR NOT

const [file, setFile] = useState(null);

// Call this function to get the logged-in user's data and check if they are an admin
  const[isadmin,setIsadmin]=useState(true);
  const [currentId,setCurrentId]=useState(null);
  const [adminRef,setAdminRef]=useState(null);
  const checkIfAdminPage = async (username) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log("Current user not found");
      return;
    }
   
    try {
      const db = getFirestore();
      const q = query(
        collection(db, 'UsersDetail'), 
        where('email', '==', currentUser.email)
      );
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        setAdminRef(userDoc.id);
        setCurrentId(userData.username);
        // Compare with the passed username parameter instead of currentId
        setIsadmin(userData.username !== username);
        if (userData.isSeller === true) {
          setsellerid(userData.username);
        }
      }
    } catch (error) {
      console.error("Error checking admin status:", error);
    }
  };
  //get the current user which is admin 
 //set the scrolls and styles so that we can differentiate the upload styles and scrolls
const [switchtoStyles,setswitchtoStyles]=useState(false);
  return (
    <firebasecontext.Provider value={{
      handlefirebasestorageforuser,handlefirebasestorageforseller,handlesignin,isLoginpage,alwaysloggedin,handleChange,formData,handlesignup,handlesiguprender,isSignup,checkIfAdminPage,isadmin,currentId,setFile,file,setswitchtoStyles,switchtoStyles,toastMessage,openToast,uploadbannerprofileimg,setOpenToast,firestore,sellerid,adminRef
    }}>
      {props.children}
    </firebasecontext.Provider>
  )
  }