import React, { useEffect, useState } from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom';
import hotpricetag from '../Hot Price Tag.png';
import scroll from '../Now.png';
import styles from '../styles.png';
import profilelive from '../profileicon.png';
import profileproduct from '../profileproducts.png'
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { SearchIcon, CheckCircle2, Star } from 'lucide-react';
import ProfileScroll from './ProfileScroll';
import { Button } from 'react-scroll';
import Styles from './Styles';
import ProductSection from './ProductSection';
import LiveProductSection from './LiveProductSection';
import HotProductSection from './HotProductSection';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import SocialList from './SocialList';
const ProfileMainPage = () => {
  const [isLoading, setIsLoading] = useState(false);

    const[isSeller,setIsseller]=useState(false);
    const[scrolldata,setScrolldata]=useState([]);
    const[styledata,setStyledata]=useState([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [pagiantion,setPagination]=useState(null);
      const [profiledetail,setIsprofiledetail]=useState({
        name: "RAHUL",
        brand: "SCROLL",
        content: "Social Media with Ecommerce",
        scrolls: 0,
        followers: 0,
        following: 0,
        styles: 0
      })
      const handleProductSearch = async (query, page = 1) => {
        if (!query.trim()) {
            setSearchResults([]);
            setIsSearching(false);
            return;
        }
    
        setIsSearching(true);
        try {
            const response = await fetch(
                `${backendURL}/userdetail/user/serach/for/profile/page/${username}/${query}?page=${page}&limit=10`
            );
            if (!response.ok) throw new Error(`Response status: ${response.status}`);
            
            const data = await response.json();
            setSearchResults(data.products);
            setPagination(data.pagination);
        } catch (error) {
            console.error('Error searching products:', error);
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    // Add debounced search effect
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (searchQuery) {
                handleProductSearch(searchQuery);
            }
        }, 300);

        return () => clearTimeout(timeoutId);
    }, [searchQuery]);


        // Function to check if current user is following the profile
    const checkFollowStatus = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser?.email;
      if (!currentUser) return;

      try {
          const response = await fetch(`${backendURL}/userdetail/checkFollowStatus/${username}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: currentUser }),
          });

          if (!response.ok) throw new Error('Failed to check follow status');
          
          const data = await response.json();
          setIsFollowing(data.isFollowing);
      } catch (error) {
          console.error('Error checking follow status:', error);
      }
  };

      const {username} = useParams();
      console.log(username);
      const Profilepagefetching = async () => {
        const url = `${backendURL}/userdetail/${username}`;
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`Response status: ${response.status}`);
            
            const profileData = await response.json();
            setIsprofiledetail(profileData.userprofile);
            console.log(profileData);
            setScrolldata(profileData.userprofile.scrolls || []);
            setStyledata(profileData.userprofile.styles || []);
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };
  
  //followers api handling
  const auth = getAuth();
  const handlefollowers = async () => {
    
    const currentUser = auth.currentUser?.email;
    if (!currentUser) return;
    setIsLoading(true);
    try {
        const response = await fetch(`${backendURL}/userdetail/follower_following/${username}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: currentUser }),
        });

        if (!response.ok) throw new Error('Failed to update follow status');

        const result = await response.json();
        
        if (result.success) {
            setIsFollowing(result.isFollowing);
            
            // Update the followers count locally
            setIsprofiledetail(prev => ({
                ...prev,
                followers: result.isFollowing 
                    ? [...prev.followers, auth.currentUser?.email]
                    : prev.followers.filter(f => f !== auth.currentUser?.email)
            }));
        }
    } catch (error) {
        console.error('Error updating follow status:', error);
    }finally{
      setIsLoading(true);
    }
};
  //handling styles
  const Navigate=useNavigate();
  const gottostylepage=()=>{
    Navigate(`/userdetail/${username}/Styles`);
  }
  // const gottostyles=()=>{
  //   Navigate(`/userdetail/${username}/SocialList`)
  // }
  // arraofdata.forEach((val)=>{
  //   console.log(val.images[0]);
  // })
  
  const firebase=useFirebase();
  // Profilepagefetching()
  useEffect(() => {
    const loadProfileData = async () => {
        await Profilepagefetching();
        await checkFollowStatus();
    };
    console.log(firebase.currentId);
     firebase.checkIfAdminPage(username);
    loadProfileData();
}, [username]);
  // console.log(styledata)
  

  // const handleFollow = async () => {
  //   try {
  //     await handlefollowers(); // Call the existing API function
  //     setIsFollowing(!isFollowing);
  //   } catch (error) {
  //     console.error('Error toggling follow state:', error);
  //   }
  // };

  return (
    <div className="bg-gradient-to-b from-black/50 via-black to-[#121212]">
      {/* Profile Header */}
      <div>
        {/* Banner Image */}
        <div className="relative h-36">
          <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/80" />
          <img 
            src={profiledetail.bannerimage} 
            alt="Brand Poster" 
            className="w-full h-full object-cover"
          />
        </div>

        {/* Profile Content */}
        <div className="px-3">
          {/* Profile Image and Info */}
          <div className='flex flex-row'>
            <div className="relative">
              <img 
                src={profiledetail.profileimage}
                alt="Profile" 
                className="relative -mt-[40px] w-24 h-24 object-cover rounded-full 
                  border-[3px] border-black bg-black shadow-2xl"
              />
              {profiledetail.isSeller && (
                <div className="absolute -right-1 -bottom-1 bg-white rounded-full p-[3px] shadow-xl">
                  <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" 
                      d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM16.59 8.59L10 15.17L7.41 12.59L6 14L10 18L18 10L16.59 8.59Z" 
                      fill="#0095F6"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="ml-3 pt-1 flex-1">
              <div className="flex justify-between items-start w-full">
                <div>
                  <h1 className="text-lg font-semibold text-white">{profiledetail.name}</h1>
                  <p className="text-gray-400 text-sm">{profiledetail.username}</p>
                </div>
                {profiledetail.isSeller && (
                  <div className="flex items-center gap-1">
                    <span className="text-white text-sm font-medium">4.3</span>
                    <Star className="w-4 h-4 fill-[url(#star-gradient)] text-transparent" />
                    <svg width="0" height="0">
                      <defs>
                        <linearGradient id="star-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" style={{stopColor: '#FFD700'}} />
                          <stop offset="100%" style={{stopColor: '#FFA500'}} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                )}
              </div>
              <p className="text-gray-300 text-sm mt-1">{profiledetail.desc}</p>
            </div>
          </div>

          {/* Stats */}
          <div className="flex justify-between px-2 mt-4">
                {[
                    { label: 'Scrolls', value: scrolldata.length || 0 },
                    { label: 'Followers', value: profiledetail.followers?.length || 0 },
                    { label: 'Following', value: profiledetail.following?.length || 0 },
                    { label: 'Styles', value: styledata.length || 0 }
                ].map((stat, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            if (stat.label === 'Followers' || stat.label === 'Following') {
                                Navigate(`/userdetail/${username}/SocialList`);
                            }
                        }}
                        className="group flex flex-col items-center"
                    >
                        <span className="text-lg font-semibold text-white group-hover:text-[#fe2c55] transition-colors">
                            {stat.value}
                        </span>
                        <span className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors">
                            {stat.label}
                        </span>
                    </button>
                ))}
            </div>

            {/* Follow Button - Only show if not viewing own profile */}
            {firebase.currentId !== username && (
                <div className="flex gap-2 mt-4 mb-4">
                    <button
                        onClick={handlefollowers}
                        className={`flex-1 py-2 rounded-full font-medium transition-all duration-300
                            ${isFollowing
                                ? 'bg-white/10 text-white hover:bg-red-500/20 hover:text-red-500 border border-white/10'
                                : 'bg-[#fe2c55] text-white hover:bg-[#fe2c55]/90 active:bg-[#fe2c55]/80'
                            }`}
                    >
                        {isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                    <button
                        className="flex-1 bg-white/10 text-white py-2 rounded-full font-medium 
                            backdrop-blur-xl hover:bg-white/20 active:bg-white/30 transition-colors 
                            border border-white/10"
                    >
                        Message
                    </button>
                </div>
            )}

        </div>
      </div>

      {/* Search and Navigation - Will stick together */}
      <div className="sticky top-0 z-20 bg-black/95 backdrop-blur-xl border-b border-white/[0.08]">
        {/* Search Bar */}
        <div className="sticky top-0 z-20 bg-black/95 backdrop-blur-xl border-b border-white/[0.08]">
                <div className="px-3 py-2">
                    <div className="relative">
                        <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
                            <SearchIcon className="h-5 w-5" />
                        </div>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search products in profile..."
                            className="w-full pl-10 pr-4 py-2 bg-white/10 border border-white/10 rounded-full
                                text-white placeholder-gray-400 focus:outline-none focus:ring-2 
                                focus:ring-[#fe2c55] focus:border-transparent text-sm backdrop-blur-xl"
                        />
                    </div>

                    {/* Search Results */}
                    {searchQuery && (
                        <div className="absolute left-0 right-0 mt-2 mx-3 bg-black/95 border border-white/10 rounded-xl overflow-hidden shadow-xl z-50">
                            {isSearching ? (
                                <div className="p-4 text-center text-gray-400">
                                    Searching...
                                </div>
                            ) : searchResults.length > 0 ? (
                                <div className="max-h-[60vh] overflow-y-auto">
                                    {searchResults.map((product) => (
                                        <Link
                                            key={product.id}
                                            to={`/ProductPage/${product.id}`}
                                            className="flex items-center gap-3 p-3 hover:bg-white/10 transition-colors"
                                        >
                                            <img
                                                src={product.image}
                                                alt={product.name}
                                                className="w-12 h-12 object-cover rounded-lg"
                                            />
                                            <div className="flex-1">
                                                <h3 className="text-white font-medium">{product.name}</h3>
                                                <p className="text-gray-400 text-sm">${product.price}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            ) : (
                                <div className="p-4 text-center text-gray-400">
                                    No products found
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

        {/* Category Icons */}
        <div className="flex justify-around py-2">
          <Button onClick={() => Navigate(`/userdetail/${username}`)}>
            <img className='w-7 h-7 opacity-80 hover:opacity-100 transition-opacity' src={scroll} alt="scrollicon" />
          </Button>       
          {profiledetail.isSeller && (
            <Button onClick={()=>{Navigate(`/userdetail/${username}/ProductSection`)}}>
              <img className='w-7 h-7 opacity-80 hover:opacity-100 transition-opacity' src={profileproduct} alt="producticon" />
            </Button>
          )}
          <Button onClick={gottostylepage}>
            <img className='w-7 h-7 opacity-80 hover:opacity-100 transition-opacity' src={styles} alt="stylesicon" />
          </Button>
          {profiledetail.isSeller && (
            <Button onClick={()=>{Navigate(`/userdetail/${username}/LiveProduct`)}}>
              <img className='w-7 h-7 opacity-80 hover:opacity-100 transition-opacity' src={profilelive} alt="liveicon" />
            </Button>
          )}
          {profiledetail.isSeller && (
            <Button onClick={()=>{Navigate(`/userdetail/${username}/HotProduct`)}}>
              <img className='w-7 h-7 opacity-80 hover:opacity-100 transition-opacity' src={hotpricetag} alt="hotpricetagicon" />
            </Button>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div>
        <Routes>
          <Route path="/" element={<ProfileScroll />} />
          <Route path="Styles" element={<Styles/>} />
          <Route path="ProductSection" element={<ProductSection/>} />
          <Route path="LiveProduct" element={<LiveProductSection/>} />
          <Route path="HotProduct" element={<HotProductSection/>} />
        </Routes>
      </div>
    </div>
  )
}

export default ProfileMainPage
