import React, { useState, useEffect, useRef } from 'react';
import { MessageCircle, Send, Reply, ThumbsUp, MoreVertical } from 'lucide-react';

const CommentSystemStyles = ({ stylesId, currentUserId, onClose }) => {
  const [comments, setComments] = useState([]); // Initialize as empty array
  const [newComment, setNewComment] = useState('');
  const [replyTo, setReplyTo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const containerRef = useRef(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  // Safely get last comment ID
  const lastCommentId = comments && comments.length > 0 ? comments[comments.length - 1].id : null;

  const fetchComments = async (lastId = null) => {
    if (!stylesId) return; // Add check for stylesId

    try {
      setLoading(true);
      const queryParams = new URLSearchParams({
        limit: '10',
        ...(lastId && { lastCommentId: lastId }),
      });

      const response = await fetch(
        `${backendURL}/userdetail/styles/get/of/comments/${stylesId}?${queryParams}`
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (!lastId) {
        setComments(data.comments || []); // Ensure we always set an array
      } else {
        setComments(prev => [...prev, ...(data.comments || [])]);
      }
      setHasMore(data.hasMore || false);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setComments([]); // Set empty array on error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stylesId) {
      fetchComments();
    }
  }, [stylesId]); // Add stylesId to dependency array

  const handleScroll = () => {
    if (!containerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollHeight - scrollTop <= clientHeight * 1.5 &&
      hasMore &&
      !loadingMore &&
      !loading
    ) {
      loadMoreComments();
    }
  };

  const loadMoreComments = async () => {
    if (!hasMore || loadingMore || !lastCommentId) return;
    
    setLoadingMore(true);
    await fetchComments(lastCommentId);
    setLoadingMore(false);
  };

  const handleSubmitComment = async () => {
    if (!newComment.trim() || !stylesId || !currentUserId) return;
    
    setLoading(true);
    try {
      const response = await fetch(`${backendURL}/userdetail/styles/add/of/comments/${stylesId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: currentUserId,
          text: newComment,
          replyTo: replyTo?.id
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        // Add new comment to the beginning since we're sorting by newest
        setComments(prev => [data.comment, ...prev]);
        setNewComment('');
        setReplyTo(null);
      }
    } catch (error) {
      console.error('Error posting comment:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    const now = new Date();
    const diff = now - date;
    
    // Less than 1 minute
    if (diff < 60000) {
      return 'Just now';
    }
    // Less than 1 hour
    if (diff < 3600000) {
      const minutes = Math.floor(diff / 60000);
      return `${minutes}m ago`;
    }
    // Less than 1 day
    if (diff < 86400000) {
      const hours = Math.floor(diff / 3600000);
      return `${hours}h ago`;
    }
    // Less than 7 days
    if (diff < 604800000) {
      const days = Math.floor(diff / 86400000);
      return `${days}d ago`;
    }
    // Otherwise return the date
    return date.toLocaleDateString();
  };

  // Add null checks for required props
  if (!stylesId || !currentUserId) {
    return (
      <div className="fixed inset-0 bg-black/95 z-50 flex items-center justify-center">
        <div className="text-white">Missing required props: stylesId or currentUserId</div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/95 z-50 flex flex-col">
      {/* Header */}
      <div className="p-4 border-b border-white/10 flex justify-between items-center">
        <h2 className="text-white text-lg font-semibold">Comments</h2>
        <button 
          onClick={onClose}
          className="text-white/70 hover:text-white"
        >
          Close
        </button>
      </div>

      {/* Comments List */}
      <div 
        ref={containerRef}
        className="flex-1 overflow-y-auto p-4 space-y-4"
        onScroll={handleScroll}
      >
        {loading && comments.length === 0 ? (
          <div className="flex justify-center items-center h-32">
            <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin"></div>
          </div>
        ) : comments.length === 0 ? (
          <div className="text-center text-white/50 py-8">
            No comments yet. Be the first to comment!
          </div>
        ) : (
          <>
            {comments.map(comment => (
              <div key={comment.id} className="space-y-2">
                {/* Main Comment */}
                <div className="flex gap-3">
                  <img 
                    src={comment.userAvatar || "/default-avatar.png"} 
                    alt={comment.username}
                    className="w-8 h-8 rounded-full"
                  />
                  <div className="flex-1">
                    <div className="flex items-center gap-2">
                      <span className="text-white font-medium">{comment.username}</span>
                      <span className="text-white/50 text-sm">
                        {formatDate(comment.createdAt)}
                      </span>
                    </div>
                    <p className="text-white/90 mt-1">{comment.text}</p>
                    <div className="flex items-center gap-4 mt-2">
                      <button 
                        className="text-white/50 hover:text-white text-sm flex items-center gap-1"
                        onClick={() => setReplyTo(comment)}
                      >
                        <Reply className="w-4 h-4" />
                        Reply
                      </button>
                      <button className="text-white/50 hover:text-white text-sm flex items-center gap-1">
                        <ThumbsUp className="w-4 h-4" />
                        {comment.likes || 0}
                      </button>
                    </div>
                  </div>
                </div>

                {/* Replies */}
                {comment.replies && comment.replies.length > 0 && (
                  <div className="ml-11 space-y-3">
                    {comment.replies.map(reply => (
                      <div key={reply.id} className="flex gap-3">
                        <img 
                          src={reply.userAvatar || "/default-avatar.png"} 
                          alt={reply.username}
                          className="w-6 h-6 rounded-full"
                        />
                        <div className="flex-1">
                          <div className="flex items-center gap-2">
                            <span className="text-white font-medium text-sm">{reply.username}</span>
                            <span className="text-white/50 text-xs">
                              {formatDate(reply.createdAt)}
                            </span>
                          </div>
                          <p className="text-white/90 text-sm mt-1">{reply.text}</p>
                          <div className="flex items-center gap-4 mt-2">
                            <button className="text-white/50 hover:text-white text-xs flex items-center gap-1">
                              <ThumbsUp className="w-3 h-3" />
                              {reply.likes || 0}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
            
            {loadingMore && (
              <div className="flex justify-center items-center py-4">
                <div className="w-6 h-6 border-2 border-white/20 border-t-white rounded-full animate-spin"></div>
              </div>
            )}
          </>
        )}
      </div>

      {/* Comment Input */}
      <div className="p-4 border-t border-white/10">
        {replyTo && (
          <div className="mb-2 flex justify-between items-center">
            <span className="text-white/70 text-sm">
              Replying to {replyTo.username}
            </span>
            <button 
              onClick={() => setReplyTo(null)}
              className="text-white/50 hover:text-white text-sm"
            >
              Cancel
            </button>
          </div>
        )}
        <div className="flex gap-2">
          <input
            type="text"
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className="flex-1 bg-white/10 rounded-full px-4 py-2 text-white placeholder-white/50 focus:outline-none focus:ring-2 focus:ring-white/20"
          />
          <button
            onClick={handleSubmitComment}
            disabled={loading || !newComment.trim()}
            className="w-10 h-10 rounded-full bg-white/10 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="w-5 h-5 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentSystemStyles;