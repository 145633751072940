import React from 'react'
import scrollimage from '../scrollimage.png'
import scroll from '../scroll.png'
import { Link } from 'react-router-dom'
const Scrollpage = ({imageUrl,productid,isDiscover}) => {
//fetch data of each product

  // console.log(productid," ", imageUrl)
  return (
    <Link to={`/scroll/foryou/specific/${productid}`}>
    <div className={`scrollimage ${isDiscover?'w-full h-full ':' w-[6.9rem] h-[7.7rem]'} bg-cover bg-no-repeat bg-center bg-white rounded-lg flex flex-col justify-end mx-1`} style={{backgroundImage:`url(${imageUrl})`}}>
      <div className='flex flex-row text-black items-center w-full h-[20%]'>
        <img className=' ml-2 w-[10%] h-[52%] ' src={scroll} alt='scroll'/>
        {/* <p className='ml-2'>2.3M</p> */}
      </div>
    </div>
    </Link>

  )
}

export default Scrollpage
