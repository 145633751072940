import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';

const ProductDisplayWTags = () => {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [showAllTags, setShowAllTags] = useState(true);
  const [selectedTag, setSelectedTag] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [scrolldata, setScrolldata] = useState({
    coverImage: '',
    productTags: [],
    videoUrl: '',
    thumbnailUrl: '',
    name: '',
    uploadedAt: '',
    email: '',
    userId: ''
  });
  const { scrollid } = useParams();

  // Navigation Icons Components
  const BackIcon = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
    </svg>
  );

  const HomeIcon = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
    </svg>
  );

  const DiscoverIcon = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
  );

  const ScrollIcon = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
    </svg>
  );

  const LiveIcon = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
    </svg>
  );

  const ProfileIcon = () => (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
    </svg>
  );

  const fetchProductDetails = async (productid) => {
    try {
        // console.log(productId);
      const response = await fetch(`${backendURL}/userdetail/product_details?productid=${productid}`);
    //   console.log(response);
      if (!response.ok) {
        throw new Error('Failed to fetch product details');
      }
      const data = await response.json();
      return data.productdetails;
    } catch (error) {
      console.error('Error fetching product details:', error);
      return null;
    }
  };

  const fetchScrollData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // First fetch scroll data
      const scrollResponse = await fetch(`${backendURL}/userdetail/scrollsdata/specific/${scrollid}`);
      if (!scrollResponse.ok) {
        throw new Error('Failed to fetch scroll data');
      }
      
      const scrollJson = await scrollResponse.json();
      const scrollData = scrollJson.scrolls;
      setScrolldata(scrollData);

      // If there are product tags, fetch details for each product
      if (scrollData.productTags && Array.isArray(scrollData.productTags)) {
        const productPromises = scrollData.productTags.map(async (tag) => {
          const productData = await fetchProductDetails(tag.productId);
          if (productData) {
            return {
              ...productData,
              id: tag.productId,
              position: tag.position
            };
          }
          return null;
        });

        const resolvedProducts = await Promise.all(productPromises);
        setTags(resolvedProducts.filter(product => product !== null));
      }
    } catch (err) {
      setError(err.message);
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    fetchScrollData();
  }, [scrollid]);


  return (
    <div className="fixed inset-0 bg-black">
      {/* Full screen background image */}
      <div className="relative w-full h-full">
        <img 
          src={scrolldata.coverImage}
          alt="Product Display"
          className="w-full h-full object-cover"
        />

        {/* Overlay for tags */}
        <div className="absolute inset-0">
          {/* Header with controls */}
          <div className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center z-10">
            <button 
              onClick={() => navigate(-1)}
              className="text-white p-2 rounded-full bg-black/50 backdrop-blur-sm"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button 
              onClick={() => setShowAllTags(!showAllTags)}
              className="text-white px-4 py-2 rounded-full bg-black/50 backdrop-blur-sm"
            >
              {showAllTags ? 'Hide Tags' : 'Show Tags'}
            </button>
          </div>

          {/* Product Tags */}
          {showAllTags && tags.map((tag) => (
            <div
              key={tag.id}
              className="absolute"
              style={{
                left: `${tag.position.x}%`,
                top: `${tag.position.y}%`,
                transform: 'translate(-50%, -50%)'
              }}
            >
              <button
                onClick={() => setSelectedTag(selectedTag === tag.id ? null : tag.id)}
                className="w-8 h-8 rounded-full bg-white/20 backdrop-blur-sm border-2 border-white flex items-center justify-center hover:bg-white/30 transition-colors"
              >
                <span className="w-2 h-2 bg-white rounded-full" />
              </button>
              
              {/* Product Info Card */}
              {selectedTag === tag.id && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 bg-white/90 backdrop-blur-sm rounded-lg p-3 shadow-lg min-w-[150px]">
                  <div className="text-black font-medium">{tag.name}</div>
                  <div className="text-black/70">${tag.price}</div>
                  <button 
                    onClick={() => navigate(`/ProductPage/${tag.id}`)}
                    className="mt-2 w-full bg-black text-white px-3 py-1 rounded-full text-sm hover:bg-black/80 transition-colors"
                  >
                    View Details
                  </button>
                </div>
              )}
            </div>
          ))}

          {/* Bottom Navigation */}
          <div className="absolute bottom-0 left-0 right-0 p-4 flex justify-between items-center bg-gradient-to-t from-black/50 to-transparent">
            <button 
              onClick={() => navigate('/')}
              className="text-white flex flex-col items-center opacity-70 hover:opacity-100"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              <span className="text-xs mt-1">Home</span>
            </button>

            <button 
              onClick={() => navigate('/discover')}
              className="text-white flex flex-col items-center opacity-70 hover:opacity-100"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
              <span className="text-xs mt-1">Discover</span>
            </button>

            <button 
              onClick={() => navigate('/scroll/foryou')}
              className="text-white flex flex-col items-center opacity-70 hover:opacity-100"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
              </svg>
              <span className="text-xs mt-1">Scroll</span>
            </button>

            <button 
              onClick={() => navigate('/live')}
              className="text-white flex flex-col items-center opacity-70 hover:opacity-100"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
              <span className="text-xs mt-1">Live</span>
            </button>

            <button 
              onClick={() => navigate('/profile')}
              className="text-white flex flex-col items-center opacity-70 hover:opacity-100"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <span className="text-xs mt-1">Profile</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDisplayWTags; 