import React, { useDebugValue, useEffect, useState } from 'react';
import './App.css';
import HomPage from './component/HomPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import ProductCard from './component/ProductCard';
import { Provider } from 'react-redux';
import { store } from './app/store'
import Profile from './component/Profile';
import CheckoutPage from './component/CheckoutPage';
import ScrollLogin from './component/ScrollLogin';
import { useFirebase } from './FirebaseContext/FIrebaseContext';
import Discover from './component/Discover';
import ScrollSignup from './component/ScrollSignup';
import EditProfile from './component/EditProfile';
import Styles from './component/Styles';
import ProductSection from './component/ProductSection';
import LiveProductSection from './component/LiveProductSection';
import HotProductSection from './component/HotProductSection';
import PostVideoPage from './component/PostVideoPage';
import NewScrollPage from './component/NewScrollPage';
import SocialList from './component/SocialList';
import ProfileMainPage from './component/ProfileMainPage';
// import LiveStreamPage from './component/LiveStreamPag';
// import ManageProducts from './component/ManageProducts';
import EditProduct from './component/EditProduct';
import ProductPage from './component/ProductPage';
// import { Settings, Wallet } from 'lucide-react';
import Saved from './component/Saved';
import LiveStreamPage from './component/LiveStreamPage';
import HostLiveStream from './component/HostLiveStream';
import ReelsPlayer from './component/ReelsPlayer';
import Cart from './component/Cart';
import ManageProducts from './component/ManageProducts';
import Settings from './component/Settings';
import Wallet from './component/Wallet';
import ScrollFollowing from './component/ScrollFollowing';
import LiveStreamHost from './component/LiveStreamHost';
import LiveStreamList from './component/LiveStreamList';
import LiveStreamViewer from './component/LiveStreamViewer ';
import ProductDisplayWTags from './component/ProductDisplayWTags';
import ReviewAndPay from './component/ReviewAndPay';
import OrderManagement from './component/OrderManagement';
import AddAddress from './component/AddAddress';
import ScrollLives from './component/ScrollLives';
import MobileOnlyWrapper from './component/MobileOnlyWrapper';
// import Cart from './component/Cart.jsx';
// import SignUpForm from './component/SingUpForm';
function App() {
  const firebase = useFirebase();
  // console.log("alwaysloggedin",firebase.alwaysloggedin);
  // const [showpage,setShowpage]=useState(true);
  return (
    <MobileOnlyWrapper>
    <Provider store={store}>
      <BrowserRouter>
        <div className="
          App w-full min-h-screen flex justify-center items-center bg-black">
          {firebase.alwaysloggedin ? (
            <div className="
            w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-full bg-white shadow-md
          ">
              <Routes>
                <Route path='/' element={<HomPage />} />
                <Route path='/Discover' element={<Discover />} />
                <Route path='/scroll' element={<HomPage />}>
                  <Route path='following' element={<HomPage />} />
                  <Route path='foryou' element={<HomPage />} />
                  {/* <Route path='foryou/specific/:documentId' element={<HomPage />} /> */}
                </Route>
                <Route path="/scroll/foryou/specific/:documentId" element={<ScrollFollowing />} />
                <Route path='/live' element={<LiveStreamPage/>} />
                <Route path='/host-live' element={<HostLiveStream/>} />
                <Route path='/LiveStreamHost' element={<LiveStreamHost/>} />
                <Route path='/LiveStreamList' element={<ScrollLives/>} />
                <Route path='/LiveStreamViewer' element={<LiveStreamViewer/>} />
                <Route path='/userdetail/:username' element={<Profile />}>
                  <Route path="Profile" element={<ProfileMainPage />} />
                  <Route path="SocialList" element={<SocialList />} />
                  <Route path='Styles' element={<Styles />} />
                  <Route path="ProductSection" element={<ProductSection />} />
                  <Route path="LiveProduct" element={<LiveProductSection />} />
                  <Route path="HotProduct" element={<HotProductSection />} />  
                </Route>
                <Route path='/ProductPage/:productid' element={<ProductPage/>} />
                <Route path='/ReviewAndPay' element={<ReviewAndPay/>} />
                <Route path='/OrderManagement' element={<OrderManagement/>} />
                <Route path='/ManageProducts' element={<ManageProducts/>} />
                <Route path='/EditProfile' element={<EditProfile />} />
                <Route path='/PostVideoPage' element={<PostVideoPage />} />
                <Route path='/NewScrollPage' element={<NewScrollPage />} />
                <Route path='/listproduct' element={<EditProduct/>} />
                <Route path='/ProductDisplayWTags/:scrollid' element={<ProductDisplayWTags/>} />
                <Route path="/Settings" element={<Settings/>} />
                  <Route path="/Wallet" element={<Wallet/>} />
                  <Route path="/Saved" element={<Saved/>} />
                  <Route path="/Scrolls/:username" element={<ReelsPlayer/>} />
                  <Route path="/Cart" element={<Cart/>} />
                  <Route path="/AddAddress" element={<AddAddress/>} />
                  <Route path="/login" element={<ScrollLogin/>} />
    <Route path="/signup" element={<ScrollSignup />} />
                  {/* <Route path="/Cart" element={<Cart/>} /> */}
              </Routes>

            </div>
          ) : (<ScrollLogin />)}
        </div>
      </BrowserRouter>
    </Provider>
    </MobileOnlyWrapper>
  );
}

export default App;