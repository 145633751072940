import React, { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const AddAddress = () => {
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const [formData, setFormData] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pincode: '',
    phoneNumber: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const auth=getAuth();
 const emailid=auth.currentUser?.email;
  const addAddress = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${backendURL}/userdetail/add/user/address?email=${emailid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to add address. Please try again.');
      }

      const data = await response.json();
      console.log('Address added successfully:', data);

      // Redirect or show success message
      navigate(-1); // Navigate back to the previous page
    } catch (error) {
      console.error('Error occurred:', error);
      setError(error.message || 'Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (isFormValid()) {
      addAddress();
    }
  };

  const isFormValid = () => {
    return (
      formData.addressLine1 &&
      formData.city &&
      formData.state &&
      formData.pincode &&
      formData.phoneNumber
    );
  };

  return (
    <div className="fixed inset-0 bg-black text-white z-50">
      {/* Header */}
      <div className="flex items-center p-4 bg-black/90 backdrop-blur-lg border-b border-white/[0.08]">
        <button
          onClick={() => navigate(-1)}
          className="p-2 hover:bg-white/10 rounded-full transition-colors"
        >
          <ChevronLeft size={24} />
        </button>
        <h1 className="text-[17px] font-semibold ml-2">Add Address</h1>
      </div>

      {/* Main Content */}
      <div className="h-[calc(100vh-64px)] overflow-y-auto bg-gradient-to-b from-[#1C1C1E] to-black">
        <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-4 space-y-6">
          {/* Form Fields */}
          <div className="space-y-4">
            {/* Address Line 1 */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-white/60">
                Address Line 1
              </label>
              <input
                type="text"
                name="addressLine1"
                value={formData.addressLine1}
                onChange={handleInputChange}
                placeholder="Enter your street address"
                className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] border-none transition-all"
                required
              />
            </div>

            {/* Address Line 2 */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-white/60">
                Address Line 2 (Optional)
              </label>
              <input
                type="text"
                name="addressLine2"
                value={formData.addressLine2}
                onChange={handleInputChange}
                placeholder="Apartment, suite, etc."
                className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] border-none transition-all"
              />
            </div>

            {/* City and State Row */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-white/60">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="Enter city"
                  className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] border-none transition-all"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-white/60">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  placeholder="Enter state"
                  className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] border-none transition-all"
                  required
                />
              </div>
            </div>

            {/* Pincode and Phone Number Row */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-white/60">
                  Pincode
                </label>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  placeholder="Enter pincode"
                  maxLength="6"
                  className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] border-none transition-all"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-white/60">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  placeholder="Enter phone number"
                  maxLength="10"
                  className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] border-none transition-all"
                  required
                />
              </div>
            </div>
          </div>

          {/* Save Button */}
          <button
            type="submit"
            disabled={!isFormValid() || loading}
            className={`w-full py-3.5 rounded-xl font-medium text-base transition-all ${
              isFormValid() && !loading
                ? 'bg-[#FE2C55] hover:bg-[#ff1a45] active:bg-[#e01442]'
                : 'bg-[#2C2C2E] text-white/40 cursor-not-allowed'
            }`}
          >
            {loading ? 'Saving...' : 'Save Address'}
          </button>

          {/* Error Message */}
          {error && (
            <p className="text-red-500 text-sm mt-4">
              {error}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddAddress;
