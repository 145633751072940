import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ChevronLeft, MapPin, Package, Calendar, CreditCard } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

const OrderManagement = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isSchedulingPickup, setIsSchedulingPickup] = useState(false);
  const [isViewingDetails, setIsViewingDetails] = useState(false);
  const [pickupDetails, setPickupDetails] = useState({
    pickupAddress: '',
    pickupDate: ''
  });
  const [filterStatus, setFilterStatus] = useState('all');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  // Fetch seller's orders
  const auth =getAuth();
 const emailid=auth.currentUser?.email;
 console.log(emailid);
 useEffect(() => {
  const fetchSellerOrders = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/userdetail/get/order/for/seller?email=${emailid}`);
      if (!response.ok) throw new Error('Failed to fetch orders');
      const data = await response.json();
      // Ensure all orders have required fields with default values
      const processedOrders = data.map(order => ({
        ...order,
        status: order.status || 'Pending',
        createdAt: order?.createdAt || { _seconds: Date.now() / 1000 },
        totalAmount: order.totalAmount || 0,
        items: order.items || []
      }));
      setOrders(processedOrders);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  fetchSellerOrders();
}, [backendURL, emailid]);

  // Update order status
  const handleUpdateStatus = async (orderId, newStatus) => {
    try {
      const response = await fetch(`${backendURL}/userdetail/order/status/for/user/:${orderId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (!response.ok) throw new Error('Failed to update status');

      setOrders(orders.map(order => 
        order.id === orderId 
          ? { ...order, status: newStatus }
          : order
      ));
    } catch (err) {
      setError(err.message);
    }
  };

  

  // Schedule pickup
  const handleSchedulePickup = async () => {
    if (!selectedOrder || !pickupDetails.pickupAddress || !pickupDetails.pickupDate) return;

    try {
      console.log(selectedOrder.id);
      const response = await fetch(`${backendURL}/userdetail/create/order/pickup/seller/${selectedOrder.id}?email=${emailid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(pickupDetails)
      });

      if (!response.ok) throw new Error('Failed to schedule pickup');

      setOrders(orders.map(order => 
        order.id === selectedOrder.id 
          ? {
              ...order,
              pickupAddress: pickupDetails.pickupAddress,
              pickupDate: pickupDetails.pickupDate,
              status: 'Pickup Scheduled'
            }
          : order
      ));

      setIsSchedulingPickup(false);
      setSelectedOrder(null);
      setPickupDetails({ pickupAddress: '', pickupDate: '' });
    } catch (err) {
      setError(err.message);
    }
  };

  // View order details
  const handleViewDetails = async (orderId) => {
    try {
      const response = await fetch(`${backendURL}/userdetail/get/single/order/user/${orderId}?email=${emailid}`);
      if (!response.ok) throw new Error('Failed to fetch order details');
      const orderDetails = await response.json();
      setSelectedOrder(orderDetails);
      setIsViewingDetails(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const filteredOrders = filterStatus === 'all' 
    ? orders 
    : orders.filter(order => {
        // Handle case-insensitive comparison and ensure status exists
        const orderStatus = (order.status || '').toLowerCase();
        return orderStatus === filterStatus.toLowerCase();
      });

      if (!orders.length && !loading) {
        return (
          <div className="min-h-screen bg-gray-50 flex items-center justify-center">
            <div className="text-gray-500">No orders found.</div>
          </div>
        );
      }
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-gray-500">Loading orders...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-red-500">Error: {error}</div>
      </div>
    );
  }
  console.log(filteredOrders[0]?.createdAt?._seconds);

  return (
    <div className="min-h-screen bg-gray-50 relative">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-4 left-4 z-50 p-2 bg-white/70 backdrop-blur-sm rounded-full shadow-sm hover:bg-white/90"
      >
        <X className="w-5 h-5 text-gray-600" />
      </button>

      <div className="max-w-4xl mx-auto px-4 py-8">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <h1 className="text-3xl font-semibold text-gray-900">Seller Orders</h1>
          <p className="mt-2 text-gray-500">
            Manage orders and schedule pickups for your products
          </p>
        </motion.div>

        <div className="mb-6">
          <div className="flex space-x-2 bg-white/70 backdrop-blur-sm rounded-lg p-1 shadow-sm overflow-x-auto">
            {['all', 'pending', 'processing', 'ready for pickup', 'pickup scheduled'].map((status) => (
              <button
                key={status}
                onClick={() => setFilterStatus(status)}
                className={`px-4 py-2 rounded-md text-sm whitespace-nowrap ${
                  filterStatus === status 
                    ? 'bg-black text-white' 
                    : 'text-gray-600 hover:bg-gray-50'
                }`}
              >
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </button>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <AnimatePresence>
            {filteredOrders.map((order) => (
              <motion.div
                key={order.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="bg-white/70 backdrop-blur-sm rounded-lg p-6 shadow-sm"
              >
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-medium">Order #{order.id}</h3>
                    <p className="text-sm text-gray-500 mt-1">
                      Customer: {order.customerName}
                    </p>
                  </div>
                  <select
                    value={order.status}
                    onChange={(e) => handleUpdateStatus(order.id, e.target.value)}
                    className="ml-4 px-3 py-1 rounded-md text-sm border-gray-300 focus:ring-black focus:border-black"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Processing">Processing</option>
                    <option value="Ready for Pickup">Ready for Pickup</option>
                    <option value="Pickup Scheduled">Pickup Scheduled</option>
                  </select>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div>
                    <p className="text-sm text-gray-500">Order Date</p>
                    <p className="font-medium">
                      {new Date(order.createdAt?._seconds).toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Total Amount</p>
                    <p className="font-medium">${order.totalAmount?.toFixed(2)}</p>
                  </div>
                </div>

                <div className="flex space-x-3">
                  {!order.pickupAddress && (
                    <button
                      onClick={() => {
                        setSelectedOrder(order);
                        setIsSchedulingPickup(true);
                      }}
                      className="flex-1 bg-black text-white rounded-lg py-2.5 text-sm font-medium hover:bg-gray-900"
                    >
                      Schedule Pickup
                    </button>
                  )}
                  <button 
                    onClick={() => handleViewDetails(order.id)}
                    className="flex-1 bg-gray-100 text-gray-900 rounded-lg py-2.5 text-sm font-medium hover:bg-gray-200"
                  >
                    View Details
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>

      {/* Pickup Scheduling Modal */}
      <AnimatePresence>
        {isSchedulingPickup && selectedOrder && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
              className="bg-white rounded-xl w-full max-w-lg mx-4"
            >
              <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                <h3 className="text-lg font-semibold">Schedule Pickup</h3>
                <button
                  onClick={() => {
                    setIsSchedulingPickup(false);
                    setSelectedOrder(null);
                    setPickupDetails({ pickupAddress: '', pickupDate: '' });
                  }}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="p-6 space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Pickup Address
                  </label>
                  <textarea
                    value={pickupDetails.pickupAddress}
                    onChange={(e) => setPickupDetails({
                      ...pickupDetails,
                      pickupAddress: e.target.value
                    })}
                    className="w-full rounded-lg border-gray-300 focus:ring-black focus:border-black"
                    rows={3}
                    placeholder="Enter pickup address..."
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Pickup Date
                  </label>
                  <input
                    type="date"
                    value={pickupDetails.pickupDate}
                    onChange={(e) => setPickupDetails({
                      ...pickupDetails,
                      pickupDate: e.target.value
                    })}
                    className="w-full rounded-lg border-gray-300 focus:ring-black focus:border-black"
                    min={new Date().toISOString().split('T')[0]}
                  />
                </div>

                <div className="flex space-x-3 pt-4">
                  <button
                    onClick={() => {
                      setIsSchedulingPickup(false);
                      setSelectedOrder(null);
                      setPickupDetails({ pickupAddress: '', pickupDate: '' });
                    }}
                    className="flex-1 bg-gray-100 text-gray-900 rounded-lg py-2.5 text-sm font-medium hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSchedulePickup}
                    className="flex-1 bg-black text-white rounded-lg py-2.5 text-sm font-medium hover:bg-gray-900"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Order Details Modal */}
      <AnimatePresence>
        {isViewingDetails && selectedOrder && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
              className="bg-white rounded-xl w-full max-w-lg mx-4 max-h-[90vh] overflow-y-auto"
            >
              <div className="sticky top-0 bg-white px-6 py-4 border-b border-gray-200 flex items-center justify-between">
                <h3 className="text-lg font-semibold">Order Details</h3>
                <button
                  onClick={() => {
                    setIsViewingDetails(false);
                    setSelectedOrder(null);
                  }}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="p-6 space-y-6">
                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-3">Order Information</h4>
                  <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-600">Order ID</span>
                      <span className="font-medium">{selectedOrder?.id}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Order Date</span>
                      <span className="font-medium">
                        {new Date(selectedOrder?.createdAt?._seconds).toLocaleString()}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Status</span>
                      <span className="font-medium">{selectedOrder?.status}</span>
                    </div>
                  </div>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-3">Customer Information</h4>
                  <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                    <div className="flex justify-between">
                      <span className="text-gray-600">Name</span>
                      <span className="font-medium">{selectedOrder.shippingAddress.customerName}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Contact</span>
                      <span className="font-medium">{selectedOrder.shippingAddress.phoneNumber}</span>
                    </div>
                  </div>
                </div>
                  <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-3">Items</h4>
                  <div className="space-y-3">
                    {selectedOrder.items?.map((item, index) => (
                      <div key={index} className="bg-gray-50 rounded-lg p-4">
                        <div className="flex items-start justify-between">
                          <div className="flex-1">
                            <h5 className="font-medium text-gray-900">{item.name}</h5>
                            <div className="mt-1 text-sm text-gray-500">
                              <p>Quantity: {item.quantity}</p>
                              <p>Price per unit: ${item.price?.toFixed(2)}</p>
                            </div>
                          </div>
                          <div className="text-right">
                            <p className="font-medium text-gray-900">
                              ${(item.quantity * item.price)?.toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {selectedOrder.pickupAddress && (
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 mb-3">Pickup Details</h4>
                    <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                      <div>
                        <span className="text-gray-600">Address</span>
                        <p className="mt-1 font-medium">{selectedOrder.pickupAddress}</p>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-600">Scheduled Date</span>
                        <span className="font-medium">
                          {(new Date(selectedOrder.pickupDate._seconds).toLocaleDateString())}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  <h4 className="text-sm font-medium text-gray-500 mb-3">Payment Details</h4>
                  <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                    <div className="flex justify-between items-center">
                      <span className="text-gray-600">Subtotal</span>
                      <span className="font-medium">
                        ${selectedOrder.items
                          ?.filter(item => item.sellerId === selectedOrder.sellerId)
                          .reduce((acc, item) => acc + (item.quantity * item.price), 0)
                          .toFixed(2)}
                      </span>
                    </div>
                    {selectedOrder.tax && (
                      <div className="flex justify-between items-center">
                        <span className="text-gray-600">Tax</span>
                        <span className="font-medium">${selectedOrder.tax.toFixed(2)}</span>
                      </div>
                    )}
                    {selectedOrder.shippingFee && (
                      <div className="flex justify-between items-center">
                        <span className="text-gray-600">Shipping Fee</span>
                        <span className="font-medium">${selectedOrder.shippingFee.toFixed(2)}</span>
                      </div>
                    )}
                    <div className="pt-2 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="font-medium text-gray-900">Total Amount</span>
                        <span className="text-lg font-semibold text-gray-900">
                          ${selectedOrder.totalAmount
?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-4">
                  {!selectedOrder.pickupAddress && (
                    <button
                      onClick={() => {
                        setIsViewingDetails(false);
                        setIsSchedulingPickup(true);
                      }}
                      className="w-full bg-black text-white rounded-lg py-3 text-sm font-medium hover:bg-gray-900"
                    >
                      Schedule Pickup
                    </button>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default OrderManagement;