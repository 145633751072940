import React from 'react'
import search from '../Search.png'
import StatusLive from './StatusLive'
import Scrollpage from './Scrollpage'
import { useEffect, useState } from 'react'
import Navbar from './Navbar'
import { motion, AnimatePresence } from 'framer-motion'
import { X, Bookmark, ChevronLeft, ChevronRight } from 'lucide-react'
import { Link } from 'react-router-dom'

const Discover = () => {
  const [productfetch, setProductimage] = useState([])
  const [isSearchbaractive, setIssearchbaractive] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('#Trending')
  const [currentProductIndex, setCurrentProductIndex] = useState(0)
  const [Scrolldata, setScrolldata] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const backendURL = process.env.REACT_APP_BACKEND_URL

  const fetchproductimage = async () => {
    try {
      const url = `${backendURL}/userdetail/Product_details/all`
      const response = await fetch(url)
      if (!response.ok) throw new Error('Response status')
      const imagedatas = await response.json()
      setProductimage(imagedatas.productdetails)
    } catch (error) {
      console.log('error occurred:', error)
    }
  }

  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([])
      return
    }
         
    try {
      // const username=${query};
      const url = `${backendURL}/userdetail/user/serach/for/docsover/page/${query}`
      const response = await fetch(url)
      if (!response.ok) throw new Error(`Response status: ${response.status}`)
      const data = await response.json()
      setSearchResults(data.users || [])
    } catch (error) {
      console.error('Error searching users:', error)
      setSearchResults([])
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery)
      }
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [searchQuery])

  useEffect(() => {
    const fetchscrollimage = async () => {
      try {
        const url = `${backendURL}/userdetail/scrollsdata/all`
        const response = await fetch(url)
        const data = await response.json()

        if (data?.scrolls?.length) {
          setScrolldata((prevImages) => [...prevImages, ...data.scrolls])
        } else {
          console.error('No product details found in response')
        }
      } catch (error) {
        console.error('Error fetching products:', error)
      }
    }

    fetchscrollimage()
    fetchproductimage()
  }, [backendURL])

  const handleSwipe = (direction) => {
    if (direction === 'right') {
      console.log('Liked product:', productfetch[currentProductIndex])
    }
    setCurrentProductIndex((prev) => prev + 1)
  }

  return (
    <div className="discover w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-screen mx-auto flex flex-col bg-black overflow-hidden">
      {/* Search Bar - Fixed */}
      <div className="sticky top-0 z-50 bg-black/95 backdrop-blur-lg pt-2 pb-3">
        <div className="flex items-center mx-4">
          <div className="flex-1 flex items-center bg-[#1C1C1E] rounded-xl px-4 py-3.5">
            <img src={search} alt="search" className="h-4 w-4 mr-3 opacity-50" />
            <input
              type="text"
              placeholder="Search users..."
              className="bg-transparent w-full focus:outline-none text-[#8E8E93] text-[15px] font-medium placeholder:text-[#8E8E93]"
              onClick={() => setIssearchbaractive(true)}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>

      {isSearchbaractive ? (
        <div className="flex-1 px-4 py-6 overflow-y-auto">
          {searchQuery ? (
            <div className="space-y-4">
              <p className="text-white text-sm font-medium">Search Results</p>
              {searchResults.length > 0 ? (
                searchResults.map((user) => (
                  <Link to={`/userdetail/${user.username}`} key={user._id}>
                    <div className="flex items-center space-x-4 p-3 rounded-xl bg-[#1C1C1E] hover:bg-[#2C2C2E] transition-colors">
                      <img
                        src={user.profileImage || '/default-avatar.png'}
                        alt={user.username}
                        className="w-12 h-12 rounded-full object-cover"
                      />
                      <div>
                        <p className="text-white font-medium">{user.username}</p>
                        <p className="text-[#8E8E93] text-sm">{user.name}</p>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="text-[#8E8E93] text-sm">No users found</p>
              )}
            </div>
          ) : (
            <p className="text-[#8E8E93] text-sm">Recent Searches</p>
          )}
        </div>
      ) : (
        <div className="flex-1 overflow-y-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
          {/* Categories */}
          <div className="px-4 py-2 bg-gradient-to-b from-black/80 to-transparent">
            <div className="flex gap-1.5 overflow-x-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
              {['#Trending', '#Hot Deals', "What's New", '#Fashion', '#Tech'].map((category) => (
                <button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-3 py-1.5 rounded-lg text-[13px] font-medium whitespace-nowrap transition-all duration-200 ${
                    selectedCategory === category
                      ? 'bg-white text-black'
                      : 'bg-[#1C1C1E] text-white hover:bg-[#2C2C2E]'
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>

          {/* Image Grid Layout */}
          <div className="p-4 space-y-2">
            {/* First Pattern */}
            <div className="grid grid-cols-3 gap-2 mb-2">
              <div className="space-y-2">
                <Link to={`/ProductPage/${productfetch[0]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden">
                    <img src={productfetch[0]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
                <Link to={`/ProductPage/${productfetch[1]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden shadow-lg">
                    <img src={productfetch[1]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
              </div>
              <div className="space-y-2">
                <Link to={`/ProductPage/${productfetch[2]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden shadow-lg">
                    <img src={productfetch[2]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
                <Link to={`/ProductPage/${productfetch[3]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden shadow-lg">
                    <img src={productfetch[3]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
              </div>
              <Link to={`/ProductPage/${productfetch[4]?.id}`}>
                <div className="row-span-2 rounded-xl overflow-hidden shadow-lg">
                  <img src={productfetch[4]?.image} alt="" className="w-full h-full object-cover" />
                </div>
              </Link>
            </div>

            {/* Second Pattern - Reversed */}
            <div className="grid grid-cols-3 gap-2">
              <Link to={`/ProductPage/${productfetch[5]?.id}`}>
                <div className="row-span-2 rounded-xl overflow-hidden">
                  <img src={productfetch[5]?.image} alt="" className="w-full h-full object-cover" />
                </div>
              </Link>
              <div className="space-y-2">
                <Link to={`/ProductPage/${productfetch[6]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden">
                    <img src={productfetch[6]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
                <Link to={`/ProductPage/${productfetch[7]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden">
                    <img src={productfetch[7]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
              </div>
              <div className="space-y-2">
                <Link to={`/ProductPage/${productfetch[8]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden">
                    <img src={productfetch[8]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
                <Link to={`/ProductPage/${productfetch[9]?.id}`}>
                  <div className="aspect-square rounded-xl overflow-hidden">
                    <img src={productfetch[9]?.image} alt="" className="w-full h-full object-cover" />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* Swipeable Products */}
          <div className="px-4 mb-6">
            <h2 className="text-white text-[22px] font-semibold mb-4">Discover Products</h2>
            <div className="relative h-[450px] rounded-2xl overflow-hidden">
              <AnimatePresence>
                <motion.div
                  key={currentProductIndex}
                  className="absolute inset-0 bg-[#1C1C1E]"
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  drag="x"
                  dragConstraints={{ left: 0, right: 0 }}
                  dragElastic={0.9}
                  onDragEnd={(e, { offset, velocity }) => {
                    const swipe = offset.x > 100 ? 'right' : offset.x < -100 ? 'left' : 'none'
                    if (swipe !== 'none') handleSwipe(swipe)
                  }}
                  whileDrag={{
                    rotate: offset => offset.x * 0.03
                  }}
                >
                  <Link to={`/ProductPage/${productfetch[currentProductIndex]?.id}`}>
                    <img
                      src={productfetch[currentProductIndex]?.image}
                      alt=""
                      className="w-full h-[65%] object-cover"
                    />
                  </Link>
                  <div className="absolute inset-x-0 bottom-0 p-6 bg-gradient-to-t from-black via-black/80 to-transparent">
                    <h3 className="text-white text-2xl font-semibold mb-2">
                      {productfetch[currentProductIndex]?.name}
                    </h3>
                    <p className="text-white text-xl font-semibold mb-4">
                      ${productfetch[currentProductIndex]?.price}
                    </p>
                    <div className="flex justify-center gap-6">
                      <motion.button
                        className="w-14 h-14 rounded-full bg-red-500 flex items-center justify-center"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleSwipe('left')}
                      >
                        <X className="text-white w-8 h-8" />
                      </motion.button>
                      <motion.button
                        className="w-14 h-14 rounded-full bg-blue-500 flex items-center justify-center"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleSwipe('right')}
                      >
                        <Bookmark className="text-white w-8 h-8" />
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
          </div>

          {/* Top Creators */}
          {/* <div className="px-4 mb-6">
            <h2 className="text-white text-[22px] font-semibold mb-4">Top Creators</h2>
            <div className="grid grid-cols-4 gap-3">
              {[...Array(4)].map((_, index) => (
                <StatusLive key={index} />
              ))}
            </div>
          </div> */}

          {/* Top Scrolls */}
          <div className="mb-8">
            <h2 className="text-white text-[22px] font-semibold px-4 mb-3">Top Scrolls</h2>
            <div className="flex gap-1.5 px-4 overflow-x-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
              {Scrolldata.slice(0, 8).map((product) => (
                <div
                  key={product.id}
                  className="min-w-[150px] aspect-[3/5] rounded-2xl overflow-hidden transition-all duration-200 hover:scale-[0.98] relative group"
                >
                  <Scrollpage imageUrl={product.thumbnailUrl} productid={product.id} isDiscover={true} />
                </div>
              ))}
            </div>
          </div>



          {/* Trending Products - Enhanced */}
          <div className="px-4 mb-8">
            <h2 className="text-white text-[22px] font-semibold mb-4">Trending Products</h2>
            <div className="grid grid-cols-2 gap-3">
              {productfetch.slice(0, 5).map((product) => (
                <div
                  key={product.id}
                  className="group relative rounded-2xl bg-[#1C1C1E] overflow-hidden"
                >
                  <div className="aspect-[4/5]">
                  <Link to={`/ProductPage/${product?.id}`}>
                    <img 
                      src={product.image} 
                      alt="" 
                      className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105" 
                    />
                    </Link>
                  </div>
                  <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black via-black/80 to-transparent p-3">
                    <h3 className="text-white text-sm font-semibold mb-1 truncate">
                      {product.name}
                    </h3>
                    <div className="flex justify-between items-center">
                      <p className="text-gray-300 text-xs line-clamp-1">
                        {product.description}
                      </p>
                      <p className="text-white text-sm font-semibold">
                        ${product.price}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Navbar />
    </div>
  )
}

export default Discover