import React, { useEffect,useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
// import { useEffect } from 'react';


const LiveProductSection = () => {
    const [imagedata,setImagedata]=useState([]);
const [page, setPage] = useState(1);
 const [hasMore, setHasMore] = useState(true);
 const [totalProducts, setTotalProducts] = useState(0);
 const backendURL = process.env.REACT_APP_BACKEND_URL;
//data fetching from dummy api 
 const {username}=useParams();
    const fetchproductimage = async () => {
        try {
            const serllerId=username;
            console.log(serllerId)
            const url = `${backendURL}/userdetail/product_details/specificuser/${serllerId}`;
            const response = await fetch(url);
            if(!response.ok) {
                throw new Error('Response status');
            }
            const imagedatas = await response.json();
            console.log(imagedatas.productdetails);
            const arraofdata = imagedatas.productdetails;
            setImagedata(prevImages => {
                const uniqueImages = [...prevImages, ...arraofdata].filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                );
                return uniqueImages;
            });
            
            if (page === 1) {
                setTotalProducts(imagedatas.total);
            }
            setPage(prevPage => prevPage + 1);
        } catch(error) {
            console.log("error occured:", error);
            setHasMore(false);
        }
    }

    useEffect(() => {
        fetchproductimage();
    }, []);
  return (
    <div 
            id="scrollableDiv"
            className="h-[300px] overflow-y-scroll [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
          >
            <InfiniteScroll
              dataLength={imagedata.length}
              next={fetchproductimage}
              hasMore={hasMore}
              loader={<h4 className="text-white text-center my-4">Loading more...</h4>}
              endMessage={
                <p className="text-white text-center my-4">
                  No more images to load
                </p>
              }
              scrollableTarget="scrollableDiv"
            >
              <div className="grid grid-cols-2 gap-1 bg-black pb-1">
                {imagedata.map((product, index) => (
                  <div 
                    key={product.id} 
                    className="aspect-square bg-gray-800 hover:opacity-75 transition"
                  >
                    <img 
                      src={product.images[0]} 
                      alt={product.title} 
                      className="w-full h-full object-cover"
                    />
                    <div className='w-[100%] overflow-hidden text-white' >{product.name}</div>
                    <div className='text-white'>Price:-${product.price}</div>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
  )
}

export default LiveProductSection
