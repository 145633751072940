import React, { useState, useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { CreditCard, Wallet, CheckCircle } from 'lucide-react';

function ReviewAndPay() {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const emailid = auth.currentUser?.email;
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  // State management
  const [items, setItems] = useState([]);
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  // Payment methods
  const paymentMethods = [
    {
      id: 'cod',
      name: 'Cash on Delivery',
      description: 'Pay when your order arrives',
      icon: Wallet,
      surcharge: 0
    },
    {
      id: 'online',
      name: 'Online Payment',
      description: 'Cards, UPI, Net Banking',
      icon: CreditCard,
      surcharge: 0
    }
  ];

  // Fetch cart items and address on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch cart items
        const cartRes = await fetch(`${backendURL}/userdetail/get/product/of/user/cart?email=${emailid}`);
        const cartData = await cartRes.json();
        setItems(cartData.items || []);

        // Fetch addresses
        const addressRes = await fetch(`${backendURL}/userdetail/get/user/of/address?email=${emailid}`);
        const addressData = await addressRes.json();
        setAddress(addressData.addresses || []);
        if (addressData.addresses?.length > 0) {
          setSelectedAddress(addressData.addresses[0]);
        }
        
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [emailid, backendURL]);

  // Shipping options
  const shippingOptions = [
    {
      id: 'standard',
      name: 'Standard Delivery',
      cost: 3.00,
      time: '3-5 business days',
      description: 'Standard shipping with tracking'
    },
    {
      id: 'express',
      name: 'Express Delivery',
      cost: 8.00,
      time: '1-2 business days',
      description: 'Fast delivery with priority handling'
    }
  ];

  const [selectedShipping, setSelectedShipping] = useState(shippingOptions[0]);

  // Calculate totals
  const subtotal = items.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const total = subtotal + selectedShipping.cost + (selectedPaymentMethod?.surcharge || 0);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }

  // Update the handlePayment function in your ReviewAndPay component

const handlePayment = async () => {
  if (!selectedAddress) {
    setError('Please select a delivery address');
    return;
  }

  if (!selectedPaymentMethod) {
    setError('Please select a payment method');
    return;
  }

  try {
    // Prepare order data
    const orderData = {
      items: items.map(item => ({
        productId: item.id,
        quantity: item.quantity,
        name: item.name,
        size: item.size
      })),
      shippingAddress: selectedAddress,
      shippingMethod: selectedShipping,
      paymentMethod: selectedPaymentMethod.id,
      totalAmount: total
    };

    if (selectedPaymentMethod.id === 'cod') {
      // Handle COD order placement
      const response = await fetch(`${backendURL}/userdetail/create/order/for/user?email=${emailid}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...orderData,
          paymentStatus: 'Pending'
        })
      });

      const data = await response.json();
      if (response.ok) {
        // Clear cart and navigate to success page
        await fetch(`${backendURL}/userdetail/clear/cart/order/for/user?email=${emailid}`, {
          method: 'POST'
        });
        
        navigate('/order-success', { 
          state: { 
            orderId: data.orderId,
            paymentMethod: 'COD',
            totalAmount: total
          }
        });
      } else {
        setError(data.error || 'Failed to place order');
      }
    } else {
      // Handle online payment
      const paymentResponse = await fetch(`${backendURL}/payments/initiate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: total,
          email: emailid,
          orderData // Pass order data to create order after payment
        })
      });

      const paymentData = await paymentResponse.json();
      if (paymentResponse.ok) {
        // Store order data in session storage for after payment
        sessionStorage.setItem('pendingOrderData', JSON.stringify(orderData));
        
        // Navigate to payment gateway page
        navigate('/payment-gateway', { 
          state: { 
            clientSecret: paymentData.clientSecret,
            amount: total,
            orderData
          }
        });
      } else {
        setError(paymentData.error || 'Failed to initiate payment');
      }
    }
  } catch (err) {
    console.error('Payment processing error:', err);
    setError('Failed to process payment. Please try again.');
  }
};
  return (
    <div className="min-h-screen bg-[#f5f5f7]">
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-xl z-50 border-b border-gray-200">
        <div className="px-4 h-14 flex items-center max-w-[393px] mx-auto">
          <button 
            className="w-10 h-10 -ml-2 flex items-center justify-center"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack className="w-6 h-6 text-[#0066CC]" />
          </button>
          <h1 className="text-lg font-medium ml-2">Review & Pay</h1>
        </div>
      </header>

      <main className="pt-14 pb-32 max-w-[393px] mx-auto">
        {error && (
          <div className="px-4 py-2 bg-red-100 text-red-600 text-sm mt-2">
            {error}
          </div>
        )}

        {/* Delivery Address */}
        <div className="px-4 py-4">
          <h2 className="text-base text-gray-500">Deliver to:</h2>
          {address.map((addr) => (
            <div 
              key={addr.id}
              className={`mt-2 p-3 rounded-xl border-2 transition-colors cursor-pointer
                ${selectedAddress === addr ? 'border-[#0066CC] bg-[#0066CC]/5' : 'border-gray-200'}`}
              onClick={() => setSelectedAddress(addr)}
            >
              <p className="text-base font-medium">{addr.name}</p>
              <p className="text-sm text-gray-600 mt-1">
                {addr.addressLine1}
                {addr.addressLine2 && <>, {addr.addressLine2}</>}
                <br />
                {addr.city}, {addr.state}, {addr.pincode}
              </p>
            </div>
          ))}
          <button
            onClick={() => navigate('/AddAddress')}
            className="mt-4 w-full py-2.5 rounded-xl border-2 border-[#FE2C55] text-[#FE2C55] font-medium"
          >
            + Add New Address
          </button>
        </div>

        {/* Order Items */}
        <div className="bg-white mb-4">
          <div className="px-4 py-3 border-b border-gray-100">
            <h2 className="text-base font-medium">Order summary</h2>
          </div>
          <div className="divide-y divide-gray-100">
            {items.map((item) => (
              <div key={item.id} className="p-4">
                <div className="flex gap-3">
                  <div className="w-16 h-16 rounded-lg bg-gray-50 overflow-hidden flex-shrink-0">
                    <img 
                      src={item.image} 
                      alt={item.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-xs text-gray-500">{item.sellerid}</p>
                    <h3 className="text-base font-medium truncate">{item.name}</h3>
                    <p className="text-sm text-gray-600 mt-1">
                      Size: {item.size} • Qty: {item.quantity}
                    </p>
                    <p className="text-base font-medium mt-2">
                      ${(item.price * item.quantity).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Payment Method Selection */}
        <div className="bg-white mb-4">
          <div className="px-4 py-3 border-b border-gray-100">
            <h2 className="text-base font-medium">Payment Method</h2>
          </div>
          <div className="p-4 space-y-3">
            {paymentMethods.map(method => (
              <div
                key={method.id}
                className={`p-3 rounded-xl border-2 transition-colors cursor-pointer
                  ${selectedPaymentMethod?.id === method.id 
                    ? 'border-[#0066CC] bg-[#0066CC]/5' 
                    : 'border-gray-200'}`}
                onClick={() => setSelectedPaymentMethod(method)}
              >
                <div className="flex items-start gap-3">
                  <method.icon className="w-6 h-6 text-gray-600" />
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h3 className="text-base font-medium">{method.name}</h3>
                      {selectedPaymentMethod?.id === method.id && (
                        <CheckCircle className="w-5 h-5 text-[#0066CC]" />
                      )}
                    </div>
                    <p className="text-sm text-gray-600 mt-1">{method.description}</p>
                    {method.surcharge > 0 && (
                      <p className="text-sm text-[#FE2C55] mt-1">
                        Surcharge: ${method.surcharge.toFixed(2)}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Shipping Options */}
        <div className="bg-white mb-4">
          <div className="px-4 py-3 border-b border-gray-100">
            <h2 className="text-base font-medium">Shipping Method</h2>
          </div>
          <div className="p-4 space-y-3">
            {shippingOptions.map(option => (
              <div
                key={option.id}
                className={`p-3 rounded-xl border-2 transition-colors cursor-pointer
                  ${selectedShipping.id === option.id ? 'border-[#0066CC] bg-[#0066CC]/5' : 'border-gray-200'}`}
                onClick={() => setSelectedShipping(option)}
              >
                <div className="flex justify-between items-start">
                  <div>
                    <div className="flex items-center gap-2">
                      <h3 className="text-base font-medium">{option.name}</h3>
                      <span className="text-sm text-[#34C759]">{option.time}</span>
                    </div>
                    <p className="text-sm text-gray-600 mt-1">{option.description}</p>
                  </div>
                  <span className="text-base font-medium">${option.cost.toFixed(2)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Payment Summary */}
        <div className="bg-white">
          <div className="px-4 py-4 space-y-2">
            <div className="flex justify-between text-base">
              <span className="text-gray-600">Subtotal</span>
              <span>${subtotal.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-base">
              <span className="text-gray-600">Shipping</span>
              <span>${selectedShipping.cost.toFixed(2)}</span>
            </div>
            {selectedPaymentMethod?.surcharge > 0 && (
              <div className="flex justify-between text-base">
                <span className="text-gray-600">Payment Surcharge</span>
                <span>${selectedPaymentMethod.surcharge.toFixed(2)}</span>
              </div>
            )}
            <div className="flex justify-between text-base font-medium pt-2 border-t border-gray-200">
              <span>Total</span>
              <span>${total.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </main>

      {/* Bottom Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="max-w-[393px] mx-auto px-4 py-3">
          <button 
            onClick={handlePayment}
            className="w-full py-3 rounded-xl bg-[#34C759] text-white font-medium text-base"
            disabled={!selectedPaymentMethod}
          >
            {selectedPaymentMethod?.id === 'cod' 
              ? `Place Order - ${total.toFixed(2)}`
              : `Pay $${total.toFixed(2)}`}
          </button>
          <p className="text-[10px] text-gray-500 text-center mt-1">
            All transactions are secured and encrypted
          </p>
        </div>
      </div>
    </div>
  );
}

export default ReviewAndPay;