import React, { useState, useCallback, useEffect } from 'react';
import EditLookPage from './EditLookPage';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';

// Add SVG icons object for options
const optionIcons = {
  'Tag Brand': (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A2 2 0 013 12V7a4 4 0 014-4z" />
    </svg>
  ),
  'Tag Product': (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
    </svg>
  ),
  'Audience': (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
    </svg>
  ),
  'Keywords': (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
    </svg>
  ),
  'Gifts': (
    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
    </svg>
  )
};


// Add this utility function at the top of the file
const processImage = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calculate dimensions for 9:16 aspect ratio
      let width = img.width;
      let height = img.height;
      let x = 0;
      let y = 0;

      if (width / height > 9 / 16) {
        // Image is too wide
        width = height * (9 / 16);
        x = (img.width - width) / 2;
      } else {
        // Image is too tall
        height = width * (16 / 9);
        y = (img.height - height) / 2;
      }

      // Set canvas to desired 9:16 dimensions
      canvas.width = 1080; // Standard width
      canvas.height = 1920; // 16:9 ratio height

      // Draw and crop image
      ctx.drawImage(
        img,
        x, y, width, height,
        0, 0, canvas.width, canvas.height
      );

      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob));
      }, 'image/jpeg', 0.95);
    };
    img.src = URL.createObjectURL(file);
  });
};

// First, add the products constant at the top with the brands constant


function NewScrollPage({ onClose }) {
  const [showPopup, setShowPopup] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [caption, setCaption] = useState('');
  const fileInputRef = React.useRef(null);
  const [showTagBrand, setShowTagBrand] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showTagProduct, setShowTagProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const [productSearchTerm, setProductSearchTerm] = useState('');
  const [showEditLook, setShowEditLook] = useState(false);
  const [showAudience, setShowAudience] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState('everyone'); // 'everyone' or 'followers'
  const [showKeywords, setShowKeywords] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [selectedBrandForProducts, setSelectedBrandForProducts] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [brands,setBrands]=useState([]);
  const [products,setProducts]=useState([]);

  //show all the seller/brabds data to choose by the user 
  const fetchsellerdata = async () => {
    try{
      const response = await fetch(`${backendURL}/Sellerdetail`);
      if (!response.ok) throw new Error("Failed to fetch SellerDetail");
      const maindata = await response.json();
      const data=maindata;
      // console.log(maindata.id);
  
      setBrands(data.sellerdetails);
    }catch(error){
      console.error("Error fetching videos:", error);
    }
  };
  const firebase=useFirebase();
  //show all the produts data to choose by the user
  const fetchProductDataOfBrand = async (selectedBrand = null) => {
    try {
        // Initialize empty products array
        let products = [];
        
        if (selectedBrand) {
            // Case 1: Fetch products for a specific brand
            console.log("Fetching products for brand:", selectedBrand.id);
            const sellerDoc = await getDoc(doc(firebase.firestore, "UsersDetail", selectedBrand.id));
            
            if (!sellerDoc.exists()) {
                console.log("Seller document not found");
                setProducts([]);
                return [];
            }
            
            const sellerData = sellerDoc.data();
            
            if (!sellerData.products || sellerData.products.length === 0) {
                console.log("No products found for seller");
                setProducts([]);
                return [];
            }
            
            // Fetch all product documents in parallel
            const productRefs = sellerData.products.map(productId => 
                getDoc(doc(firebase.firestore, "Products", productId))
            );
            
            const productDocs = await Promise.all(productRefs);
            
            products = productDocs
                .filter(doc => doc.exists()) // Filter out non-existent documents
                .map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
        } else {
            // Case 2: Fetch products for all selected brands
            console.log("Fetching products for all selected brands:", selectedBrands);
            
            // Make sure we have selected brands
            if (!selectedBrands || selectedBrands.length === 0) {
                console.log("No brands selected");
                setProducts([]);
                return [];
            }
            
            // Fetch products for each brand in parallel
            const brandProductPromises = selectedBrands.map(async (brand) => {
                const sellerDoc = await getDoc(doc(firebase.firestore, "UsersDetail", brand.id));
                
                if (!sellerDoc.exists()) {
                    console.log(`Seller document not found for brand ${brand.id}`);
                    return [];
                }
                
                const sellerData = sellerDoc.data();
                
                if (!sellerData.products || sellerData.products.length === 0) {
                    console.log(`No products found for seller ${brand.id}`);
                    return [];
                }
                
                const productRefs = sellerData.products.map(productId => 
                    getDoc(doc(firebase.firestore, "Products", productId))
                );
                
                const productDocs = await Promise.all(productRefs);
                
                return productDocs
                    .filter(doc => doc.exists())
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));
            });
            
            // Wait for all brand product fetches to complete
            const productsArrays = await Promise.all(brandProductPromises);
            products = productsArrays.flat();
        }
        
        console.log("Final products array:", products);
        setProducts(products);
        return products;
        
    } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
        return [];
    }
};
  const handleEditCoverClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFileUpload = useCallback(async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        // Revoke previous objectURL if exists
        if (coverImage) {
          URL.revokeObjectURL(coverImage);
        }
        const processedImageUrl = await processImage(file);
        setCoverImage(processedImageUrl);
        setShowPopup(false);
      } catch (error) {
        console.error('Error processing image:', error);
      } finally {
        // Reset the file input value
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      }
    }
  }, [coverImage]);

  const handleRemoveCover = useCallback(() => {
    if (coverImage) {
      URL.revokeObjectURL(coverImage);
    }
    setCoverImage(null);
    setShowPopup(false);
    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [coverImage]);

  const triggerFileUpload = () => {
    fileInputRef.current?.click();
  };

  const handleTagBrandClick = useCallback(() => {
    fetchsellerdata();
    setShowTagBrand(true);
  }, []);

  const handleBrandSelect = useCallback((brand) => {
    setSelectedBrands(prev => {
      const exists = prev.find(b => b.name === brand.name);
      if (exists) {
        return prev.filter(b => b.name !== brand.name);
      }
      return [...prev, brand];
    });
  }, []);

  const handleProductSelect = useCallback((product) => {
    setSelectedProducts(prev => {
      const exists = prev.find(p => p.name === product.name);
      if (exists) {
        return prev.filter(p => p.name !== product.name);
      }
      return [...prev, product];
    });
  }, []);

  const handleTagProductClick = useCallback(async () => {
    try {
        console.log("Fetching products...");
        await fetchProductDataOfBrand(selectedBrandForProducts);
        setShowTagProduct(true);
    } catch (error) {
        console.error("Error in handleTagProductClick:", error);
    }
}, [selectedBrandForProducts]);
useEffect(() => {
  if (showTagProduct) {
      fetchProductDataOfBrand(selectedBrandForProducts);
  }
}, [selectedBrandForProducts, showTagProduct]);

const filteredBrands = (brands || []).filter((brand) =>
  (brand?.name?.toLowerCase().includes(brandSearchTerm.toLowerCase()) ||
   brand?.handle?.toLowerCase().includes(brandSearchTerm.toLowerCase())) ?? false
);

// Filtered products with safety checks
const filteredProducts = (products || []).filter((product) => {
    const matchesSearch = product?.name?.toLowerCase().includes(productSearchTerm.toLowerCase()) ?? false;
    return matchesSearch;
});
  const handleNext = useCallback(() => {
    setShowEditLook(true);
  }, []);

  const handleAudienceClick = useCallback(() => {
    setShowAudience(true);
  }, []);

  const handleKeywordsClick = useCallback(() => {
    setShowKeywords(true);
  }, []);

  const handleAddKeyword = useCallback((e) => {
    if (e.key === 'Enter' && keywordInput.trim()) {
      e.preventDefault();
      if (keywords.length >= 50) {
        // You might want to add a toast notification here
        return;
      }
      setKeywords(prev => [...new Set([...prev, keywordInput.trim()])]);
      setKeywordInput('');
    }
  }, [keywordInput, keywords]);

  const handleRemoveKeyword = useCallback((keywordToRemove) => {
    setKeywords(prev => prev.filter(keyword => keyword !== keywordToRemove));
  }, []);
 const Navigate=useNavigate();
  return (
    <div className="fixed inset-0 bg-black text-white z-50 safe-area-inset">
      <div className="h-full flex flex-col">
        {/* Header - Optimized for mobile */}
        <div className="flex justify-between items-center p-4 relative backdrop-blur-lg bg-black/40 safe-area-inset-top">
          <button 
            onClick={()=>{Navigate('/PostVideoPage')}} 
            className="absolute left-4 hover:bg-white/10 p-2 rounded-full transition-colors touch-manipulation"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
            </svg>
          </button>
          <h2 className="text-xl font-medium mx-auto">New Scroll</h2>
          <button 
            onClick={handleNext}
            className={`absolute right-4 py-2 px-6 rounded-full text-sm font-medium 
            transition-all duration-300 ${
              coverImage && caption.trim().length > 0 
                ? 'bg-[#FE2C55] text-white hover:bg-[#ff1a45] active:bg-[#e01442]' 
                : 'bg-[#2C2C2E] text-white/40'
            }`}
            disabled={!coverImage || caption.trim().length === 0}
          >
            Next
          </button>
        </div>

        {/* Main Content - Mobile optimized scrolling */}
        <div className="flex-1 px-4 py-6 overflow-y-auto space-y-6 overscroll-bounce">
          {/* Cover upload - Optimized for touch */}
          <div className="relative group touch-manipulation max-w-[280px] mx-auto">
            <div 
              onClick={handleEditCoverClick}
              className={`relative rounded-2xl overflow-hidden transition-all duration-300 shadow-lg cursor-pointer 
              active:opacity-80 aspect-[9/16] w-full bg-[#1C1C1E]`}
            >
              {coverImage ? (
                <img 
                  src={coverImage} 
                  alt="Cover" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="h-full w-full flex flex-col items-center justify-center">
                  <div className="w-12 h-12 mb-3 rounded-full bg-[#2C2C2E] flex items-center justify-center">
                    <svg className="w-6 h-6 text-white/60" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <p className="text-white/60 font-medium text-sm">Add Cover Photo</p>
                </div>
              )}
              
              {/* Edit Cover button with stopPropagation to prevent double trigger */}
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEditCoverClick();
                }}
                className="absolute bottom-3 right-3 bg-black/40 backdrop-blur-md text-white py-1.5 px-3 
                rounded-full text-xs font-medium hover:bg-black/60 active:bg-black/70 
                transition-all duration-300"
              >
                Edit Cover
              </button>
            </div>
          </div>

          {/* Caption input - Mobile optimized */}
          <div className="space-y-2">
            <label className="text-sm font-medium text-white/60 px-1">Caption</label>
            <textarea
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              className="w-full p-4 bg-[#1C1C1E] rounded-xl text-white resize-none border-none 
              focus:ring-2 focus:ring-[#FE2C55] transition-all text-base leading-relaxed
              placeholder:text-white/40"
              rows="4"
              placeholder="Write a caption..."
            ></textarea>
          </div>

          {/* Options - Mobile optimized with icons */}
          <div className="space-y-3">
            {Object.entries(optionIcons).map(([option, icon]) => (
              <button 
                key={option}
                onClick={() => {
                  if (option === 'Tag Brand') handleTagBrandClick();
                  if (option === 'Tag Product' && selectedBrands.length > 0) handleTagProductClick();
                  if (option === 'Audience') handleAudienceClick();
                  if (option === 'Keywords') handleKeywordsClick();
                }}
                className={`w-full text-left bg-[#1C1C1E] text-white py-4 px-5 rounded-xl 
                ${option === 'Tag Product' && selectedBrands.length === 0 
                  ? 'opacity-50 cursor-not-allowed' 
                  : 'hover:bg-[#2C2C2E] active:bg-[#3C3C3E]'} 
                transition-all duration-300 
                flex items-center justify-between group touch-manipulation`}
                disabled={option === 'Tag Product' && selectedBrands.length === 0}
              >
                <div className="flex items-center space-x-4">
                  <div className="text-white/60 group-hover:text-white/80 transition-colors">
                    {icon}
                  </div>
                  <div className="flex flex-col">
                    <span className="font-medium">{option}</span>
                    {option === 'Tag Product' && selectedBrands.length === 0 && (
                      <span className="text-sm text-white/60">Select a brand first</span>
                    )}
                    {option === 'Tag Brand' && selectedBrands.length > 0 && (
                      <span className="text-sm text-white/60">
                        {selectedBrands.map(b => b.handle).join(', ')}
                      </span>
                    )}
                    {option === 'Tag Product' && selectedProducts.length > 0 && (
                      <span className="text-sm text-white/60">
                        {selectedProducts.map(p => p.name).join(', ')}
                      </span>
                    )}
                    {option === 'Audience' && (
                      <span className="text-sm text-white/60">
                        {selectedAudience === 'everyone' ? 'Everyone' : 'Followers'}
                      </span>
                    )}
                    {option === 'Keywords' && keywords.length > 0 && (
                      <span className="text-sm text-white/60">
                        {keywords.length} keywords
                      </span>
                    )}
                  </div>
                </div>
                <svg className="w-5 h-5 text-white/40 group-hover:text-white/60 transition-colors" 
                  fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </button>
            ))}
          </div>

          {/* Skip Button - Now inside main content */}
          <div className="pt-4 pb-8 flex justify-center">
            <button 
              className="px-12 py-3 rounded-xl border-2 border-[#FE2C55] text-[#FE2C55] 
              font-medium hover:bg-[#FE2C55] hover:text-white active:bg-[#e01442] 
              transition-all duration-300 touch-manipulation text-sm"
            >
              Skip
            </button>
          </div>
        </div>

        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*"
          onChange={handleFileUpload}
          capture="environment"
        />

        {/* Popup - Mobile optimized */}
        {showPopup && (
          <div className="fixed inset-0 bg-black/75 flex items-end justify-center z-50 p-4 safe-area-inset">
            <div className="bg-[#1C1C1E] w-full max-w-md mx-auto mb-4 rounded-2xl overflow-hidden 
              shadow-2xl animate-slide-up">
              <div className="p-6 space-y-4">
                <div className="w-12 h-1 bg-white/20 rounded-full mx-auto mb-4" />
                <h3 className="text-lg font-medium text-center">Edit Cover</h3>
                <button 
                  onClick={triggerFileUpload}
                  className="w-full py-4 bg-[#2C2C2E] text-white rounded-xl 
                  hover:bg-[#3C3C3E] active:bg-[#4C4C4E] transition-all duration-300 
                  font-medium touch-manipulation"
                >
                  Upload New Cover
                </button>
                {coverImage && (
                  <button 
                    onClick={handleRemoveCover}
                    className="w-full py-4 bg-transparent text-[#FE2C55] rounded-xl 
                    hover:bg-[#FE2C55]/10 active:bg-[#FE2C55]/20 transition-all duration-300 
                    font-medium touch-manipulation"
                  >
                    Remove Current Cover
                  </button>
                )}
                <button 
                  onClick={handleClosePopup}
                  className="w-full py-4 bg-transparent text-white/60 rounded-xl 
                  hover:bg-white/10 active:bg-white/20 transition-all duration-300 
                  font-medium touch-manipulation"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Add TagBrand Modal */}
        {showTagBrand && (
          <div className="fixed inset-0 bg-black/75 z-50">
            <div className="absolute inset-0 bg-gradient-to-b from-[#1C1C1E] via-[#2C2C2E] to-[#1C1C1E] 
              rounded-t-2xl transition-transform duration-300 ease-out transform translate-y-0 animate-slide-up">
              <div className="h-full overflow-hidden">
                {/* Header with transparent background */}
                <div className="flex justify-between items-center p-4 border-b border-white/10 bg-black/20 backdrop-blur-lg">
                  <button 
                    onClick={() => setShowTagBrand(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  <h2 className="text-xl font-medium">Tag Brand</h2>
                  <button 
                    onClick={() => setShowTagBrand(false)}
                    disabled={selectedBrands.length === 0}
                    className={`py-1.5 px-4 rounded-lg text-sm font-medium transition-all
                    ${selectedBrands.length > 0 
                      ? 'bg-[#FE2C55] text-white hover:bg-[#ff1a45] active:bg-[#e01442]' 
                      : 'bg-black/20 text-white/40'}`}
                  >
                    Add
                  </button>
                </div>
                
                <div className="h-[calc(100%-60px)] overflow-y-auto">
                  {/* Search bar with glass effect */}
                  <div className="p-4">
                    <div className="relative">
                      <input
                        type="text"
                        placeholder="Search for a Brand"
                        value={brandSearchTerm}
                        onChange={(e) => setBrandSearchTerm(e.target.value)}
                        className="w-full py-3 px-4 pl-11 bg-black/20 backdrop-blur-lg rounded-xl 
                        text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] 
                        border-none transition-all"
                      />
                      <svg className="w-5 h-5 text-white/40 absolute left-4 top-1/2 -translate-y-1/2" 
                        fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>
                    </div>
                  </div>

                  {/* Brand list with glass effect */}
                  <div className="px-4 space-y-2">
                    {filteredBrands.map((brand, index) => (
                      <button
                        key={index}
                        onClick={() => handleBrandSelect(brand)}
                        className={`w-full flex items-center justify-between p-4 
                        rounded-xl transition-colors backdrop-blur-sm ${
                          selectedBrands.find(b => b.name === brand.name)
                            ? 'bg-[#FE2C55]/10 hover:bg-[#FE2C55]/20'
                            : 'bg-black/20 hover:bg-black/30 active:bg-black/40'
                        }`}
                      >
                        <div className="flex items-center space-x-3">
                          <div className="w-12 h-12 rounded-full overflow-hidden bg-[#3C3C3E]">
                            <img 
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Louisvuiton-champselysees-building-paris-carbondale-02-763x1024.jpg/440px-Louisvuiton-champselysees-building-paris-carbondale-02-763x1024.jpg"
                              alt={brand.name}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="text-left">
                            <p className="font-medium">{brand.name}</p>
                            <p className="text-sm text-white/60">{brand.username}</p>
                          </div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <span className="text-[#FE2C55] text-sm font-medium">{brand.followers.length}</span>
                          {selectedBrands.find(b => b.name === brand.name) && (
                            <svg className="w-5 h-5 text-[#FE2C55]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                          )}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Add TagProduct Modal */}
        {showTagProduct && (
          <div className="fixed inset-0 bg-black/75 z-50">
            <div className="absolute inset-0 bg-gradient-to-b from-[#1C1C1E] via-[#2C2C2E] to-[#1C1C1E] 
              rounded-t-2xl transition-transform duration-300 ease-out transform translate-y-0 animate-slide-up">
              <div className="h-full overflow-hidden">
                {/* Header */}
                <div className="flex justify-between items-center p-4 border-b border-white/10 bg-black/20 backdrop-blur-lg">
                  <button 
                    onClick={() => setShowTagProduct(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  <h2 className="text-xl font-medium">Tag Product</h2>
                  <button 
                    onClick={() => setShowTagProduct(false)}
                    disabled={selectedProducts.length === 0}
                    className={`py-1.5 px-4 rounded-lg text-sm font-medium transition-all
                    ${selectedProducts.length > 0 
                      ? 'bg-[#FE2C55] text-white hover:bg-[#ff1a45] active:bg-[#e01442]' 
                      : 'bg-black/20 text-white/40'}`}
                  >
                    Add
                  </button>
                </div>

                {/* Brand Selector */}
                <div className="p-4 border-b border-white/10">
                  <p className="text-sm text-white/60 mb-3">Select Brand</p>
                  <div className="flex space-x-3 overflow-x-auto pb-4 scrollbar-hide">
                    <button
                      onClick={() => setSelectedBrandForProducts(null)}
                      className={`flex-shrink-0 px-4 py-2 rounded-full border 
                      transition-colors ${
                        !selectedBrandForProducts
                          ? 'bg-[#FE2C55] border-[#FE2C55] text-white'
                          : 'border-white/20 hover:border-white/40'
                      }`}
                    >
                      <span className="text-sm font-medium">All Brands</span>
                    </button>
                    {selectedBrands.map((brand) => (
                      <button
                        key={brand.name}
                        onClick={() => setSelectedBrandForProducts(brand)}
                        className={`flex-shrink-0 px-4 py-2 rounded-full border 
                        transition-colors ${
                          selectedBrandForProducts?.name === brand.name
                            ? 'bg-[#FE2C55] border-[#FE2C55] text-white'
                            : 'border-white/20 hover:border-white/40'
                        }`}
                      >
                        <span className="text-sm font-medium">{brand.name}</span>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Search bar */}
                <div className="p-4">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder={`Search ${selectedBrandForProducts?.name || 'all'} products`}
                      value={productSearchTerm}
                      onChange={(e) => setProductSearchTerm(e.target.value)}
                      className="w-full py-3 px-4 pl-11 bg-black/20 backdrop-blur-lg rounded-xl 
                      text-white placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] 
                      border-none transition-all"
                    />
                    <svg className="w-5 h-5 text-white/40 absolute left-4 top-1/2 -translate-y-1/2" 
                      fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </div>
                </div>

                {/* Product list */}
                <div className="px-4 space-y-2">
                  {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                      <button
                        key={index}
                        onClick={() => handleProductSelect(product)}
                        className={`w-full flex items-center justify-between p-4 
                        rounded-xl transition-colors backdrop-blur-sm ${
                          selectedProducts.find(p => p.name === product.name)
                            ? 'bg-[#FE2C55]/10 hover:bg-[#FE2C55]/20'
                            : 'bg-black/20 hover:bg-black/30 active:bg-black/40'
                        }`}
                      >
                        <div className="flex items-center space-x-3">
                          <div className="w-16 h-16 rounded-xl overflow-hidden bg-[#3C3C3E]">
                            <img 
                              src={product.image}
                              alt={product.name}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="text-left">
                            <p className="font-medium">{product.name}</p>
                            <p className="text-sm text-white/60">{product.brand}</p>
                            <p className="text-sm font-medium text-[#FE2C55]">{product.price}</p>
                          </div>
                        </div>
                        {selectedProducts.find(p => p.name === product.name) && (
                          <svg className="w-5 h-5 text-[#FE2C55]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                        )}
                      </button>
                    ))
                  ) : (
                    <div className="text-center py-8 text-white/60">
                      <p>No products found for {selectedBrandForProducts?.name || 'selected brands'}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Audience Selection Modal */}
        {showAudience && (
          <div className="fixed inset-0 bg-black/75 z-50">
            <div className="absolute inset-x-0 bottom-0 bg-gradient-to-b from-[#1C1C1E] to-[#1C1C1E] 
              rounded-t-2xl transition-transform duration-300 ease-out transform translate-y-0 animate-slide-up">
              <div className="p-6 space-y-6">
                {/* Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-xl font-medium mb-1">Who can watch this?</h2>
                    <p className="text-sm text-white/60">Choose who can watch your scroll</p>
                  </div>
                  <button 
                    onClick={() => setShowAudience(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                {/* Options */}
                <div className="space-y-3">
                  {/* Everyone Option */}
                  <button
                    onClick={() => {
                      setSelectedAudience('everyone');
                      setShowAudience(false);
                    }}
                    className={`w-full flex items-center justify-between p-4 rounded-xl transition-colors
                      ${selectedAudience === 'everyone' 
                        ? 'bg-[#FE2C55]/10 border border-[#FE2C55]' 
                        : 'bg-[#2C2C2E] hover:bg-[#3C3C3E] border border-transparent'
                      }`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className={`p-2 rounded-full ${
                        selectedAudience === 'everyone' ? 'bg-[#FE2C55]' : 'bg-[#3C3C3E]'
                      }`}>
                        <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" 
                            d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" 
                            d="M9 10a3 3 0 100-6 3 3 0 000 6zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div className="text-left">
                        <p className="font-medium">Everyone</p>
                        <p className="text-sm text-white/60">Anyone on Scroll can watch this</p>
                      </div>
                    </div>
                    {selectedAudience === 'everyone' && (
                      <svg className="w-5 h-5 text-[#FE2C55]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </button>

                  {/* Followers Option */}
                  <button
                    onClick={() => {
                      setSelectedAudience('followers');
                      setShowAudience(false);
                    }}
                    className={`w-full flex items-center justify-between p-4 rounded-xl transition-colors
                      ${selectedAudience === 'followers' 
                        ? 'bg-[#FE2C55]/10 border border-[#FE2C55]' 
                        : 'bg-[#2C2C2E] hover:bg-[#3C3C3E] border border-transparent'
                      }`}
                  >
                    <div className="flex items-center space-x-4">
                      <div className={`p-2 rounded-full ${
                        selectedAudience === 'followers' ? 'bg-[#FE2C55]' : 'bg-[#3C3C3E]'
                      }`}>
                        <svg className="w-5 h-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" 
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg>
                      </div>
                      <div className="text-left">
                        <p className="font-medium">Followers</p>
                        <p className="text-sm text-white/60">Only your followers can watch this</p>
                      </div>
                    </div>
                    {selectedAudience === 'followers' && (
                      <svg className="w-5 h-5 text-[#FE2C55]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Keywords Modal */}
        {showKeywords && (
          <div className="fixed inset-0 bg-black/75 z-50">
            <div className="absolute inset-x-0 bottom-0 bg-gradient-to-b from-[#1C1C1E] to-[#1C1C1E] 
              rounded-t-2xl transition-transform duration-300 ease-out transform translate-y-0 animate-slide-up">
              <div className="p-6 space-y-6">
                {/* Header */}
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-xl font-medium mb-1">Keywords</h2>
                    <p className="text-sm text-white/60">Add up to 50 keywords</p>
                  </div>
                  <button 
                    onClick={() => setShowKeywords(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                {/* Input */}
                <div className="space-y-4">
                  <div className="relative">
                    <input
                      type="text"
                      value={keywordInput}
                      onChange={(e) => setKeywordInput(e.target.value)}
                      onKeyDown={handleAddKeyword}
                      placeholder="Type keyword and press enter"
                      maxLength={30}
                      className="w-full py-3 px-4 bg-[#2C2C2E] rounded-xl text-white 
                      placeholder:text-white/40 focus:ring-2 focus:ring-[#FE2C55] 
                      border-none transition-all"
                    />
                    <div className="absolute right-4 top-1/2 -translate-y-1/2 text-xs text-white/40">
                      {keywords.length}/50
                    </div>
                  </div>

                  {/* Keywords List */}
                  <div className="flex flex-wrap gap-2">
                    {keywords.map((keyword, index) => (
                      <div
                        key={index}
                        className="group flex items-center space-x-2 bg-[#2C2C2E] 
                        rounded-full pl-3 pr-2 py-1.5"
                      >
                        <span className="text-sm">{keyword}</span>
                        <button
                          onClick={() => handleRemoveKeyword(keyword)}
                          className="w-5 h-5 rounded-full bg-[#3C3C3E] flex items-center 
                          justify-center hover:bg-[#FE2C55] transition-colors"
                        >
                          <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Done Button */}
                <button
                  onClick={() => setShowKeywords(false)}
                  className="w-full py-3 bg-[#FE2C55] text-white rounded-xl font-medium
                  hover:bg-[#ff1a45] active:bg-[#e01442] transition-colors"
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        )}

        {showEditLook && (
          <EditLookPage 
            selectedProducts={selectedProducts}
            coverImage={coverImage}
            onClose={() => setShowEditLook(false)}
          />
        )}
      </div>
    </div>
  );
}

// Add these constants at the top of the file


export default NewScrollPage; 