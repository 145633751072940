import React, { useState, useRef, useCallback } from 'react';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import { Alert, Snackbar, CircularProgress } from '@mui/material';

const MAX_FILE_SIZE_MB = 5;
const VALID_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

function UploadProfilePicture() {
  const [images, setImages] = useState({
    banner: null,
    profile: null,
    bannerFile: null,
    profileFile: null
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const bannerInputRef = useRef(null);
  const profileInputRef = useRef(null);
  const firebase = useFirebase();

  const validateFile = useCallback((file) => {
    if (!file) return;
    
    const maxSize = MAX_FILE_SIZE_MB * 1024 * 1024;
    
    if (!VALID_FILE_TYPES.includes(file.type)) {
      throw new Error(`Invalid file type. Please upload JPEG, PNG or GIF images.`);
    }
    
    if (file.size > maxSize) {
      throw new Error(`File size must be less than ${MAX_FILE_SIZE_MB}MB`);
    }
  }, []);

  const handleImageUpload = useCallback(async (event, type) => {
    try {
      const file = event.target.files[0];
      if (!file) return;
  
      validateFile(file);
  
      const reader = new FileReader();
      
      const imageUrl = await new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
  
      console.log(`Uploading ${type} file:`, file); // Debug log
  
      setImages(prev => ({
        ...prev,
        [type]: imageUrl,
        [`${type}File`]: file  // Store the actual File object
      }));
      
    } catch (error) {
      console.log('Error in handleImageUpload:', error);
      setError(error.message);
    }
  }, [validateFile]);

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') return;
    firebase.setOpenToast(false);
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      setError(null);
      // Check if both files exist
      if (!images.bannerFile || !images.profileFile) {
        setError("Please upload both banner and profile images");
        return;
      }
  
      
     console.log(images.bannerFile,images.profileFile);
      // Pass both files explicitly
      await firebase.uploadbannerprofileimg(
        images.bannerFile,  // Pass the actual File object
        images.profileFile  // Pass the actual File object
      );
  if (firebase.formData.storename) {
        await firebase.handlefirebasestorageforseller();
      } else {
        await firebase.handlefirebasestorageforuser();
      }
      await firebase.handlesignup();
      
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleSkip = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await firebase.handlefirebasestorage();
      await firebase.handlesignup();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Snackbar
        open={firebase.openToast}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseToast}
          severity={firebase.toastMessage?.includes('successfully') ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {firebase.toastMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: '100%' }}
        >
          {error}
        </Alert>
      </Snackbar>

      <div className="flex items-center justify-center min-h-screen bg-black p-4">
        <div className="bg-[#2C2C2C] p-4 sm:p-6 md:p-8 rounded-xl shadow-2xl w-full max-w-md text-center text-white relative min-h-[100dvh] md:min-h-0 overflow-auto">
          {/* Back Button */}
          <button 
            className="absolute top-4 left-4 hover:opacity-75 transition-opacity z-10"
            aria-label="Go back"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
            </svg>
          </button>

          <div className="h-full flex flex-col min-h-[100dvh] md:min-h-0">
            <div className="flex-shrink-0 mt-8 sm:mt-4">
              <h2 className="text-2xl sm:text-3xl font-bold mb-2 sm:mb-4">Add a Profile Picture</h2>
              <p className="text-sm sm:text-base text-gray-400 mb-6">
                Add a profile picture to stand out in the crowd. Everyone will be able to see your picture.
              </p>
            </div>

            <div className="flex-grow space-y-4 sm:space-y-6 overflow-y-auto scrollbar-hide px-2">
              {/* Banner Upload Section */}
              <div>
                <p className="text-base sm:text-lg font-medium mb-2">Banner</p>
                <input
                  type="file"
                  ref={bannerInputRef}
                  onChange={(e) => handleImageUpload(e, 'banner')}
                  accept={VALID_FILE_TYPES.join(',')}
                  className="hidden"
                  aria-label="Upload banner image"
                />
                <div 
                  onClick={() => bannerInputRef.current.click()}
                  onKeyPress={(e) => e.key === 'Enter' && bannerInputRef.current.click()}
                  className="bg-[#353535] rounded-lg h-24 sm:h-32 flex items-center justify-center hover:bg-[#404040] transition-colors cursor-pointer relative overflow-hidden mb-6"
                  role="button"
                  tabIndex={0}
                  aria-label="Click to upload banner image"
                >
                  {images.banner ? (
                    <div className="relative w-full h-full group">
                      <img 
                        src={images.banner} 
                        alt="Banner preview" 
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <svg className="w-8 sm:w-10 h-8 sm:h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <svg className="w-8 sm:w-10 h-8 sm:h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                    </svg>
                  )}
                </div>
              </div>

              {/* Profile Picture Upload Section */}
              <div className="mb-6">
                <p className="text-base sm:text-lg font-medium mb-2">Profile Picture</p>
                <input
                  type="file"
                  ref={profileInputRef}
                  onChange={(e) => handleImageUpload(e, 'profile')}
                  accept={VALID_FILE_TYPES.join(',')}
                  className="hidden"
                  aria-label="Upload profile picture"
                />
                <div 
                  onClick={() => profileInputRef.current.click()}
                  onKeyPress={(e) => e.key === 'Enter' && profileInputRef.current.click()}
                  className="bg-[#353535] rounded-full h-20 w-20 sm:h-24 sm:w-24 mx-auto flex items-center justify-center hover:bg-[#404040] transition-colors cursor-pointer relative overflow-hidden"
                  role="button"
                  tabIndex={0}
                  aria-label="Click to upload profile picture"
                >
                  {images.profile ? (
                    <div className="relative w-full h-full group">
                      <img 
                        src={images.profile} 
                        alt="Profile preview" 
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center rounded-full">
                        <svg className="w-6 md:w-8 h-6 md:h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <svg className="w-8 md:w-10 h-8 md:h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
                    </svg>
                  )}
                </div>
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col items-center gap-3">
                <button 
                  className={`w-2/3 sm:w-1/2 py-2.5 sm:py-3 px-4 sm:px-6 rounded-lg text-sm sm:text-base font-medium transition-colors 
                    ${(images.banner || images.profile) 
                      ? 'bg-[#FE2C55] hover:bg-[#e01942] text-white' 
                      : 'bg-gray-600 text-gray-300 cursor-not-allowed'
                    } ${isLoading ? 'cursor-not-allowed opacity-75' : ''}`}
                  disabled={(!images.banner && !images.profile) || isLoading}
                  onClick={handleSaveChanges}
                  aria-label="Save changes"
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Save Changes'
                  )}
                </button>
                
                <button 
                  className={`w-2/3 sm:w-1/2 bg-gray-600 text-white py-2.5 sm:py-3 px-4 sm:px-6 rounded-lg text-sm sm:text-base hover:bg-gray-700 transition-colors font-medium ${isLoading ? 'cursor-not-allowed opacity-75' : ''}`}
                  onClick={handleSkip}
                  disabled={isLoading}
                  aria-label="Skip profile picture upload"
                >
                  {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Skip'
                  )}
                </button>
              </div>
            </div>

            {/* Footer */}
            <div className="flex-shrink-0 mt-12 sm:mt-16 border-t border-gray-700">
              <p className="text-xs sm:text-sm text-gray-400 px-4 py-4">
                By continuing, you agree to our{' '}
                <a href="#" className="text-[#FE2C55] hover:underline">
                  Terms of services
                </a>
                {' '}and acknowledge that you read our{' '}
                <a href="#" className="text-[#FE2C55] hover:underline">
                  Privacy Policy
                </a>
                {' '}to learn how we collect and use your data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadProfilePicture;