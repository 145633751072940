import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { ZEGO_APP_ID, ZEGO_SERVER_SECRET } from '../config/zegoConfig';

const HostLiveStream = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [streamId] = useState(`stream-${Date.now()}`);
  const [error, setError] = useState(null);
  const [isStarting, setIsStarting] = useState(false);
  const [showSetupPopup, setShowSetupPopup] = useState(true);

  const startLiveStream = async () => {
    try {
      setIsStarting(true);
      setError(null);
      
      const userID = `host-${Date.now()}`;
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        ZEGO_APP_ID,
        ZEGO_SERVER_SECRET,
        streamId,
        userID,
        'Host',
        3600
      );

      const zp = ZegoUIKitPrebuilt.create(kitToken);
      
      await zp.joinRoom({
        container: containerRef.current,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role: ZegoUIKitPrebuilt.Host,
            liveStreamingMode: ZegoUIKitPrebuilt.LiveStreamingMode.RealTimeLive,
          },
        },
        showPreJoinView: false,
        showRoomTimer: false,
        showLeavingView: false,
        showUserList: false,
        showCameraButton: true,
        showMyCameraToggleButton: true,
        showMyMicrophoneToggleButton: true,
        showAudioVideoSettingsButton: true,
        showLayoutButton: false,
        showTextChat: false,
        showRemoveMeetingButton: false,
        showInviteMemberButton: false,
        showMeetingIDButton: false,
        showEndRoomButton: true,
        showScreenSharingButton: false,
        layout: "Grid",
        showNonVideoUser: false,
        showOnlyAudioUser: false,
        maxUsers: 2,
        videoConfig: {
          resolution: { width: 720, height: 1280 },
          bitrate: 1500,
          frameRate: 30,
        },
        audioConfig: {
          bitrate: 48,
          encoderConfig: {
            AEC: true,
            AGC: true,
            ANS: true,
          },
        },
        onJoinRoom: () => {
          setShowSetupPopup(false);
          setIsStarting(false);
        },
        onError: (error) => {
          console.error('Stream error:', error);
          setError('Failed to start stream. Please try again.');
          setIsStarting(false);
        }
      });
    } catch (error) {
      console.error('Error starting stream:', error);
      setError('Failed to start stream. Please try again.');
      setIsStarting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Pre-stream Setup */}
      {showSetupPopup && (
        <div className="fixed inset-0 bg-black/90 backdrop-blur-lg flex items-center justify-center z-50 px-4">
          <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-8 w-full max-w-sm border border-white/10">
            <h2 className="text-2xl font-semibold mb-2 text-white text-center">Start Live Stream</h2>
            <p className="text-white/70 text-sm mb-6 text-center">
              Your stream code will be:
            </p>
            
            <div className="bg-white/5 rounded-xl p-4 mb-6 text-center">
              <code className="text-white font-mono">{streamId}</code>
            </div>
            
            <div className="space-y-4">
              <button
                onClick={startLiveStream}
                disabled={isStarting}
                className="w-full bg-[#fe2c55] text-white py-4 rounded-2xl font-medium hover:bg-[#ff1a45] transition-all disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isStarting ? 'Starting...' : 'Start Live Stream'}
              </button>
              
              <button 
                onClick={() => navigate('/PostVideoPage')}
                className="w-full bg-white/5 text-white/70 py-4 rounded-2xl font-medium hover:bg-white/10 transition-all border border-white/10"
              >
                Cancel
              </button>
            </div>

            {error && (
              <div className="mt-6 text-red-400/90 text-sm text-center bg-red-500/10 rounded-2xl p-4 backdrop-blur-sm">
                {error}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Video Container */}
      <div 
        ref={containerRef}
        className="w-full h-screen bg-black"
      />
    </div>
  );
};

export default HostLiveStream; 