import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ChevronLeft,
  ChevronRight,
  User,
  ShoppingCart,
  ClipboardList,
  MapPin,
  PackageSearch,
  Bell,
  Radio,
  AlertCircle,
  FileText,
  UserMinus,
  LogOut
} from 'lucide-react';

const Section = ({ title, children }) => (
  <div className="mb-8">
    <h3 className="text-gray-400 text-sm px-4 mb-2">{title}</h3>
    <div className="bg-[#2c2c2e] rounded-lg overflow-hidden">
      {children}
    </div>
  </div>
);

const MenuItem = ({ icon, text, onClick }) => (
  <button
    onClick={onClick}
    className="w-full px-4 py-3.5 flex items-center justify-between text-white 
      hover:bg-white/5 active:bg-white/10 transition-colors border-b border-white/[0.08] last:border-0"
  >
    <div className="flex items-center gap-3">
      <div className="w-[22px] h-[22px] flex items-center justify-center text-white/80">
        {icon}
      </div>
      <span className="text-[15px]">{text}</span>
    </div>
    <ChevronRight className="w-5 h-5 text-gray-400" />
  </button>
);

const Settings = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      {/* Header - Fixed */}
      <div className="sticky top-0 z-10 bg-[#1c1c1e]/60 backdrop-blur-xl border-b border-white/[0.08]">
        <div className="flex items-center px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-semibold ml-2">Settings and privacy</h1>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-4">
          <Section title="Account">
            <MenuItem icon={<User size={18} />} text="Account" />
          </Section>

          {/* <Section title="Orders">
            <MenuItem icon={<ShoppingCart size={18} />} text="My cart" />
            <MenuItem icon={<ClipboardList size={18} />} text="Orders" />
            <MenuItem icon={<MapPin size={18} />} text="Your Addresses" />
            <MenuItem icon={<PackageSearch size={18} />} text="Track my orders" />
          </Section> */}

          <Section title="Content & Display">
            <MenuItem icon={<Bell size={18} />} text="Notifications" />
            <MenuItem icon={<Radio size={18} />} text="Live" />
          </Section>

          <Section title="Login">
            <MenuItem icon={<AlertCircle size={18} />} text="Report a problem" />
            <MenuItem icon={<FileText size={18} />} text="Term and policies" />
            <MenuItem icon={<LogOut size={18} />} text="Log out" />
          </Section>

          {/* Add extra padding at the bottom for better scrolling experience */}
          <div className="h-20"></div>
        </div>
      </div>
    </div>
  );
};

export default Settings; 