import React from 'react'
import product from '../productimage.png'
// import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
const ProductCard = ({productdata}) => {
  const Navigate=useNavigate();
  const gotoCheckoutpage=()=>{
    Navigate('/Checkout')
  }
  return (
   
    <div className='flex-none w-24 bg-white/90 rounded-xl p-2' >
    <div className='aspect-square w-full'>
      <img src={productdata.image} alt="" className='w-full h-full object-contain rounded-lg'/>
    </div>
    <p className='mt-1 text-center text-xs font-medium'>To the nines</p>
    <p className='text-center text-xs font-bold'>$20.8</p>
  </div>
  )
}

export default ProductCard
