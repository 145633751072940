import { getAuth } from "firebase/auth";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useParams, useLocation, Link } from "react-router-dom";
import { Heart, MessageCircle, Share2, Bookmark, Plus, ChevronRight, Check } from 'lucide-react';
import { useFirebase } from "../FirebaseContext/FIrebaseContext";
import LikeButton from "./LikeButton";

const ReelsPlayer = () => {
  const [videos, setVideos] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [likes, setLikes] = useState({});
  const [bookmarks, setBookmarks] = useState({});
  const [follows, setFollows] = useState({});
  const [initialVideoLoaded, setInitialVideoLoaded] = useState(false);
  const [videoErrors, setVideoErrors] = useState({});
  const [videoReady, setVideoReady] = useState({});
  const [productDetails, setProductDetails] = useState({});
  
  const email = getAuth().currentUser?.email;
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const containerRef = useRef(null);
  const loadingRef = useRef(false);
  const location = useLocation();
  const initialState = location.state || {};
  const firebase = useFirebase();
  const username = firebase.currentId;
  const { documentId } = useParams();

  // Function to fetch product details
  const fetchProductData = async (productId) => {
    if (!productId) return;
    
    try {
      const url = `${backendURL}/userdetail/Product_details?productid=${productId}`;
      const resp = await fetch(url);
      if (!resp.ok) {
        console.log("Error occurred while fetching product details");
        return;
      }
      const maindata = await resp.json();
      setProductDetails(prevDetails => ({
        ...prevDetails,
        [productId]: maindata.productdetails
      }));
    } catch (error) {
      console.log("Error occurred while fetching product details:", error);
    }
  };

  // Function to fetch specific video and random videos
  const fetchInitialVideos = async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);

    try {
      if (documentId) {
        console.log('Fetching specific video for ID:', documentId);
        const specificResponse = await fetch(`${backendURL}/userdetail/scrollsdata/specific/${documentId}`);
        const specificData = await specificResponse.json();
        
        const randomResponse = await fetch(`${backendURL}/userdetail/scrollsdata/all`);
        const randomData = await randomResponse.json();
        
        const specificScrolls = specificData?.scrolls;
        const randomScrolls = randomData?.scrolls || [];

        if (specificScrolls) {
          const specificVideo = specificScrolls;
          
          if (!specificVideo.videoUrl) {
            console.error('No videoUrl in specific video:', specificVideo);
          }
          
          const filteredRandomVideos = randomScrolls.filter(video => {
            if (!video.videoUrl) {
              console.error('No videoUrl in random video:', video);
              return false;
            }
            return video.id && specificVideo.id && video.id !== specificVideo.id;
          });   
          setVideos([specificVideo, ...filteredRandomVideos]);
        } else {
          console.log('No specific video found, using random videos');
          setVideos(randomScrolls.filter(video => video.videoUrl));
        }
      } else {
        const response = await fetch(`${backendURL}/userdetail/scrollsdata/all`);
        const data = await response.json();
        
        const validVideos = (data?.scrolls || []).filter(video => {
          if (!video.videoUrl) {
            console.error('Video missing URL:', video);
            return false;
          }
          return true;
        });
        
        setVideos(validVideos);
      }
      
      setHasMore(true);
      setInitialVideoLoaded(true);
    } catch (error) {
      console.error("Error fetching videos:", error);
      setVideos([]);
      setHasMore(false);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  // Function to fetch more random videos
  const fetchMoreVideos = async () => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);

    try {
      const response = await fetch(`${backendURL}/userdetail/scrollsdata/all`);
      const data = await response.json();
      
      const newScrolls = data?.scrolls || [];
      
      const existingIds = new Set(videos.map(v => v?.id).filter(Boolean));
      const newVideos = newScrolls.filter(video => 
        video?.videoUrl && video?.id && !existingIds.has(video.id)
      );
      
      if (newVideos.length === 0) {
        setHasMore(false);
      } else {
        setVideos(prev => [...prev, ...newVideos]);
      }
    } catch (error) {
      console.error("Error fetching more videos:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  // Effect to fetch initial videos
  useEffect(() => {
    if (initialState.videos && initialState.initialIndex >= 0) {
      setVideos(initialState.videos);
      setCurrentIndex(initialState.initialIndex);
    } else {
      fetchInitialVideos();
    }
  }, [documentId]);

  // Effect to fetch product details when current video changes
  useEffect(() => {
    if (videos[currentIndex]?.productTags?.[0]?.productId) {
      fetchProductData(videos[currentIndex].productTags[0].productId);
    }
  }, [currentIndex, videos]);

  // Scroll handler
  const handleScroll = () => {
    if (!containerRef.current || loadingRef.current) return;

    const container = containerRef.current;
    const scrollPosition = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    
    const newIndex = Math.floor(scrollPosition / clientHeight);
    if (newIndex !== currentIndex) {
      console.log(`Switching to video index ${newIndex}`);
      setCurrentIndex(newIndex);
      setIsPlaying(true);
    }

    if (scrollHeight - scrollPosition <= clientHeight * 1.5 && hasMore && !loading) {
      console.log('Loading more videos...');
      setPage(prev => prev + 1);
      fetchMoreVideos();
    }
  };

  // Interaction handlers
  const toggleLike = (e, videoId) => {
    e.stopPropagation();
    setLikes(prev => ({ ...prev, [videoId]: !prev[videoId] }));
  };

  const toggleBookmark = (e, videoId) => {
    e.stopPropagation();
    setBookmarks(prev => ({ ...prev, [videoId]: !prev[videoId] }));
  };

  const toggleFollow = (e, videoId) => {
    e.stopPropagation();
    setFollows(prev => ({ ...prev, [videoId]: !prev[videoId] }));
  };

  // Video state handlers
  const handleVideoError = (videoId) => {
    console.error(`Error loading video ${videoId}`);
    setVideoErrors(prev => ({ ...prev, [videoId]: true }));
  };

  const handleVideoReady = (videoId) => {
    console.log(`Video ${videoId} ready to play`);
    setVideoReady(prev => ({ ...prev, [videoId]: true }));
  };

  const handleVideoClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    const threshold = Math.min(rect.width, rect.height) * 0.15;
    
    if (Math.abs(x - centerX) < threshold && Math.abs(y - centerY) < threshold) {
      console.log('Toggling video playback');
      setIsPlaying(prev => !prev);
    }
  };

  return (
    <div
      ref={containerRef}
      onScroll={handleScroll}
      className="fixed inset-0 bg-black snap-y snap-mandatory overflow-y-scroll scrollbar-none"
    >
      {videos.length === 0 && !loading && (
        <div className="h-screen flex items-center justify-center text-white">
          <p>No videos available</p>
        </div>
      )}

      {videos.map((video, index) => {
        const videoUrl = video?.videoUrl;
        const currentProductId = video?.productTags?.[0]?.productId;
        const currentProductDetails = productDetails[currentProductId];
       console.log(currentProductDetails);
        if (!videoUrl) {
          console.error(`Missing video URL for index ${index}:`, video);
          return null;
        }

        return (
          <div 
            key={video?.id || index} 
            className="h-screen w-full snap-start snap-always relative"
            onClick={handleVideoClick}
          >
            {!videoReady[video?.id] && !videoErrors[video?.id] && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-12 h-12 border-4 border-white/20 border-t-white rounded-full animate-spin"></div>
              </div>
            )}

            {videoErrors[video?.id] ? (
              <div className="absolute inset-0 flex items-center justify-center text-white">
                <p>Error loading video</p>
              </div>
            ) : (
              <ReactPlayer
                url={videoUrl}
                playing={currentIndex === index && isPlaying}
                muted={false}
                playsinline
                width="100%"
                height="100%"
                onError={() => handleVideoError(video?.id)}
                onReady={() => handleVideoReady(video?.id)}
                onBuffer={() => console.log(`Video ${video?.id} buffering`)}
                onBufferEnd={() => console.log(`Video ${video?.id} buffer ended`)}
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  objectFit: 'cover'
                }}
                config={{
                  file: {
                    attributes: {
                      style: {
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }
                    },
                    forceVideo: true
                  }
                }}
              />
            )}

            <div className="absolute right-2 bottom-20 flex flex-col items-center gap-6">
              <button 
                onClick={(e) => toggleFollow(e, video?.id)}
                className="relative group"
              >
                <div className="w-12 h-12 rounded-full overflow-hidden ring-2 ring-white/20">
                  <img 
                    src={video?.userImage || "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=150"}
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className={`absolute -bottom-1 -right-1 w-5 h-5 rounded-full flex items-center justify-center ${
                  follows[video?.id] ? 'bg-blue-500' : 'bg-red-500'
                }`}>
                  {follows[video?.id] ? (
                    <Check className="w-3 h-3 text-white" />
                  ) : (
                    <Plus className="w-3 h-3 text-white" />
                  )}
                </div>
              </button>

              <LikeButton
                mainpage={true}
  itemId={video.id}
  initialLikeCount={video.likeCount || 0}
  initialIsLiked={video.isLikedByUser || false}
  onLikeToggle={(isLiked) => {
    // Optional: Update local state if needed
    setVideos(prev => prev.map(v => 
      v.id === video.id 
        ? { ...v, isLikedByUser: isLiked, likeCount: isLiked ? (v.likeCount || 0) + 1 : (v.likeCount || 0) - 1 }
        : v
    ));
  }}
/>

              <button 
                className="flex items-center gap-2 group flex-col"
                onClick={(e) => {
                  e.stopPropagation();
                  // setActiveScrollId(video.id);
                  // setShowComments(true);
                }}
              >
                <MessageCircle className="w-6 h-6 text-white/80 group-hover:text-white" />
                <span className="text-white/80 group-hover:text-white text-sm font-medium">
                  {video.commentCount || '0'}
                </span>
              </button>

              <button 
                onClick={(e) => toggleBookmark(e, video?.id)}
                className="flex flex-col items-center gap-1"
              >
                <Bookmark 
                  className={`w-8 h-8 ${bookmarks[video?.id] ? 'text-white fill-white' : 'text-white'}`}
                />
                {/* <span className="text-white text-xs">89k</span> */}
              </button>

              <button className="flex flex-col items-center gap-1">
                <Share2 className="w-8 h-8 text-white" />
                {/* <span className="text-white text-xs">132.5k</span> */}
              </button>
            </div>

            <div className="absolute bottom-20 left-2 w-[70%]">
              <div className="flex items-center gap-1 text-white text-xs mb-2">
                <span>Explore Similar</span>
                <ChevronRight className="w-4 h-4" />
              </div>
              {video?.id && (
                <Link to={`/ProductDisplayWTags/${video.id}`}>
                  <div className="bg-black/40 backdrop-blur-sm rounded-xl p-2 flex items-center gap-3">
                    <img
                      src={currentProductDetails?.image || "https://images.unsplash.com/photo-1609505848912-b7c3b8b4beda?w=300"}
                      alt="Product"
                      className="w-16 h-16 rounded-lg object-cover"
                    />
                    <div className="flex-1">
                      <h3 className="text-white text-sm font-medium line-clamp-1">
                        {currentProductDetails?.name || "Fashion Item"}
                      </h3>
                      <p className="text-white/70 text-xs line-clamp-1">
                        {currentProductDetails?.description || "Premium Quality"}
                      </p>
                      <p className="text-white font-semibold text-sm mt-0.5">
                        ${currentProductDetails?.price || "99.99"}
                      </p>
                    </div>
                    <div className="flex items-center justify-center w-8 h-8">
                      <ChevronRight className="w-5 h-5 text-white/70" />
                    </div>
                  </div>
                </Link>
              )}
            </div>
            
          </div>
          
        );
      })}

      {loading && (
        <div className="flex justify-center items-center py-4">
          <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default ReelsPlayer;