import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UpperNavbar from './UpperNavbar';
import Navbar from './Navbar';
import ProfileMainPage from './ProfileMainPage';
import ProfileScroll from './ProfileScroll';
import { Route, Routes } from 'react-router-dom';
import SocialList from './SocialList';

const Profile = () => {
  const location = useLocation();
  const isSocialList = location.pathname.includes('/SocialList');

  return (
    <div className='w-full sm:w-[300px] lg:w-[400px] h-screen mx-auto 
      bg-gradient-to-b from-[#0A0A0A] via-black to-[#121212]
      flex flex-col overflow-hidden'>
      {/* Upper Navigation */}
      <UpperNavbar/>

      {/* Main Content Area */}
      <div className={`flex-1 ${!isSocialList ? 'overflow-y-auto' : 'overflow-hidden'}
        overflow-x-hidden [-ms-overflow-style:none] [scrollbar-width:none] 
        [&::-webkit-scrollbar]:hidden`}>
        {isSocialList ? (
          <div className="h-full overflow-y-auto">
            <SocialList />
          </div>
        ) : (
          <>
            <ProfileMainPage/>
          </>
        )}
      </div>

      {/* Bottom Navigation */}
      <div className="flex-shrink-0 bg-gradient-to-t from-black via-black/95 to-transparent">
        <div className="px-2 pt-2 pb-safe">
          <div className="backdrop-blur-xl bg-[#0A0A0A]/90 rounded-2xl border border-white/[0.08]
            shadow-xl">
            <Navbar/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
