import { getAuth } from 'firebase/auth';
import React, { useState, useCallback, memo, useEffect } from 'react';
import { IoIosArrowBack, IoIosClose, IoIosSearch } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';

// SearchBar Component
const SearchBar = memo(({ searchQuery, onSearchChange, onClearSearch, activeTab }) => (
  <div className="px-4 py-3 border-t border-gray-800/20">
    <div className="relative group">
      <div className="absolute inset-0 bg-gradient-to-r from-[#FE2C55]/10 to-[#FF597B]/10 
        rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-300" 
      />
      
      <div className="absolute left-3.5 top-1/2 -translate-y-1/2 flex items-center z-10">
        <IoIosSearch className="w-5 h-5 text-white/70 group-hover:text-white transition-colors" />
      </div>

      <input
        type="text"
        value={searchQuery}
        onChange={onSearchChange}
        placeholder={`Search in ${activeTab}...`}
        className="w-full pl-11 pr-4 py-3 bg-gray-900/80 rounded-xl text-base 
          text-white placeholder:text-gray-500 focus:outline-none 
          focus:ring-1 focus:ring-[#FE2C55]/50 transition-all duration-300
          border border-gray-800/50 focus:border-[#FE2C55]/30
          backdrop-blur-sm relative"
        autoComplete="off"
        spellCheck="false"
      />

      {searchQuery && (
        <button
          onClick={onClearSearch}
          className="absolute right-3 top-1/2 -translate-y-1/2 w-8 h-8 
            flex items-center justify-center rounded-full 
            hover:bg-gray-800 active:bg-gray-700 transition-colors z-10"
        >
          <IoIosClose className="w-5 h-5 text-gray-400" />
        </button>
      )}
    </div>
  </div>
));

// UserList Component
const UserList = memo(({ users, onFollow, onRemoveFollower, activeTab, onUserClick }) => (
  <div className="divide-y divide-gray-800/40 mt-4">
    {users.map(user => (
      <div key={user.id} 
        className="flex items-center justify-between py-4 px-4 hover:bg-white/5 active:bg-white/10 transition-colors"
      >
        <div className="flex items-center gap-4 cursor-pointer" onClick={() => onUserClick(user.username)}>
          <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-800 ring-1 ring-gray-700/50 
            flex items-center justify-center flex-shrink-0 relative group">
            <div className="absolute inset-0 bg-gradient-to-b from-black/0 to-black/50 opacity-0 
              group-hover:opacity-100 transition-opacity" />
            <img 
              src={user.profileimage || `https://ui-avatars.com/api/?name=${user.name}&background=27272a&color=fff`} 
              alt={user.name}
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.src = `https://ui-avatars.com/api/?name=${user.name}&background=27272a&color=fff`;
              }}
            />
          </div>
          <div className="flex flex-col justify-center min-w-0">
            <h3 className="text-base font-medium text-white truncate">{user.name}</h3>
            <p className="text-sm text-gray-400 truncate">@{user.username}</p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => onFollow(user)}
            className={`px-6 py-2 rounded-full text-sm font-medium transition-all flex-shrink-0
              ${activeTab === 'following'
                ? 'bg-gray-800/80 text-white border border-gray-700/50 hover:bg-gray-700/80 backdrop-blur-sm'
                : 'bg-gradient-to-r from-[#FE2C55] to-[#FF597B] text-white hover:opacity-90 active:opacity-100'
              }`}
          >
            {activeTab === 'following' ? 'Unfollow' : 'Follow'}
          </button>
        </div>
      </div>
    ))}
  </div>
));

// LoadingSpinner Component
const LoadingSpinner = () => (
  <div className="flex items-center justify-center min-h-screen">
    <div className="w-12 h-12 border-4 border-[#FE2C55]/20 border-t-[#FE2C55] rounded-full animate-spin" />
  </div>
);

// Main SocialList Component
function SocialList() {
  const navigate = useNavigate();
  const { username} = useParams();
  const [activeTab, setActiveTab] = useState('following');
  const [followerUsers, setFollowerUsers] = useState([]);
  const [followingUsers, setFollowingUsers] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const auth = getAuth();
  const currentUser = auth.currentUser;


  //navigate to user of followers and following and get the detials of specific user to show iamges and usernames in list from the list which is mapped in below api
  const handleUserClick = useCallback((clickedUsername) => {
    navigate(`/userdetail/${clickedUsername}`);
  }, [navigate]);
  // Fetch user details by username
  const fetchUserDetails = async (username) => {
    try {
      const response = await fetch(`${backendURL}/userdetail/followerfollowinguser/${username}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      return {
        ...data.userprofile,
        isFollowing: false 
      };
    } catch (error) {
      console.error(`Error fetching details for ${username}:`, error);
      return null;
    }
  };

  // Main profile fetching function so that we can get the list of followers and following
  const fetchProfileData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendURL}/userdetail/${username}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const data = await response.json();
      const profile = data.userprofile;
       
      // Fetch follower details
      if (profile.followers?.length) {
        const followerDetailsPromises = profile.followers.map(fetchUserDetails);
        const followerDetails = await Promise.all(followerDetailsPromises);
        setFollowerUsers(followerDetails.filter(user => user !== null));
      }

      // Fetch following details
      if (profile.following?.length) {
        const followingDetailsPromises = profile.following.map(fetchUserDetails);
        const followingDetails = await Promise.all(followingDetailsPromises);
        console.log(followingDetails);
        setFollowingUsers(followingDetails.filter(user => user !== null).map(user => ({
          ...user,
          isFollowing: true
        })));
      }

      // Set some suggested users (you might want to fetch these from an API)
      setSuggestedUsers([
        // Add some sample suggested users or fetch from API
      ]);

    } catch (error) {
      console.error('Error fetching profile:', error);
      setError('Failed to load profile data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [username]);

  // Handle following/unfollowing users
  const handleFollow = useCallback(async (targetUser) => {
    if (!currentUser) return;

    const currentUserEmail = currentUser.email;
    
    try {
      const response = await fetch(`${backendURL}/userdetail/follower_following/${targetUser.username}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: currentUserEmail }),
      });

      if (!response.ok) throw new Error('Failed to update follow status');

      const result = await response.json();

      if (result.success) {
        // If we're in the following tab, remove the user from the list
        if (activeTab === 'following') {
          setFollowingUsers(prev => prev.filter(user => user.username !== targetUser.username));
        } else {
          // If we're in followers tab or suggested tab, update the follow status
          const updateUserList = (users) =>
            users.map(user =>
              user.username === targetUser.username
                ? { ...user, isFollowing: !user.isFollowing }
                : user
            );

          setFollowerUsers(updateUserList);
          setSuggestedUsers(updateUserList);
          
          // If the user is now followed, add them to following list
          if (!targetUser.isFollowing) {
            setFollowingUsers(prev => [...prev, { ...targetUser, isFollowing: true }]);
          }
        }
      }
    } catch (error) {
      console.error('Error updating follow status:', error);
    }
  }, [activeTab, backendURL, currentUser]);
  // Handle removing followers
  const handleRemoveFollower = useCallback(async (userId) => {
    // Implementation for removing followers
    // You'll need to add your API call here
    setFollowerUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
  }, []);

  // Filter users based on search query
  const getFilteredUsers = useCallback(() => {
    const searchTerm = searchQuery.toLowerCase().trim();
    let users;

    switch (activeTab) {
      case 'following':
        users = followingUsers;
        break;
      case 'followers':
        users = followerUsers;
        break;
      case 'suggested':
        users = suggestedUsers;
        break;
      default:
        users = [];
    }
    
    if (!searchTerm) return users;

    return users.filter(user => 
      user.name?.toLowerCase().includes(searchTerm) ||
      user.username?.toLowerCase().includes(searchTerm)
    );
  }, [activeTab, followingUsers, followerUsers, suggestedUsers, searchQuery]);

  // Search handlers
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          <h3 className="text-lg font-medium mb-2">Error</h3>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  const filteredUsers = getFilteredUsers();
  const data=filteredUsers.map((data)=>{return data});
  console.log(data);
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <header className="fixed top-0 z-50">
        <div className="bg-gradient-to-b from-black/95 to-black/80 backdrop-blur-xl border-b border-gray-800/20">
          <div className="safe-top px-4 h-14 flex items-center justify-between max-w-[393px] mx-auto">
            <button 
              className="w-10 h-10 -ml-2 flex items-center justify-center rounded-full hover:bg-white/10 active:bg-white/5 transition-colors"
              onClick={() => navigate(-1)}
            >
              <IoIosArrowBack className="w-6 h-6 text-[#FE2C55]" />
            </button>
            <h1 className="text-lg font-semibold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
              Social List
            </h1>
            <div className="w-10" />
          </div>

          <div className="px-4 flex justify-between">
            {['Following', 'Followers', 'Suggested'].map(tab => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab.toLowerCase())}
                className={`px-8 py-4 text-sm font-medium relative transition-all
                  ${activeTab === tab.toLowerCase()
                    ? 'text-[#FE2C55]'
                    : 'text-gray-400 hover:text-gray-300'
                  }`}
              >
                {tab}
                {activeTab === tab.toLowerCase() && (
                  <>
                    <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gradient-to-r from-[#FE2C55] to-[#FF597B]" />
                    <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-1 h-1 rounded-full bg-[#FE2C55] blur-sm" />
                  </>
                )}
              </button>
            ))}
          </div>

          {activeTab !== 'suggested' && (
            <SearchBar 
              searchQuery={searchQuery}
              onSearchChange={handleSearchChange}
              onClearSearch={handleClearSearch}
              activeTab={activeTab}
            />
          )}
        </div>
      </header>

      <main className={`
        ${activeTab === 'suggested' ? 'pt-[108px]' : 'pt-[125px]'}
        pb-8 max-w-[393px] mx-auto px-4 safe-bottom
      `}>
        {filteredUsers.length > 0 ? (
          <UserList 
            users={filteredUsers}
            onFollow={handleFollow}
            onRemoveFollower={handleRemoveFollower}
            activeTab={activeTab}
            onUserClick={handleUserClick}
          />
        ) : (
          <div className="flex flex-col items-center justify-center py-12 text-center">
            <div className="w-16 h-16 rounded-full bg-gray-800/50 flex items-center justify-center mb-4">
              <IoIosSearch className="w-8 h-8 text-gray-600" />
            </div>
            <h3 className="text-base font-medium text-white mb-1">No results found</h3>
            <p className="text-sm text-gray-400">
              Try adjusting your search terms
            </p>
          </div>
        )}
      </main>

      <div className="h-[env(safe-area-inset-bottom)]" />
    </div>
  );
}

export default SocialList;