import React, { useEffect, useState } from 'react'
import brandimage from '../brandimage.png'
import { Link } from 'react-router-dom'

const BrandPage = ({brand}) => {
   const[productdata,setproductdata]=useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    
      const fetchproductimage = async () => {
          try {
              // const serllerId=username;
              // console.log(serllerId)
              const url = `${backendURL}/userdetail/product_details/specificuser/${brand.username}`;
              const response = await fetch(url);
              if(!response.ok) {
                  throw new Error('Response status');
              }
              const imagedatas = await response.json();
              // console.log(imagedatas.productdetails);
              const arraofdata = imagedatas.productdetails;
              setproductdata(prevImages => {
                  const uniqueImages = [...prevImages, ...arraofdata].filter(
                      (item, index, self) =>
                          index === self.findIndex((t) => t.id === item.id)
                  );
                  return uniqueImages;
              });
              
          } catch(error) {
              console.log("error occured:", error);
          }
      }
  
      useEffect(() => {
          fetchproductimage();
      }, []);
      console.log(productdata);
    
  return (
    <div className='relative w-full h-[38rem] rounded-xl shadow-md overflow-hidden'>
      <div className='absolute inset-0 bg-gradient-to-b from-[#ff9494] to-white'/>
      
      {/* Content */}
      <div className='relative h-full flex flex-col'>
        <Link to="/brandpage">
          <div className='flex flex-row justify-between  p-4 items-center'> 
            <div className='flex items-center gap-3'>
              <div className='w-12 h-12 rounded-xl overflow-hidden bg-white/90'>
                <img 
                  src={brandimage} 
                  alt="Brand" 
                  className='w-full h-full object-contain'
                />
              </div>
              <div>
                <h3 className='text-lg font-medium text-gray-900'>{brand.storename}</h3>
                <p className='text-gray-600 text-sm'>{brand.followers?.length} followers</p>
              </div>
            </div>
            <Link to ={`/userdetail/${brand.username}`}>
            <button className='px-6 py-1.5 bg-black text-white rounded-full text-sm font-medium '>
              View Store
            </button>
            </Link>
          </div>
        </Link>

        {/* Products Grid */}
        <div className='flex-1 px-4 pb-4 pt-2'>
          <div className='h-full grid grid-cols-2 grid-rows-2 gap-4'>
            <div className='bg-white/90 rounded-xl p-3'>
            <Link to={`/ProductPage/${productdata[0]?.id}`}>
              <div className='w-full h-[85%]'>
                <img src={productdata[0]?.image} alt="" className='w-full h-full object-contain rounded-lg'/>
              </div>
              </Link>
              <p className='text-center text-xs font-medium text-gray-900 mt-2'>{productdata[0]?.name}</p>
              <p className='text-center text-xs font-bold text-gray-900'>{productdata[0]?.price}</p>
            </div>
            <div className='bg-white/90 rounded-xl p-3'>
            <Link to={`/ProductPage/${productdata[1]?.id}`}>
              <div className='w-full h-[85%]'>
                <img src={productdata[1]?.image} alt="" className='w-full h-full object-contain rounded-lg'/>
              </div>
              </Link>
              <p className='text-center text-xs font-medium text-gray-900 mt-2'>{productdata[1]?.name}</p>
              <p className='text-center text-xs font-bold text-gray-900'>{productdata[1]?.price}</p>
            </div>
            <div className='bg-white/90 rounded-xl p-3'>
            <Link to={`/ProductPage/${productdata[0]?.id}`}>
              <div className='w-full h-[85%]'>
                <img src={productdata[2]?.image} alt="" className='w-full h-full object-contain rounded-lg'/>
              </div>
              </Link>
              <p className='text-center text-xs font-medium text-gray-900 mt-2'>{productdata[0]?.name}</p>
              <p className='text-center text-xs font-bold text-gray-900'>{productdata[0]?.price}</p>
            </div>
            <div className='bg-white/90 rounded-xl p-3'>
            <Link to={`/ProductPage/${productdata[0]?.id}`}>
              <div className='w-full h-[85%]'>
                <img src={productdata[3]?.image} alt="" className='w-full h-full object-contain rounded-lg'/>
              </div>
              </Link>
              <p className='text-center text-xs font-medium text-gray-900 mt-2'>{productdata[0]?.name}</p>
              <p className='text-center text-xs font-bold text-gray-900'>{productdata[0]?.price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandPage
