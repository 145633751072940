import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Bookmark } from 'lucide-react';
import Navbar from './Navbar';
import UpperNavbar from './UpperNavbar';
import StatusLive from './StatusLive';
import Scrollpage from './Scrollpage';
import BrandPage from './BrandPage';
import ScrollFollowing from './ScrollFollowing';
import HomePageBrandProdcuts from './HomePageBrandProdcuts';
const HomePage = () => {
  const { pathname } = useLocation();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  
  // Core states
  const [isScrollpage, setIsScrollpage] = useState(false);
  const [isProfilePage, setIsProfilePage] = useState(false);
  const [productfetch, setProductimage] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('right');
  const [imagedata, setImagedata] = useState([]);
  const [discoverimage, setDiscoverImagedata] = useState([]);
  const [discoverfetch, setProductofdiscoverimage] = useState([]);
  const [sellerdata, setsellerdata] = useState(null);
  
  // Infinite scroll states
  const [displayItems, setDisplayItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef();
  const loadingRef = useRef(null);

  // Path-based page setup
  useEffect(() => {
    setIsScrollpage(pathname.includes('/scroll'));
    setIsProfilePage(pathname.includes('/profile'));
  }, [pathname]);

  // Fetch data functions
  const fetchData = useCallback(async (endpoint, errorMessage) => {
    try {
      const response = await fetch(`${backendURL}${endpoint}`);
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return data;
    } catch (error) {
      console.error(errorMessage, error);
      return null;
    }
  }, [backendURL]);

  // Fetch scroll images
  useEffect(() => {
    const fetchScrollImages = async () => {
      const data = await fetchData(
        '/userdetail/scrollsdata/all',
        'Error fetching scrolls:'
      );
      
      if (data?.scrolls?.length) {
        setImagedata(prev => [...prev, ...data.scrolls]);
        setProductimage(data.scrolls);
      }
    };
    
    fetchScrollImages();
  }, [fetchData]);

  // Fetch product images
  useEffect(() => {
    const fetchProductImages = async () => {
      const data = await fetchData(
        '/userdetail/Product_details/all',
        'Error fetching products:'
      );
      
      if (data?.productdetails?.length) {
        setDiscoverImagedata(prev => [...prev, ...data.productdetails]);
        setProductofdiscoverimage(data.productdetails);
      }
    };
    
    fetchProductImages();
  }, [fetchData]);
  console.log(sellerdata);

  // Fetch seller details
  useEffect(() => {
    const fetchSellerDetails = async () => {
      const data = await fetchData(
        '/userdetail/seller/details/for/home/brand',
        'Error fetching seller details:'
      );
      
      if (data?.sellers) {
        setsellerdata(data.sellers);
        setDisplayItems(createDisplayItems(data.sellers));
      }
    };
    
    fetchSellerDetails();
  }, [fetchData]);

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          loadMoreItems();
        }
      },
      { threshold: 0.1 }
    );

    const currentLoadingRef = loadingRef.current;
    if (currentLoadingRef) {
      observer.observe(currentLoadingRef);
    }

    return () => {
      if (currentLoadingRef) {
        observer.unobserve(currentLoadingRef);
      }
    };
  }, [hasMore, loading]);

  // Helper function to create display items
  const createDisplayItems = useCallback((sellers, startIndex = 0, count = 4) => {
    if (!sellers?.length) return [];
    
    return Array.from({ length: count }, (_, i) => {
      const seller = sellers[startIndex + (i % sellers.length)];
      return {
        type: i % 2 === 0 ? 'brand_products' : 'brand_page',
        data: seller,
        id: `${i}-${seller.id}-${Date.now()}`
      };
    });
  }, []);

  // Load more items function
  const loadMoreItems = useCallback(() => {
    if (loading || !hasMore || !sellerdata) return;

    setLoading(true);
    setTimeout(() => {
      const newItems = createDisplayItems(sellerdata, displayItems.length, 4);
      setDisplayItems(prev => [...prev, ...newItems]);
      setLoading(false);
    }, 500);
  }, [loading, hasMore, sellerdata, displayItems.length, createDisplayItems]);

  // Handle swipe for discover products
  const handleSwipe = useCallback((direction) => {
    setSlideDirection(direction);
    if (direction === 'right') {
      console.log('Liked product:', discoverfetch[currentProductIndex]);
    }
    setCurrentProductIndex(prev => prev + 1);
  }, [currentProductIndex, discoverfetch]);

  // Animation configurations
  const getSlideAnimation = useCallback((direction) => {
    const animations = {
      left: { initial: { x: 300 }, animate: { x: 0 }, exit: { x: -300 } },
      right: { initial: { x: -300 }, animate: { x: 0 }, exit: { x: 300 } },
      bottom: { initial: { y: -300 }, animate: { y: 0 }, exit: { y: 300 } }
    };
    
    return {
      ...animations[direction] || animations.left,
      initial: { ...animations[direction]?.initial, opacity: 0 },
      animate: { ...animations[direction]?.animate, opacity: 1 },
      exit: { ...animations[direction]?.exit, opacity: 0 }
    };
  }, []);

  // Render components
  const renderDiscoverProduct = useCallback(() => {
    const currentProduct = discoverfetch[currentProductIndex];
   
    if (!currentProduct) return null;

    return (
      <motion.div
        key={currentProductIndex}
        className="absolute inset-0 flex flex-col"
        {...getSlideAnimation(slideDirection)}
        transition={{ type: "tween", duration: 0.3, ease: "easeInOut" }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={0.7}
        onDragEnd={(e, { offset }) => {
          const swipe = offset.x > 100 ? 'right' : offset.x < -100 ? 'left' : 'none';
          if (swipe !== 'none') handleSwipe(swipe);
        }}
      >
        {/* Product content */}
        <Link to={`/ProductPage/${currentProduct.id}`} className="block">
          <div className="w-full h-[400px] relative">
            <img
              src={currentProduct.image}
              alt={currentProduct.name}
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/20 via-transparent to-black" />
          </div>
        </Link>

        <div className="flex-1 flex flex-col justify-between p-6 bg-gradient-to-t from-black to-transparent">
          <Link to={`/ProductPage/${currentProduct.id}`}>
            <div className="mb-6">
              <div className="flex justify-between items-start mb-2">
                <h3 className="text-white text-xl font-semibold leading-tight max-w-[70%]">
                  {currentProduct.name}
                </h3>
                <span className="text-white text-xl font-bold">
                  ${currentProduct.price}
                </span>
              </div>
              <p className="text-white/70 text-sm line-clamp-2">
                {currentProduct.description}
              </p>
            </div>
          </Link>

          {/* Action buttons */}
          <div className="relative">
            <div className="flex justify-center items-center gap-4 mb-6">
              <button
                onClick={() => handleSwipe('left')}
                className="w-12 h-12 rounded-full bg-white/10 backdrop-blur-xl border border-white/20 
                  flex items-center justify-center hover:bg-red-500/20"
              >
                <X className="text-white w-6 h-6" />
              </button>
              <button
                onClick={() => handleSwipe('bottom')}
                className="w-14 h-14 rounded-full bg-gradient-to-r from-[#fe2c55] to-[#fe5735]
                  flex items-center justify-center shadow-lg"
              >
                <Bookmark className="text-white w-7 h-7" />
              </button>
              <button
                onClick={() => handleSwipe('right')}
                className="w-12 h-12 rounded-full bg-white/10 backdrop-blur-xl border border-white/20 
                  flex items-center justify-center hover:bg-blue-500/20"
              >
                <svg className="w-6 h-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </button>
            </div>

            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 
              text-white/50 text-xs flex items-center gap-1.5">
              <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                  d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
              </svg>
              Swipe to explore
            </div>
          </div>
        </div>
      </motion.div>
    );
  }, [currentProductIndex, discoverfetch, getSlideAnimation, handleSwipe, slideDirection]);
  const[isForyou,setIsForyou]=useState(false);
  useEffect(() => {
    const followingValue = pathname.includes('/scroll/foryou');
    setIsForyou(followingValue);
  }, [pathname]);
  return(
    <div className="w-full sm:w-[300px] md:w-[350px] lg:w-[400px] h-screen mx-auto 
      bg-gradient-to-b from-[#1c1c1e] via-black to-[#1c1c1e]
      flex flex-col overflow-hidden">
      <UpperNavbar />
      
      <main className="flex-grow overflow-y-auto overflow-x-hidden
        [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
         {!isForyou && (
          <div className={`
            ${isForyou?null:'bg-black'}
            livesection 
            flex 
            flex-row 
            items-start
            p-2 
          `}>
            {[...Array(1)].map((_, index) => (
              <StatusLive key={index}/>
            ))}
          </div>
        )}
        {isScrollpage ? (
          <ScrollFollowing />
        ) : (
          <div className="flex flex-col w-full">
            {/* Trending Section */}
            <section className="px-4 py-3 border-b border-white/[0.08]">
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-white/80 text-sm font-medium">Trending</h2>
              </div>
              <div className="flex gap-3 overflow-x-auto pb-2
                [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden">
                {productfetch.map((product) => (
                  <div key={product.id} className="flex-shrink-0">
                    <Scrollpage 
                      imageUrl={product.thumbnailUrl} 
                      productid={product.id} 
                      isDiscover={false} 
                    />
                  </div>
                ))}
              </div>
            </section>

            {/* Discover Products Section */}
            <section className="px-4 py-3">
              <div className="flex justify-between items-center mb-3">
                <h2 className="text-white/80 text-sm font-medium">Discover Products</h2>
                <button 
                  className="text-[#fe2c55] text-sm font-medium"
                  onClick={() => setCurrentProductIndex(prev => prev + 1)}
                >
                  Skip
                </button>
              </div>
              <div className="relative h-[600px] rounded-2xl overflow-hidden shadow-2xl bg-[#1C1C1E]">
                <AnimatePresence mode="wait">
                  {renderDiscoverProduct()}
                </AnimatePresence>
              </div>
            </section>
            {/* Infinite Scroll Content */}
            {displayItems.map((item) => (
              <div key={item.id} className="animate-fadeIn">
                <section className="px-4 py-3">
                  <h2 className="text-white/80 text-sm font-medium mb-3">
                    {item.type === 'brand_products' ? 'Popular Products' : 'Featured Brands'}
                  </h2>
                  <div className="space-y-4">
                    {item.type === 'brand_products' ? (
                      <HomePageBrandProdcuts brand={item.data} />
                    ) : (
                      <BrandPage brand={item.data} />
                    )}
                  </div>
                </section>
              </div>
            ))}

            {/* Loading States */}
            {loading && (
              <div className="px-4 py-6 text-center">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-white/20" />
                <p className="text-white/50 text-sm mt-2">Loading more items...</p>
              </div>
            )}

            <div ref={loadingRef} className="h-4 mb-4" aria-hidden="true" />

            {!hasMore && (
              <div className="px-4 py-6 text-center text-white/50 text-sm">
                No more items to load
              </div>
            )}
          </div>
        )}
      </main>
      
      {/* Bottom Navigation */}
      {/* Bottom Navigation */}
      <footer className="flex-shrink-0 bg-gradient-to-t from-black via-black/95 to-transparent">
        <div className="px-2 pt-2 pb-safe">
          <div className="backdrop-blur-xl bg-[#1c1c1e]/90 rounded-2xl border border-white/[0.08]
            shadow-xl">
            <Navbar />
          </div>
        </div>
      </footer>
    </div>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(HomePage);