import React, { useState, useRef, useEffect } from 'react';
import VideoPreviewPage from './VideoPreviewPage';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import { getAuth } from 'firebase/auth';
import { Mic, MicOff, Video, VideoOff, Settings } from 'lucide-react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { ZEGO_APP_ID, ZEGO_SERVER_SECRET } from '../config/zegoConfig';
import UploadStyles from './UploadStyles';
import LiveStreamHost from './LiveStreamHost';

function PostVideoPage() {
  const [selectedTab, setSelectedTab] = useState('Scroll');
  const [videoSrc, setVideoSrc] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const firebase = useFirebase();

  // Live streaming states
  const [hasPermissions, setHasPermissions] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);
  const [previewStream, setPreviewStream] = useState(null);
  const videoPreviewRef = useRef(null);
  const zegoRef = useRef(null);
  

  // Check and request permissions
  const checkPermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
      setHasPermissions(true);
      setPreviewStream(stream);
      if (videoPreviewRef.current) {
        videoPreviewRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error getting permissions:', error);
      setHasPermissions(false);
    }
  };

  // Toggle camera
  const toggleCamera = async () => {
    if (previewStream) {
      const videoTrack = previewStream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = !isCameraOn;
        setIsCameraOn(!isCameraOn);
      }
    }
  };

  // Toggle microphone
  const toggleMic = async () => {
    if (previewStream) {
      const audioTrack = previewStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !isMicOn;
        setIsMicOn(!isMicOn);
      }
    }
  };

  // Start live stream
  const startLiveStream = () => {
    // Stop preview stream before navigating
    if (previewStream) {
      previewStream.getTracks().forEach(track => track.stop());
    }

    // Generate a unique channel name and user ID
    const channelName = `stream-${Date.now()}`;
    const userID = `host-${Date.now()}`;

    // Navigate to host live stream page
    navigate('/host-live', {
      state: {
        channelName,
        userID
      }
    });
  };

  // Effect to handle permissions when Live tab is selected
  useEffect(() => {
    if (selectedTab === 'Live') {
      checkPermissions();
    } else {
      // Clean up stream when switching away from Live tab
      if (previewStream) {
        previewStream.getTracks().forEach(track => track.stop());
        setPreviewStream(null);
      }
    }
    return () => {
      if (previewStream) {
        previewStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [selectedTab]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      firebase.setFile(file);
      if (videoSrc) {
        URL.revokeObjectURL(videoSrc);
      }
      const url = URL.createObjectURL(file);
      setVideoSrc(url);
      setShowPreview(true);
      event.target.value = '';
    }
  };

  const handlePreviewClose = () => {
    setShowPreview(false);
    setVideoSrc(null);
  };

  const handleNext = () => {
    navigate('/NewScrollPage');
  };

  return (
    <>
      <div className="flex items-center justify-center bg-[#000000] text-white overflow-hidden" 
           style={{ height: 'calc(var(--vh, 1vh) * 100)' }}>
        <div className="relative w-full h-full max-w-md flex flex-col">
          <div className="absolute top-3 left-3 z-10">
            <button 
              className="w-8 h-8 flex items-center justify-center rounded-full bg-[#1D1D1D] hover:bg-[#2D2D2D] transition-colors" 
              onClick={() => navigate(`/userdetail/${firebase.currentId}`)}
            >
              <svg className="w-5 h-5 text-white/90" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          
          <div className="pt-14 px-4">
            <div className="flex justify-center">
              <div className="bg-[#1D1D1D] rounded-2xl p-1 flex text-sm">
                <button 
                  className={`py-2 px-6 rounded-xl transition-all duration-300 font-medium ${
                    selectedTab === 'Live' 
                      ? 'bg-black text-white shadow-lg' 
                      : 'text-white hover:text-white/80'
                  }`} 
                  onClick={() => setSelectedTab('Live')}
                >
                  Live
                </button>
                <button 
                  className={`py-2 px-6 rounded-xl transition-all duration-300 font-medium ${
                    selectedTab === 'Scroll' 
                      ? 'bg-black text-white shadow-lg' 
                      : 'text-white hover:text-white/80'
                  }`}
                  onClick={() => {
                    setSelectedTab('Scroll');
                    firebase.setswitchtoStyles(false);
                  }}
                >
                  Scroll
                </button>
                <button 
                  className={`py-2 px-6 rounded-xl transition-all duration-300 font-medium ${
                    selectedTab === 'Styles' 
                      ? 'bg-black text-white shadow-lg' 
                      : 'text-white hover:text-white/80'
                  }`} 
                  onClick={() => {
                    setSelectedTab('Styles');
                    firebase.setswitchtoStyles(true);
                  }}
                >
                  Styles
                </button>
              </div>
            </div>
          </div>
          
          <div className="flex-1 flex items-center justify-center p-4">
            {selectedTab === 'Live' ? <LiveStreamHost/> : selectedTab === 'Scroll'?(
              // Existing scroll/styles upload UI
              <div className="bg-[#1D1D1D] rounded-2xl w-full h-[calc(100vh-240px)] flex flex-col items-center justify-center p-8 shadow-2xl">
                <div className="mb-6">
                  <svg className="w-20 h-20 text-white/40" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <rect x="3" y="3" width="8" height="8" rx="2" strokeWidth="1.5" />
                    <path d="M13 7l4 4" strokeWidth="1.5" />
                    <rect x="13" y="13" width="8" height="8" rx="2" strokeWidth="1.5" />
                  </svg>
                </div>
                <p className="text-xl text-white/80 mb-8 font-medium">Drag photos and videos here</p>
                <button 
                  onClick={() => inputRef.current.click()} 
                  className="bg-[#0A84FF] text-white py-3 px-6 rounded-xl text-sm font-medium hover:bg-[#0071E3] transition-all duration-300 shadow-lg hover:shadow-xl active:scale-95"
                >
                  Select Video from Gallery
                </button>
                <input
                  type="file"
                  accept="video/*"
                  className="hidden"
                  ref={inputRef}
                  onChange={handleFileUpload}
                  capture="environment"
                />
              </div>
            ):<UploadStyles/>}
          </div>
          <div className="p-4 text-center">
            <p className="text-sm text-white font-medium mb-2">
              {selectedTab === 'Live' ? 'Start Live Stream' : firebase.switchtoStyles ? 'Post Styles' : 'Post Scroll'}
            </p>
            <div className="w-16 h-0.5 bg-white/20 mx-auto rounded-full"></div>
          </div>
        </div>
      </div>

      {showPreview && videoSrc && (
        <VideoPreviewPage
          videoSrc={videoSrc}
          onClose={handlePreviewClose}
          onNext={handleNext}
        />
      )}
    </>
  );
}

export default PostVideoPage;
