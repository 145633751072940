import { getAuth } from 'firebase/auth';
import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
// import
// import 
function EditLookPage({ selectedProducts = [], coverImage, onClose }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [tags, setTags] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;


  
  const isProductTagged = useCallback((product) => {
    return tags.some(tag => tag.product.name === product.name);
  }, [tags]);

  const handleImageClick = useCallback((e) => {
    if (selectedProduct) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = ((e.clientX - rect.left) / rect.width) * 100;
      const y = ((e.clientY - rect.top) / rect.height) * 100;
      // console.log(x, " ", y);
      const newTag = {
        product: selectedProduct,
        x,
        y,
      };
      setTags([...tags, newTag]);
      setSelectedProduct(null);
    }
  }, [selectedProduct, tags]);

  const removeTag = useCallback((index) => {
    setTags(tags.filter((_, i) => i !== index));
  }, [tags]);
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };
//upload the videos and images to storage
const username=useParams();
const Navigate=useNavigate();
const firebase=useFirebase();
useEffect(()=>{firebase.checkIfAdminPage(username)},[username]);
// const admin=getAuth().currentUser?.email;
const uploadscrolls = async () => {
  if (!firebase.currentId) {
    setToastMessage('User ID not found');
    setOpenToast(true);
    return;
  }
  
  if (!tags || tags.length === 0) {
    setToastMessage('At least one product tag is required');
    setOpenToast(true);
    return;
  }

  if (!firebase.file || !coverImage) {
    setToastMessage('Video and cover image are required');
    setOpenToast(true);
    return;
  }

  setIsUploading(true);
  try {
    // Convert video file to base64
    const videoBase64 = await fileToBase64(firebase.file);
    
    // Remove the data:video/* prefix from base64 if present
    const videoData = videoBase64.split(',')[1];
    
    // For cover image, remove the data:image/* prefix if present
    const coverData = coverImage.split(',')[1] || coverImage;

    // Prepare JSON payload
    const payload = {
      username: firebase.currentId,
      video: {
        name: firebase.file.name,
        type: firebase.file.type,
        data: videoData // Send just the base64 data without prefix
      },
      coverImage: {
        name: 'cover.jpg',
        type: 'image/jpeg',
        data: coverData // Send just the base64 data without prefix
      },
      productTags: tags.map(tag => ({
        product: {
          id: tag.product.id,
          name: tag.product.name
        },
        x: tag.x,
        y: tag.y
      }))
    };

    console.log('Payload structure:', {
      ...payload,
      video: { ...payload.video, data: 'truncated...' },
      coverImage: { ...payload.coverImage, data: 'truncated...' }
    });

    const url = `${backendURL}/userdetail/uploadscroll/${firebase.currentId}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    const responseText = await response.text();
    try {
      const responseData = JSON.parse(responseText);
      if (response.ok) {
        setToastMessage('Scroll uploaded successfully!');
        setOpenToast(true);
        Navigate(`/userdetail/${firebase.currentId}`);
      } else {
        throw new Error(responseData.message || 'Failed to upload scroll');
      }
    } catch (parseError) {
      console.error('Response parsing error:', parseError);
      console.error('Raw response:', responseText);
      throw new Error('Server returned invalid response');
    }
  } catch (error) {
    console.error('Upload error:', error);
    setToastMessage(`Upload failed: ${error.message}`);
    setOpenToast(true);
  } finally {
    setIsUploading(false);
  }
};

// Helper function to convert File to base64
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};
// const uploadstyles = async () => {
//   if (!firebase.file) {
//     setToastMessage('No file selected');
//     setOpenToast(true);
//     return;
//   }
//   const url = `${backendURL}/userdetail/uploadstyle/${firebase.currentId}`;
//   const formData = new FormData();
//   formData.append('video', firebase.file);
//   formData.append('username', firebase.currentId);
//   try {
//     const response = await fetch(url, {
//       method: 'POST',
//       body: formData,
//     });

//     if (response.ok) {
//       const responseData = await response.json(); // Parse response data
//       setToastMessage('Video uploaded successfully!');
//       setOpenToast(true);
      
//       // Navigate after successful upload
//       Navigate(`/userdetail/${firebase.currentId}`);
//     } else {
//       // Handle different error scenarios
//       const errorData = await response.json();
//       setToastMessage(errorData.message || 'Failed to upload video');
//       setOpenToast(true);
//     }
//   } catch (error) {
//     console.error('Upload error:', error);
//     setToastMessage('Network error. Please try again.');
//     setOpenToast(true);
//   }
// };
//handle upload data of scrolls and styles
// const handleupload=()=>{
//   if(firebase.switch===true){
    
//   }
// }

  return (
    <>
    <Snackbar
    open={openToast}
    autoHideDuration={6000}
    onClose={handleCloseToast}
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
  >
    <Alert
      onClose={handleCloseToast} 
      severity={toastMessage.includes('successfully') ? 'success' : 'error'}
      sx={{ width: '100%' }}
    >
      {toastMessage}
    </Alert>
  </Snackbar>
    <div className="fixed inset-0 bg-black text-white z-50">
      <div className="h-full flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-4 relative backdrop-blur-lg bg-black/40">
          <button 
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <h2 className="text-xl font-medium">Edit Look</h2>
          <button 
              className={`py-2 px-6 rounded-full text-sm font-medium transition-all flex items-center gap-2
              ${tags.length > 0
                ? 'bg-[#FE2C55] text-white hover:bg-[#ff1a45] active:bg-[#e01442]' 
                : 'bg-[#2C2C2E] text-white/40'}`}
              disabled={tags.length === 0 || isUploading}
              onClick={uploadscrolls}
            >
              {isUploading ? (
                <>
                  <CircularProgress size={16} color="inherit" />
                  <span>Uploading...</span>
                </>
              ) : (
                'Next'
              )}
            </button>
        </div>

        {/* Updated Main Content with better spacing */}
        <div className="flex-1 overflow-y-auto bg-gradient-to-b from-[#1C1C1E] to-black pb-48">
          {/* Optimized Image Area */}
          <div className="p-4 flex items-center justify-center min-h-[calc(100%-180px)]">
            <div className="relative w-full max-w-[280px] aspect-[9/16] mx-auto">
              <div 
                className="relative w-full h-full rounded-2xl overflow-hidden shadow-2xl 
                bg-[#1C1C1E]"
                onClick={handleImageClick}
              >
                <img
                  src={coverImage}
                  alt="Look"
                  className="w-full h-full object-cover"
                />
                
                {/* Updated Tags with dialogue box style */}
                {tags.map((tag, index) => (
                  <button
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      removeTag(index);
                    }}
                    className="absolute transform -translate-x-1/2 group"
                    style={{ left: `${tag.x}%`, top: `${tag.y}%` }}
                  >
                    {/* Tag Content - Smaller Dialogue Box */}
                    <div className="absolute bottom-2 transform -translate-x-1/2 min-w-[100px]">
                      {/* Dialogue Box */}
                      <div className="relative bg-gradient-to-b from-[#2C2C2E] to-[#1C1C1E] 
                        rounded-lg shadow-lg border border-white/10 p-1.5 
                        group-hover:border-[#FE2C55] transition-colors">
                        {/* Product Info */}
                        <div className="flex items-center space-x-2">
                          <div className="w-6 h-6 rounded-md overflow-hidden flex-shrink-0">
                            <img 
                              src={tag.product.image} 
                              alt={tag.product.name}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-[10px] font-medium truncate text-white/90">
                              {tag.product.name}
                            </p>
                            <p className="text-[10px] text-[#FE2C55] font-medium">
                              {tag.product.price}
                            </p>
                          </div>
                          {/* Remove Button */}
                          <div className="w-4 h-4 rounded-full bg-[#FE2C55] opacity-0 
                            group-hover:opacity-100 transition-opacity flex items-center justify-center 
                            flex-shrink-0">
                            <svg className="w-2.5 h-2.5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                        </div>

                        {/* Simple Pointer */}
                        <div className="absolute -bottom-1.5 left-1/2 transform -translate-x-1/2 
                          w-3 h-3 rotate-45 bg-gradient-to-br from-[#2C2C2E] to-[#1C1C1E] 
                          border-b border-r border-white/10 group-hover:border-[#FE2C55] transition-colors" />
                      </div>
                    </div>

                    {/* Simple Point Indicator */}
                    <div className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2">
                      <div className="w-1.5 h-1.5 rounded-full bg-[#FE2C55]" />
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Updated Products Carousel with inactive states */}
        <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-t from-black via-black/95 to-transparent">
          <div className="px-4 pt-16 pb-6">
            <p className="text-white/60 text-sm font-medium mb-3 text-center">
              {selectedProduct ? 'Tap on image to place tag' : 'Select a product to tag'}
            </p>
            <div className="flex space-x-3 overflow-x-auto pb-4 scrollbar-hide px-4">
              {selectedProducts.map((product, index) => {
                const isTagged = isProductTagged(product);
                return (
                  <button
                    key={index}
                    onClick={() => !isTagged && setSelectedProduct(product)}
                    disabled={isTagged}
                    className={`flex-shrink-0 group transition-transform ${
                      selectedProduct?.name === product.name ? 'scale-95' : ''
                    } ${isTagged ? 'opacity-40 cursor-not-allowed' : ''}`}
                  >
                    <div className={`relative rounded-xl overflow-hidden w-16 h-16 mb-2 
                      ${selectedProduct?.name === product.name ? 'ring-2 ring-[#FE2C55]' : ''}
                      ${isTagged ? 'ring-2 ring-white/20' : ''}`}
                    >
                      <img 
                        src={product.image} 
                        alt={product.name}
                        className="w-full h-full object-cover"
                      />
                      {isTagged && (
                        <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
                          <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                              d="M5 13l4 4L19 7" />
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="text-center">
                      <p className={`text-xs font-medium truncate max-w-[64px] ${
                        isTagged ? 'text-white/40' : ''
                      }`}>
                        {product.name}
                      </p>
                      <p className={`text-xs ${
                        isTagged ? 'text-[#FE2C55]/40' : 'text-[#FE2C55]'
                      }`}>
                        {product.price}
                      </p>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default EditLookPage; 