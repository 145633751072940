import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { motion } from 'framer-motion';

const Saved = () => {
  const [activeTab, setActiveTab] = useState('scrolls');
  const navigate = useNavigate();

  // Dummy data for scrolls
  const scrolls = [
    {
      id: 1,
      thumbnail: "https://images.unsplash.com/photo-1515886657613-9f3515b0c7y8f?w=800&auto=format&fit=crop&q=60",
      views: "12.5K",
      likes: "2.3K",
      timestamp: "2 days ago"
    },
    {
      id: 2,
      thumbnail: "https://images.unsplash.com/photo-1539109136881-3be0616acf4b?w=800&auto=format&fit=crop&q=60",
      views: "8.9K",
      likes: "1.8K",
      timestamp: "5 days ago"
    },
    {
      id: 3,
      thumbnail: "https://images.unsplash.com/photo-1576566588028-4147f3842f27?w=800&auto=format&fit=crop&q=60",
      views: "15.2K",
      likes: "3.1K",
      timestamp: "1 week ago"
    }
  ];

  // Dummy data for styles
  const styles = [
    {
      id: 1,
      image: "https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?w=800&auto=format&fit=crop&q=60",
      title: "Summer Collection",
      likes: "4.2K",
      timestamp: "3 days ago"
    },
    {
      id: 2,
      image: "https://images.unsplash.com/photo-1539109136881-3be0616acf4b?w=800&auto=format&fit=crop&q=60",
      title: "Autumn Vibes",
      likes: "3.8K",
      timestamp: "6 days ago"
    }
  ];

  // Dummy data for products
  const products = [
    {
      id: 1,
      image: "https://images.unsplash.com/photo-1576566588028-4147f3842f27?w=800&auto=format&fit=crop&q=60",
      name: "Classic White Coat",
      price: "₹4,890",
      brand: "Louis Vuitton"
    },
    {
      id: 2,
      image: "https://images.unsplash.com/photo-1591047139829-d91aecb6caea?w=800&auto=format&fit=crop&q=60",
      name: "Leather Jacket",
      price: "₹5,990",
      brand: "Gucci"
    }
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'scrolls':
        return (
          <div className="grid grid-cols-3 gap-1">
            {scrolls.map(scroll => (
              <div key={scroll.id} className="relative aspect-[3/4]">
                <img 
                  src={scroll.thumbnail} 
                  alt={`Scroll ${scroll.id}`}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/80 via-black/50 to-transparent rounded-b-lg">
                  <div className="flex items-center justify-between text-white text-xs">
                    <span>{scroll.views}</span>
                    <span>{scroll.likes}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );

      case 'styles':
        return (
          <div className="grid grid-cols-2 gap-3">
            {styles.map(style => (
              <div key={style.id} className="rounded-xl overflow-hidden border border-white/10">
                <div className="aspect-square relative">
                  <img 
                    src={style.image} 
                    alt={style.title}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="p-3">
                  <h3 className="text-white text-sm font-medium mb-1">{style.title}</h3>
                  <div className="flex items-center justify-between">
                    <span className="text-white/60 text-xs">{style.likes}</span>
                    <span className="text-white/60 text-xs">{style.timestamp}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );

      case 'products':
        return (
          <div className="space-y-4">
            {products.map(product => (
              <div key={product.id} className="flex gap-4 bg-white/5 rounded-xl p-3 border border-white/10">
                <img 
                  src={product.image} 
                  alt={product.name}
                  className="w-24 h-24 object-cover rounded-lg"
                />
                <div className="flex-1">
                  <h3 className="text-white font-medium mb-1">{product.name}</h3>
                  <p className="text-white/60 text-sm mb-2">{product.brand}</p>
                  <p className="text-white font-semibold">{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="relative min-h-screen bg-black text-white">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-black/90 backdrop-blur-xl border-b border-white/10">
        <div className="flex items-center px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-semibold ml-2">Saved</h1>
        </div>

        {/* Tabs */}
        <div className="flex px-4 pb-3">
          {['scrolls', 'styles', 'products'].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`flex-1 py-2 text-sm font-medium capitalize transition-colors ${
                activeTab === tab 
                  ? 'text-white border-b-2 border-white' 
                  : 'text-white/50 hover:text-white/80'
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="p-4">
        {renderContent()}
      </div>

      {/* Coming Soon Overlay */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="absolute inset-0 flex items-center justify-center z-50"
      >
        {/* Enhanced backdrop with black gradient - reduced blur */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#000000]/98 via-[#0C0C0C]/95 to-[#000000]/98 backdrop-blur-[8px]">
          <div className="absolute inset-0 bg-gradient-to-tr from-[#1C1C1E]/10 via-transparent to-[#2C2C2E]/10 opacity-30" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[500px] h-[500px] rounded-full bg-[#1C1C1E]/10 blur-[80px]" />
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[500px] h-[500px] rounded-full bg-[#2C2C2E]/10 blur-[80px]" />
        </div>

        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.1, type: "spring", stiffness: 100 }}
          className="relative text-center px-8 max-w-[320px]"
        >
          {/* Enhanced text content */}
          <h2 className="text-[32px] font-bold mb-4 bg-gradient-to-b from-white via-white/90 to-white/70 
            bg-clip-text text-transparent"
          >
            Coming Soon
          </h2>
          <p className="text-[17px] text-white/70 font-medium leading-relaxed mb-8">
            We're working on something amazing. Stay tuned for updates.
          </p>

          {/* Back Button with black shades */}
          <button
            onClick={() => navigate(-1)}
            className="px-8 py-3 rounded-2xl bg-[#1C1C1E]/60 backdrop-blur-sm
              border border-[#2C2C2E]/20 shadow-lg shadow-black/40
              hover:bg-[#2C2C2E]/60 active:scale-[0.98] transition-all duration-300
              text-white/90 font-medium text-[15px]
              relative overflow-hidden group"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-[#2C2C2E]/30 via-[#3C3C3E]/30 to-[#2C2C2E]/30 
              opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <span className="relative">Go Back</span>
          </button>

          {/* Animated rings with black shades */}
          <div className="absolute -inset-10 -z-10 pointer-events-none">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[400px] h-[400px] 
              rounded-full border border-[#2C2C2E]/[0.03] animate-pulse" 
            />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[600px] h-[600px] 
              rounded-full border border-[#2C2C2E]/[0.02] animate-pulse delay-300" 
            />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Saved; 