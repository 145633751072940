// LikeButton.js
import React, { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';
import { getAuth } from 'firebase/auth';

const LikeButton = ({ itemId, initialLikeCount = 0, initialIsLiked = false, onLikeToggle,mainpage }) => {
  const [isLiked, setIsLiked] = useState(initialIsLiked);
  const [likeCount, setLikeCount] = useState(initialLikeCount);
  const [isAnimating, setIsAnimating] = useState(false);
  const auth = getAuth();

  const toggleLike = async (e) => {
    e.stopPropagation(); // Prevent event bubbling
    
    if (!auth.currentUser) {
      // Handle unauthorized user case
      alert('Please login to like this item');
      return;
    }

    setIsAnimating(true);
    const scrollId=itemId;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/userdetail/toggleLikeScroll/${scrollId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: auth.currentUser.uid
        })
      });

      if (!response.ok) throw new Error('Failed to toggle like');
      
      const data = await response.json();
      
      setIsLiked(data.action === 'liked');
      setLikeCount(prev => data.action === 'liked' ? prev + 1 : prev - 1);
      
      // Call the parent callback if provided
      if (onLikeToggle) {
        onLikeToggle(data.action === 'liked');
      }

    } catch (error) {
      console.error('Error toggling like:', error);
      // Revert UI state on error
      setIsLiked(prev => !prev);
      setLikeCount(prev => isLiked ? prev - 1 : prev + 1);
    }

    // Reset animation after a delay
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <button 
      onClick={toggleLike}
      className={`flex ${mainpage?'flex-col':'flex-row'} items-center group `}
    >
      <div className={`w-10 h-10 rounded-full bg-black/40 backdrop-blur-sm flex items-center justify-center transition-transform duration-300 ${isAnimating ? 'scale-110' : ''}`}>
        <Heart 
          className={`w-6 h-6 transition-all duration-300 ${
            isLiked 
              ? 'text-red-500 fill-red-500 scale-110' 
              : 'text-white group-hover:scale-110'
          }`} 
        />
      </div>
      <span className="text-white text-xs mt-1">
        {likeCount >= 1000 
          ? `${(likeCount / 1000).toFixed(1)}k` 
          : likeCount}
      </span>
    </button>
  );
};

export default LikeButton;