import { Link } from 'lucide-react';
import React, { useEffect,useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';

const ProductSection = () => {
    const [imagedata,setImagedata]=useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalProducts, setTotalProducts] = useState(0);
    const Navigate=useNavigate();
    const {username}=useParams();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const fetchproductimage = async () => {
        try {
            const serllerId=username;
            console.log(serllerId)
            const url = `${backendURL}/userdetail/product_details/specificuser/${serllerId}`;
            const response = await fetch(url);
            if(!response.ok) {
                throw new Error('Response status');
            }
            const imagedatas = await response.json();
            console.log(imagedatas.productdetails);
            const arraofdata = imagedatas.productdetails;
            setImagedata(prevImages => {
                const uniqueImages = [...prevImages, ...arraofdata].filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                );
                return uniqueImages;
            });
            
            if (page === 1) {
                setTotalProducts(imagedatas.total);
            }
            setPage(prevPage => prevPage + 1);
        } catch(error) {
            console.log("error occured:", error);
            setHasMore(false);
        }
    }

    useEffect(() => {
        fetchproductimage();
    }, []);

    return (
        <div className="min-h-screen pb-20 ">
            <InfiniteScroll
                dataLength={imagedata.length}
                next={fetchproductimage}
                hasMore={hasMore}
                loader={<h4 className="text-white text-center my-4">Loading more...</h4>}
                endMessage={
                    <p className="text-white text-center my-4">
                        No more images to load
                    </p>
                }
            >
                <div className="grid grid-cols-2 gap-2 p-2">
                  {imagedata.map((product) => (
                        <div 
                            key={product.id} 
                            className="flex flex-col bg-[#1c1c1e] rounded-lg overflow-hidden hover:opacity-90 transition"
                        >
                            <div className="aspect-square bg-[#2c2c2e]" onClick={()=>{Navigate(`/ProductPage/${product.id}`)}}>
                                <img 
                                    src={product.images[0]} 
                                    alt={product.title} 
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="p-2">
                                <div className="text-sm text-white truncate">{product.name}</div>
                                <div className="text-[#fe2c55] font-medium">${product.price}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    )
}

export default ProductSection
