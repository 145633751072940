import React, { useState, useRef, useEffect } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { doc, getDoc, updateDoc, onSnapshot, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// import { db, auth } from '../config/firebase';


const LiveStreamViewer = ({ streamId }) => {
  const containerRef = useRef(null);
  const [error, setError] = useState(null);
  const [stream, setStream] = useState(null);
  const [canView, setCanView] = useState(false);
  const db=getFirestore();
  const auth=getAuth();

  useEffect(() => {
    if (!streamId) return;

    const streamRef = doc(db, 'streams', streamId);
    const unsubscribe = onSnapshot(streamRef, (doc) => {
      if (doc.exists()) {
        setStream({ id: doc.id, ...doc.data() });
      }
    });

    return () => unsubscribe();
  }, [streamId]);

  const joinStream = async () => {
    try {
      if (!stream) throw new Error('Stream not found');

      if (stream.isPrivate) {
        const followDoc = await getDoc(
          doc(db, 'follows', `${stream.hostId}_${auth.currentUser.uid}`)
        );
        if (!followDoc.exists()) {
          throw new Error('This is a private stream for followers only');
        }
      }

      const response = await fetch('/api/stream/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await auth.currentUser.getIdToken()}`
        },
        body: JSON.stringify({
          streamId: stream.zegoStreamId,
          role: 'viewer',
          userId: auth.currentUser.uid
        })
      });

      const { token } = await response.json();

      const zp = ZegoUIKitPrebuilt.create(token);
      await zp.joinRoom({
        container: containerRef.current,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role: ZegoUIKitPrebuilt.Audience,
          }
        },
        showPreJoinView: false
      });

      // Add viewer to stream
      await updateDoc(doc(db, 'streams', streamId), {
        viewerCount: stream.viewerCount + 1
      });

      setCanView(true);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900">
      {!canView ? (
        <div className="max-w-md mx-auto p-6">
          <h2 className="text-2xl text-white mb-4">
            {stream?.hostName}'s Stream
          </h2>
          <button
            onClick={joinStream}
            className="w-full bg-blue-600 text-white p-3 rounded-lg"
          >
            Join Stream
          </button>
          {error && (
            <div className="mt-4 text-red-500">{error}</div>
          )}
        </div>
      ) : (
        <div ref={containerRef} className="w-full h-screen" />
      )}
    </div>
  );
};
export default LiveStreamViewer;