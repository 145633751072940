import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ShoppingBag, 
  ChevronDown,
  Wallet2, // For instant earnings
  HandshakeIcon, // For trust building
  Users 
} from 'lucide-react';
import { motion } from 'framer-motion';
import Navbar from './Navbar';

const ScrollLives = () => {
  const navigate = useNavigate();
  const slides = Array(10).fill({ empty: true });
  const [badgeColor, setBadgeColor] = React.useState("#FF2D55"); // TikTok Red

  React.useEffect(() => {
    const colors = ["#FF2D55", "#0A84FF", "#FFD60A"]; // Red, Blue, Yellow
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % colors.length;
      setBadgeColor(colors[currentIndex]);
    }, 2000); // Change color every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="fixed inset-0 bg-black">
      <div className="relative h-[100dvh] max-w-[400px] mx-auto">
        {/* Ambient Background */}
        <div className="absolute inset-0 overflow-hidden pointer-events-none">
          <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
          <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[500px] h-[500px] 
            rounded-full bg-[#FF2D55]/10 blur-[120px] opacity-20" />
          <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-[500px] h-[500px] 
            rounded-full bg-[#0A84FF]/10 blur-[120px] opacity-20" />
        </div>

        {/* Main Content */}
        <div className="relative h-full flex flex-col">
          {/* Header - Apple-style transparent */}
          <div className="px-4 py-3 bg-transparent z-50">
            <div className="flex items-center justify-center gap-2.5">
              <div className="w-1.5 h-1.5 rounded-full bg-[#FF2D55] animate-pulse 
                shadow-lg shadow-[#FF2D55]/25" 
              />
              <span className="text-[13px] font-medium text-white/80 tracking-wide 
                bg-gradient-to-b from-white to-white/80 bg-clip-text text-transparent"
              >
                Live
              </span>
            </div>
          </div>

          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto snap-y snap-mandatory scrollbar-hide">
            {slides.map((_, index) => (
              <div key={index} 
                className="h-[calc(100dvh-110px)] w-full snap-start snap-always 
                  flex items-center justify-center relative px-4"
              >
                {/* Card Container */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="w-full max-w-[360px] relative"
                >
                  {/* Status Badge - Color changing */}
                  <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                    <motion.div 
                      animate={{
                        backgroundColor: badgeColor,
                        boxShadow: `0 8px 20px -6px ${badgeColor}80` // 80 for 50% opacity
                      }}
                      transition={{ duration: 0.5 }}
                      className="px-5 py-2.5 rounded-full
                        flex items-center justify-center gap-2 border border-white/10"
                    >
                      <span className="w-2 h-2 rounded-full bg-white animate-pulse" />
                      <span className="text-sm font-semibold text-white tracking-wide 
                        [text-shadow:0_2px_10px_rgba(0,0,0,0.15)]"
                      >
                        Nobody's Live
                      </span>
                    </motion.div>
                  </div>

                  {/* Main Card */}
                  <div className="relative rounded-3xl bg-[#1C1C1E]/40 backdrop-blur-xl 
                    border border-white/[0.08] overflow-hidden shadow-xl mt-6"
                  >
                    {/* Card Content */}
                    <div className="p-7 pt-8 space-y-7">
                      {/* Header */}
                      <div className="text-center space-y-2">
                        <h2 className="text-[28px] font-bold bg-gradient-to-b from-white via-white/90 
                          to-white/70 bg-clip-text text-transparent leading-tight"
                        >
                          Be the First to Go Live
                        </h2>
                        <p className="text-sm text-white/60">
                          Start streaming and reach global audience
                        </p>
                      </div>

                      {/* Key Points */}
                      <div className="space-y-3">
                        {[
                          {
                            icon: ShoppingBag,
                            title: "Quick Setup",
                            desc: "Add products & start streaming instantly",
                            color: "#FF2D55"
                          },
                          {
                            icon: Wallet2,
                            title: "Instant Earnings",
                            desc: "Get paid for every sale you generate",
                            color: "#FF2D55"
                          },
                          {
                            icon: HandshakeIcon,
                            title: "Build Trust",
                            desc: "Connect with your audience in real-time",
                            color: "#FF2D55"
                          }
                        ].map((point, i) => (
                          <div key={i} 
                            className="flex items-start gap-3 p-3 rounded-xl 
                              bg-white/[0.08] hover:bg-white/[0.12] transition-all
                              backdrop-blur-sm border border-white/[0.08]"
                          >
                            <div className={`p-2 rounded-full bg-[#FF2D55]/10`}>
                              <point.icon className={`w-4 h-4 text-[#FF2D55]`} />
                            </div>
                            <div>
                              <p className="font-medium text-white/90">{point.title}</p>
                              <p className="text-xs text-white/60 mt-0.5">{point.desc}</p>
                            </div>
                          </div>
                        ))}
                      </div>

                      {/* Action Button */}
                      <button
                        onClick={() => navigate(`/`)}
                        className="w-full py-4 rounded-xl bg-gradient-to-r from-[#FF2D55] to-[#FF375F]
                          text-white font-medium text-[15px] hover:opacity-90 active:scale-[0.98] 
                          transition-all duration-300 flex items-center justify-center gap-2
                          shadow-lg shadow-[#FF2D55]/25"
                      >
                        <div className="w-1.5 h-1.5 rounded-full bg-white animate-pulse" />
                        Go Live Now
                      </button>
                    </div>
                  </div>

                  {/* Centered Scroll Indicator */}
                  {index === 0 && (
                    <motion.div 
                      className="absolute left-1/2 -translate-x-1/2 bottom-0
                        flex flex-col items-center gap-2"
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ 
                        duration: 0.6,
                        repeat: Infinity,
                        repeatType: "reverse"
                      }}
                    >
                      <span className="text-xs font-medium text-white/50">Scroll to explore</span>
                      <ChevronDown className="w-5 h-5 text-white/50" />
                    </motion.div>
                  )}
                </motion.div>
              </div>
            ))}
          </div>

          {/* Navigation */}
          <div className="relative">
            <Navbar transparent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollLives; 