import React, { useState, useRef } from 'react';
import { X, Plus, ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import {  doc, getDoc } from 'firebase/firestore';
// import { query } from 'express';

// Import the brands and products data from NewScrollPage
// const brands = [
//   { name: 'Louis Vuitton', handle: '@someone', followers: '403K' },
//   { name: 'Gucci', handle: '@someone', followers: '350K' },
//   { name: 'Chanel', handle: '@someone', followers: '300K' },
//   { name: 'Prada', handle: '@someone', followers: '280K' },
//   { name: 'Dior', handle: '@someone', followers: '450K' },
// ];


// const products = [
//   { 
//     name: 'LV Monogram Bag', 
//     brand: 'Louis Vuitton', 
//     price: '$3,500', 
//     image: 'https://images.unsplash.com/photo-1608248597279-f99d160bfcbc?ixlib=rb-4.0.3&auto=format&fit=crop&w=320&h=320&q=80' 
//   },
//   { 
//     name: 'Petite Malle Bag', 
//     brand: 'Louis Vuitton', 
//     price: '$4,850', 
//     image: 'https://images.unsplash.com/photo-1608248597279-f99d160bfcbc?ixlib=rb-4.0.3&auto=format&fit=crop&w=320&h=320&q=80' 
//   },
//   { 
//     name: 'GG Marmont Bag', 
//     brand: 'Gucci', 
//     price: '$2,590', 
//     image: 'https://images.unsplash.com/photo-1608248597279-f99d160bfcbc?ixlib=rb-4.0.3&auto=format&fit=crop&w=320&h=320&q=80' 
//   },
//   { 
//     name: 'Dionysus Bag', 
//     brand: 'Gucci', 
//     price: '$2,890', 
//     image: 'https://images.unsplash.com/photo-1608248597279-f99d160bfcbc?ixlib=rb-4.0.3&auto=format&fit=crop&w=320&h=320&q=80' 
//   },
//   // Add more products as needed...
// ];

const UploadStyles = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const Navigate = useNavigate();
  const [showTagBrand, setShowTagBrand] = useState(false);
  const [showTagProduct, setShowTagProduct] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const [selectedBrandForProducts, setSelectedBrandForProducts] = useState(null);
  const [description, setDescription] = useState('');
  const [brands,setBrands]=useState([]);
  const [products,setProducts]=useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const firebase=useFirebase();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  // firebase.checkIfAdminPage();
  // console.log(firebase.adminRef)
  const fetchsellerdata = async () => {
    try{
      const response = await fetch(`${backendURL}/Sellerdetail`);
      if (!response.ok) throw new Error("Failed to fetch SellerDetail");
      const maindata = await response.json();
      const data=maindata;
      // console.log(maindata.id);

      setBrands(data.sellerdetails);
    }catch(error){
      console.error("Error fetching videos:", error);
    }
  };
  const fetchproductdata = async () => {
    try {
      const response = await fetch(`${backendURL}/userdetail/Product_details/all`);
      if (!response.ok) throw new Error("Failed to fetch ProductDetail");
      const maindata = await response.json();
      const data=maindata;
      console.log(data);
      setProducts(data.productdetails);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };
  const fetchProductDataOfBrand = async (bannerid) => {
    try {
        // First get the seller's document which contains the product IDs array
        const sellerDoc = await getDoc(doc(firebase.firestore, "UsersDetail", bannerid));
        const sellerData = sellerDoc.data();
        
        // If there are no products, return empty array
        if (!sellerData.products || sellerData.products.length === 0) {
            return [];
        }
        
        // Get all products using their IDs from the products array
        const productRefs = sellerData.products.map(productId => 
            doc(firebase.firestore, "Products", productId)
        );
        
        // Fetch all products in parallel
        const productDocs = await Promise.all(
            productRefs.map(ref => getDoc(ref))
        );
        
        // Convert to array of product data with IDs
        const products = productDocs.map(doc => ({
            id: doc.id,
            ...doc.data()
        })).filter(product => product); // Filter out any null products
        console.log(products);
        return products;
    } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
    }
};

// Usage in component:
// const products = await fetchProductDataOfBrand(sellerId);
// setProducts(products);
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const handleImageUpload = async (event) => {
  const files = Array.from(event.target.files);
  const newImages = await Promise.all(files.map(async (file) => ({
    url: URL.createObjectURL(file),
    base64: await fileToBase64(file),
    file: file
  })));
  setImages(prev => [...prev, ...newImages]);
  if (selectedImage === null) {
    setSelectedImage(0);
  }
};

  const handleRemoveImage = (indexToRemove) => {
    setImages(prev => {
      const newImages = prev.filter((_, index) => index !== indexToRemove);
      if (selectedImage === indexToRemove) {
        setSelectedImage(newImages.length > 0 ? 0 : null);
      } else if (selectedImage > indexToRemove) {
        setSelectedImage(selectedImage - 1);
      }
      return newImages;
    });
  };

  const handleBrandSelect = async (brand) => {
    setSelectedBrands(prev => {
      const exists = prev.find(b => b.name === brand.name);
      if (exists) {
        return prev.filter(b => b.name !== brand.name);
      }
      // When selecting a brand, also fetch its products
      fetchProductDataOfBrand(brand.id)  // Assuming brand.id exists
        .then(products => {
          setProducts(products); // Store the brand's products
        });
      return [...prev, brand];
    });
  };
  // const navigate=useNavigate();
  // const handleNext = () => {
  //   // navigate('/NewScrollPage');
  // };
  const handleProductSelect = (product) => {
    setSelectedProducts(prev => {
      const exists = prev.find(p => p.id === product.id);
      if (exists) {
        setSelectedProductIds(prevIds => prevIds.filter(id => id !== product.id));
        return prev.filter(p => p.id !== product.id);
      }
      console.log(product.id);
      setSelectedProductIds(prevIds => [...prevIds, product.id]);
      return [...prev, product];
    });
  };
  

  const filteredBrands = brands.filter((brand) => {
    // Guard against undefined/null brand objects
    if (!brand || !brand.name || !brand.username) return false;
    
    return (
      brand.name.toLowerCase().includes(brandSearchTerm.toLowerCase()) ||
      brand.username.toLowerCase().includes(brandSearchTerm.toLowerCase())
    );
  });


  const handleDoneTagging = (type) => {
    if (type === 'brand') {
      setShowTagBrand(false);
    } else {
      setShowTagProduct(false);
    }
  };

  const getTagButtonLabel = (type) => {
    if (type === 'brand') {
      //  fetchVideos();
      return selectedBrands.length > 0 
        ? `Tag Brand (${selectedBrands.length})` 
        : 'Tag Brand';
    }
    return selectedProducts.length > 0 
      ? `Tag Product (${selectedProducts.length})` 
      : 'Tag Product';
  };
  //
  const handleUpload = async () => {
    try {
      if (images.length === 0) {
        alert("Please select at least one image");
        return;
      }

      // Create JSON payload
      const payload = {
        adminRef: firebase.adminRef,
        description: description,
        productIds: selectedProductIds,
        images: images.map(img => ({
          base64: img.base64,
          filename: img.file.name,
          contentType: img.file.type
        }))
      };

      // Make the upload request with JSON
      const response = await fetch(`${backendURL}/userdetail/upload/style`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Upload failed');
      }

      const result = await response.json();
      console.log('Upload successful:', result);

      // Reset form state
      setImages([]);
      setSelectedImage(null);
      setDescription('');
      setSelectedProducts([]);
      setSelectedProductIds([]);
      
      alert('Style uploaded successfully!');
      Navigate(-1);

    } catch (error) {
      console.error('Upload error:', error);
      alert(`Failed to upload style: ${error.message}`);
    }
  };


  return (
    <div className="fixed inset-0 bg-black text-white z-50">
      {/* Header */}
      <div className="flex items-center justify-between p-4 bg-black/90 backdrop-blur-lg">
        <button 
          onClick={() => Navigate(-1)}
          className="p-2 hover:bg-white/10 rounded-full transition-colors"
        >
          <ChevronLeft size={24} />
        </button>
        <div className="text-center">
          <h1 className="text-[17px] font-semibold">Upload Styles</h1>
        </div>
        <button
    onClick={handleUpload}
    disabled={images.length === 0}
    className={`py-1.5 px-4 rounded-full text-sm font-medium transition-all ${
      images.length > 0 
        ? 'bg-[#FE2C55] hover:bg-[#ff1a45] active:bg-[#e01442]' 
        : 'bg-[#2C2C2E] text-white/40'
    }`}
  >
    Next
  </button>
      </div>
      {/* Main Content */}
      <div className="p-4 h-[calc(100vh-64px)] overflow-y-auto">
        {/* Selected Image Preview */}
        <div className="mb-4 aspect-square w-full bg-[#1C1C1E] rounded-2xl overflow-hidden">
          {selectedImage !== null ? (
            <img 
              src={images[selectedImage].url} 
              alt="Selected preview"
              className="w-full h-full object-cover"
            />
          ) : (
            <div className="w-full h-full flex items-center justify-center">
              <div className="text-center text-white/60">
                <div className="w-16 h-16 rounded-full bg-[#2C2C2E] flex items-center justify-center mx-auto mb-4">
                  <Plus size={32} />
                </div>
                <p className="text-sm">Select an image to preview</p>
              </div>
            </div>
          )}
        </div>

        {/* Image Strip with Upload Button Pinned Left */}
        <div className="flex gap-2 overflow-x-auto pb-4 scrollbar-hide">
          {/* Pinned Upload Button */}
          <button
            onClick={() => fileInputRef.current?.click()}
            className="flex-shrink-0 w-20 h-20 rounded-lg bg-[#2C2C2E] 
              flex items-center justify-center hover:bg-[#3C3C3E] 
              transition-colors cursor-pointer sticky left-0"
          >
            <Plus size={24} className="text-white/60" />
          </button>

          {/* Images */}
          {images.map((image, index) => (
            <div 
              key={index}
              className={`relative flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden 
                ${selectedImage === index ? 'ring-2 ring-[#FE2C55]' : ''}`}
            >
              <img 
                src={image.url} 
                alt={`Upload ${index + 1}`}
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => setSelectedImage(index)}
              />
              <button
                onClick={() => handleRemoveImage(index)}
                className="absolute top-1 right-1 p-1 rounded-full bg-black/50 
                  hover:bg-black/70 transition-colors"
              >
                <X size={14} />
              </button>
            </div>
          ))}
        </div>

        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
        />

        {/* Description Input */}
        <div className="mt-6 mb-4">
          <label className="block text-sm font-medium text-white/80 mb-2">
            Description
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Write a description..."
            className="w-full h-24 py-3 px-4 bg-[#1C1C1E] rounded-xl 
              text-white placeholder:text-white/40 focus:ring-2 
              focus:ring-[#FE2C55] border-none transition-all resize-none"
          />
        </div>

        {/* Tag Options with Selected Items Display */}
        <div className="space-y-3">
          {/* Tag Brand Button */}
          <div className="space-y-2">
            <button 
              onClick={() => {setShowTagBrand(true);fetchsellerdata();}}
              className="w-full text-left bg-[#1C1C1E] text-white py-4 px-5 rounded-xl 
                hover:bg-[#2C2C2E] active:bg-[#3C3C3E] transition-all duration-300 
                flex items-center justify-between group"
            >
              <span className="font-medium">{getTagButtonLabel('brand')}</span>
              <svg className="w-5 h-5 text-white/40 group-hover:text-white/60 transition-colors" 
                fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>
            
            {/* Selected Brands Display */}
            {selectedBrands.length > 0 && (
              <div className="flex flex-wrap gap-2 px-1">
                {selectedBrands.map((brand) => (
                  <div key={brand.name} 
                    className="flex items-center gap-1 bg-[#2C2C2E] rounded-full 
                      pl-3 pr-2 py-1.5 text-sm"
                  >
                    <span>{brand.name}</span>
                    <button
                      onClick={() => handleBrandSelect(brand)}
                      className="p-0.5 hover:bg-[#3C3C3E] rounded-full transition-colors"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Tag Product Button */}
          <div className="space-y-2">
            <button 
              onClick={() => {setShowTagProduct(true);fetchproductdata()}}
              className="w-full text-left bg-[#1C1C1E] text-white py-4 px-5 rounded-xl 
                hover:bg-[#2C2C2E] active:bg-[#3C3C3E] transition-all duration-300 
                flex items-center justify-between group"
            >
              <span className="font-medium">{getTagButtonLabel('product')}</span>
              <svg className="w-5 h-5 text-white/40 group-hover:text-white/60 transition-colors" 
                fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
              </svg>
            </button>

            {/* Selected Products Display */}
            {selectedProducts.length > 0 && (
              <div className="flex flex-wrap gap-2 px-1">
                {selectedProducts.map((product) => (
                  <div key={product.name} 
                    className="flex items-center gap-1 bg-[#2C2C2E] rounded-full 
                      pl-3 pr-2 py-1.5 text-sm"
                  >
                    <span>{product.name}</span>
                    <button
                      onClick={() => {
                        setSelectedProducts(prev => 
                          prev.filter(p => p.name !== product.name)
                        )
                      }}
                      className="p-0.5 hover:bg-[#3C3C3E] rounded-full transition-colors"
                    >
                      <X size={14} />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Tag Brand Modal */}
      {showTagBrand && (
        <div className="fixed inset-0 bg-black/75 z-50">
          <div className="absolute inset-x-0 bottom-0 bg-[#1C1C1E] rounded-t-2xl 
            max-h-[85vh] overflow-hidden">
            {/* Header with Add button on right */}
            <div className="p-4 border-b border-white/[0.08]">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-medium">Tag Brand</h2>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleDoneTagging('brand')}
                    className="py-1.5 px-4 bg-[#FE2C55] text-white text-sm font-medium 
                      rounded-full hover:bg-[#ff1a45] active:bg-[#e01442] transition-colors"
                  >
                    Add
                  </button>
                  <button 
                    onClick={() => setShowTagBrand(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <X size={20} />
                  </button>
                </div>
              </div>
              
              {/* Search */}
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search brands"
                  value={brandSearchTerm}
                  onChange={(e) => setBrandSearchTerm(e.target.value)}
                  className="w-full py-3 px-4 pl-11 bg-[#2C2C2E] rounded-xl 
                    text-white placeholder:text-white/40 focus:ring-2 
                    focus:ring-[#FE2C55] border-none transition-all"
                />
                <svg className="w-5 h-5 text-white/40 absolute left-4 top-1/2 -translate-y-1/2" 
                  fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>

            {/* Brand List */}
            <div className="overflow-y-auto max-h-[60vh] p-4 space-y-2">
              {filteredBrands.map((brand) => (
                <button
                  key={brand.name}
                  onClick={() => handleBrandSelect(brand)}
                  className={`w-full flex items-center justify-between p-4 
                    rounded-xl transition-colors ${
                    selectedBrands.find(b => b.name === brand.name)
                      ? 'bg-[#FE2C55]/10 hover:bg-[#FE2C55]/20'
                      : 'bg-[#2C2C2E] hover:bg-[#3C3C3E]'
                  }`}
                >
                  <div className="flex items-center space-x-3">
                    <div className="w-12 h-12 rounded-full bg-[#3C3C3E] overflow-hidden">
                      {/* Brand image placeholder */}
                      <img src={brand.profileimage} alt="profileimage" />
                    </div>
                    <div>
                      <p className="font-medium">{brand.name}</p>
                      <p className="text-sm text-white/60">{brand.username}</p>
                    </div>
                  </div>
                  {selectedBrands.find(b => b.name === brand.name) && (
                    <svg className="w-5 h-5 text-[#FE2C55]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  )}
                </button>
              ))}
            </div>

            {/* Done Button */}
            <div className="p-4 border-t border-white/[0.08]">
              <button
                onClick={() => handleDoneTagging('brand')}
                className="w-full py-3 bg-[#FE2C55] text-white rounded-xl font-medium
                  hover:bg-[#ff1a45] active:bg-[#e01442] transition-colors"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Tag Product Modal */}
      {showTagProduct && (
        <div className="fixed inset-0 bg-black/75 z-50">
          <div className="absolute inset-x-0 bottom-0 bg-[#1C1C1E] rounded-t-2xl 
            max-h-[85vh] overflow-hidden">
            {/* Header with Add button on right */}
            <div className="p-4 border-b border-white/[0.08]">
              <div className="flex items-center justify-between mb-4">
                <h2 className="text-xl font-medium">Tag Product</h2>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleDoneTagging('product')}
                    className="py-1.5 px-4 bg-[#FE2C55] text-white text-sm font-medium 
                      rounded-full hover:bg-[#ff1a45] active:bg-[#e01442] transition-colors"
                  >
                    Add
                  </button>
                  <button 
                    onClick={() => setShowTagProduct(false)}
                    className="p-2 hover:bg-white/10 rounded-full transition-colors"
                  >
                    <X size={20} />
                  </button>
                </div>
              </div>

              {/* Brand Pills */}
              <div className="flex gap-2 overflow-x-auto pb-4 -mx-4 px-4">
                {selectedBrands.map((brand) => (  // Changed from brands to selectedBrands
                  <button
                    key={brand.name}
                    onClick={() => {
                      setSelectedBrandForProducts(brand);
                      fetchProductDataOfBrand(brand.id)  // Fetch products when brand pill is clicked
                        .then(products => {
                          setProducts(products);
                        });
                    }}
                    className={`flex-shrink-0 px-4 py-2 rounded-full border 
        transition-colors ${selectedBrandForProducts?.name === brand.name
                        ? 'bg-[#FE2C55] border-[#FE2C55] text-white'
                        : 'border-white/20 hover:border-white/40'
                      }`}
                  >
                    <span className="text-sm font-medium">{brand.name}</span>
                  </button>
                ))}
              </div>
              {/* Product Search */}
              <div className="relative mt-4">
                <input
                  type="text"
                  placeholder={`Search ${selectedBrandForProducts?.name || 'all'} products`}
                  className="w-full py-3 px-4 pl-11 bg-[#2C2C2E] rounded-xl 
                    text-white placeholder:text-white/40 focus:ring-2 
                    focus:ring-[#FE2C55] border-none transition-all"
                />
                <svg className="w-5 h-5 text-white/40 absolute left-4 top-1/2 -translate-y-1/2"
                  fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>
            </div>

            {/* Product List */}
            <div className="overflow-y-auto max-h-[60vh] p-4 space-y-2">
              {/* Filter products based on selected brand */}
              {products.map((product) => (
  <button
    key={product.id}  // Changed from index to product.id
    onClick={() => handleProductSelect(product)}
    className={`w-full flex items-center justify-between p-4 
      rounded-xl transition-colors ${
      selectedProducts.find(p => p.id === product.id)  // Changed from name to id
        ? 'bg-[#FE2C55]/10 hover:bg-[#FE2C55]/20'
        : 'bg-[#2C2C2E] hover:bg-[#3C3C3E]'
    }`}
  
                  >
                    <div className="flex items-center space-x-3">
                      <div className="w-16 h-16 rounded-xl overflow-hidden bg-[#3C3C3E]">
                        <img 
                          src={product.image}
                          alt={product.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div>
                        <p className="font-medium">{product.name}</p>
                        <p className="text-sm text-white/60">{product.brand}</p>
                        <p className="text-sm font-medium text-[#FE2C55]">{product.price}</p>
                      </div>
                    </div>
                    {selectedProducts.find(p => p.name === product.name) && (
                      <svg className="w-5 h-5 text-[#FE2C55]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                    )}
                  </button>
                ))}
            </div>

            {/* Done Button */}
            <div className="p-4 border-t border-white/[0.08]">
              <button
                onClick={() => handleDoneTagging('product')}
                className="w-full py-3 bg-[#FE2C55] text-white rounded-xl font-medium
                  hover:bg-[#ff1a45] active:bg-[#e01442] transition-colors"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadStyles; 