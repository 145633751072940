import React, { useState } from 'react';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import { Link, useNavigate } from 'react-router-dom';
import ScrollSignup from './ScrollSignup';
import scrollLogo from '../scrollFlogo.png';
import { Alert, Snackbar } from '@mui/material';

const ScrollLogin = () => {
  const [formdiv, setFormdiv] = useState(false);
  const [formdata, setformdata] = useState({
    email: "",
    password: "",
  });
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'error'
  });

  // Handle toast close
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, open: false });
  };

  // Show toast message
  const showToast = (message, severity = 'error') => {
    setToast({
      open: true,
      message,
      severity
    });
  };
  const firebase = useFirebase();
  const Navigate = useNavigate();

  const handlesigninwithuser = (e) => {
    e.preventDefault();
    if (!formdata.email || !formdata.password) {
      showToast("Please fill in all fields"); // Using the showToast function instead of setToast
      return;
    }
    firebase.handlesignin(formdata.email, formdata.password);
    Navigate('/');
  }

  const handleChange = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value });
  };

  return (
    <>
      {firebase.isSignup ? (<ScrollSignup/>) : (
        <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black flex flex-col items-center px-6 py-12">
          <Snackbar
            open={toast.open}
            autoHideDuration={3000}
            onClose={handleCloseToast}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert 
              onClose={handleCloseToast} 
              severity={toast.severity}
              variant="filled"
              sx={{ width: '100%' }}
            >
              {toast.message}
            </Alert>
          </Snackbar>
          <div className="w-full max-w-md">
            {/* Logo and Header Section */}
            <div className="mt-8 mb-12 flex flex-col items-center">
              <div className="text-white mb-8">
                <img 
                  src={scrollLogo}
                  alt="Scroll Logo" 
                  className="w-[150px] h-auto transform hover:scale-105 transition-transform"
                />
              </div>
              <h1 className="text-white text-4xl font-medium tracking-tight mb-3">Welcome back</h1>
              <p className="text-gray-400 text-center text-base mb-8">
                Sign in to continue your shopping experience
              </p>
            </div>

            {/* Login Form Section */}
            <div className='space-y-6 w-full'>
              {formdiv ? (
                <form className='w-full space-y-4'>
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">Email</label>
                    <input 
                      type="email" 
                      value={formdata.email}
                      className='w-full px-4 py-3 rounded-xl border border-gray-800 bg-black/40 text-white focus:outline-none focus:border-gray-600 focus:ring-1 focus:ring-gray-600 transition-colors backdrop-blur-xl'
                      name='email'
                      placeholder='Enter your email'
                      onChange={handleChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="block text-sm font-medium text-gray-300">Password</label>
                    <input 
                      type="password"
                      value={formdata.password}
                      className='w-full px-4 py-3 rounded-xl border border-gray-800 bg-black/40 text-white focus:outline-none focus:border-gray-600 focus:ring-1 focus:ring-gray-600 transition-colors backdrop-blur-xl'
                      name='password'
                      placeholder='Enter your password'
                      onChange={handleChange}
                    />
                  </div>
                  <button 
                    className='w-full bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black text-white py-3 px-4 rounded-xl font-medium transition-all duration-300 ease-in-out shadow-lg hover:shadow-gray-800/30'
                    type="submit"
                    onClick={handlesigninwithuser}
                  >
                    Sign In
                  </button>
                </form>
              ) : (
                <button 
                  className="w-full bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black text-white py-4 px-6 rounded-xl font-medium transition-all duration-300 ease-in-out border border-gray-800 hover:border-gray-700 shadow-lg hover:shadow-gray-800/30"
                  onClick={() => setFormdiv(true)}
                >
                  <div className="flex items-center justify-center space-x-3">
                    <svg className="w-5 h-5" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                      />
                    </svg>
                    <span>Sign in with email</span>
                  </div>
                </button>
              )}

              <div className="relative my-8">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-800"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 text-gray-400 bg-gradient-to-b from-black via-gray-900 to-black">Or continue with</span>
                </div>
              </div>

              {/* Google Login Button */}
              <button className="w-full bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black text-white py-4 px-6 rounded-xl font-medium transition-all duration-300 ease-in-out border border-gray-800 hover:border-gray-700 shadow-lg hover:shadow-gray-800/30">
                <div className="flex items-center justify-center space-x-3">
                  <svg className="w-5 h-5" viewBox="0 0 24 24">
                    <path fill="#4285F4" d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"/>
                    <path fill="#34A853" d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"/>
                    <path fill="#FBBC05" d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"/>
                    <path fill="#EA4335" d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"/>
                  </svg>
                  <span>Continue with Google</span>
                </div>
              </button>

              {/* Terms Section */}
              <div className="mt-12 text-center">
                <div className="text-sm text-gray-400">
                  <p className="leading-relaxed">
                    By continuing, you agree to our{' '}
                    <a href="#" className="text-gray-200 hover:text-white transition-colors">Terms of Service</a>
                    {' '}and acknowledge our{' '}
                    <a href="#" className="text-gray-200 hover:text-white transition-colors">Privacy Policy</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Sign Up Section - Fixed at bottom */}
          <div className="fixed bottom-0 left-0 right-0 w-full bg-black/80 backdrop-blur-lg border-t border-gray-800 py-4">
            <div className="max-w-md mx-auto px-6">
              <p className="text-gray-400 text-center">
                Don't have an account?{' '}
                <button 
                  onClick={() => firebase.handlesiguprender()}
                  className="text-[#FE2C55] hover:text-[#FF375F] font-medium transition-colors"
                >
                  Sign up
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollLogin;