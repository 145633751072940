import React, { useRef, useState, useCallback } from 'react';

// Move static objects outside component
const aspectRatios = {
  '9:16': { 
    label: 'Portrait',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <rect x="7" y="4" width="10" height="16" rx="1" strokeWidth="1.5" />
        <line x1="9" y1="6" x2="15" y2="6" strokeWidth="1.5" />
        <line x1="9" y1="18" x2="15" y2="18" strokeWidth="1.5" />
      </svg>
    )
  },
  '16:9': {
    label: 'Landscape',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <rect x="4" y="6" width="16" height="12" rx="1" strokeWidth="1.5" />
        <line x1="6" y1="8" x2="6" y2="16" strokeWidth="1.5" />
        <line x1="18" y1="8" x2="18" y2="16" strokeWidth="1.5" />
      </svg>
    )
  },
  '4:3': {
    label: 'Classic',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <rect x="5" y="5" width="14" height="14" rx="1" strokeWidth="1.5" />
        <line x1="7" y1="7" x2="7" y2="17" strokeWidth="1.5" />
        <line x1="17" y1="7" x2="17" y2="17" strokeWidth="1.5" />
      </svg>
    )
  }
};

const filterNames = {
  none: 'Normal',
  grayscale: 'B&W',
  sepia: 'Sepia',
  invert: 'Invert',
  brightness: 'Bright',
  contrast: 'Contrast',
  blur: 'Blur',
  vintage: 'Vintage',
  cold: 'Cold',
  warm: 'Warm'
};

// Update filters object with SVG icons
const filters = {
  none: {
    style: 'none',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
      </svg>
    )
  },
  grayscale: {
    style: 'grayscale(100%)',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="9" strokeWidth="1.5" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 3v18M3 12h18" />
      </svg>
    )
  },
  sepia: {
    style: 'sepia(100%)',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
      </svg>
    )
  },
  vintage: {
    style: 'sepia(50%) contrast(120%) brightness(90%)',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 12a3 3 0 100 6 3 3 0 000-6z" />
      </svg>
    )
  },
  cold: {
    style: 'saturate(150%) hue-rotate(180deg)',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
      </svg>
    )
  },
  warm: {
    style: 'saturate(150%) hue-rotate(-30deg)',
    icon: (
      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="5" strokeWidth="1.5" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 1v2m0 18v2M4.22 4.22l1.42 1.42m12.72 12.72l1.42 1.42M1 12h2m18 0h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
      </svg>
    )
  }
};

function VideoPreviewPage({ videoSrc, onClose,onNext }) {
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showAspectRatioOptions, setShowAspectRatioOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedRatio, setSelectedRatio] = useState('9:16');
  const [selectedFilter, setSelectedFilter] = useState('none');
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [rotation, setRotation] = useState(0);

  // Memoize handlers
  const applyAspectRatio = useCallback((ratio) => {
    if (!videoRef.current || !containerRef.current) return;

    const video = videoRef.current;
    const container = containerRef.current;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight - 180; // Account for controls
    
    // Reset styles
    video.style.width = '';
    video.style.height = '';
    container.style.width = '';
    container.style.height = '';
    
    requestAnimationFrame(() => {
      switch (ratio) {
        case '9:16':
          if (viewportWidth * (16/9) < viewportHeight) {
            container.style.width = '100%';
            video.style.width = '100%';
            video.style.height = 'auto';
          } else {
            container.style.height = '100%';
            video.style.height = '100%';
            video.style.width = 'auto';
          }
          break;
        case '16:9':
          container.style.width = '100%';
          video.style.width = '100%';
          video.style.aspectRatio = '16/9';
          break;
        case '4:3':
          if (viewportWidth * (3/4) < viewportHeight) {
            container.style.width = '100%';
            video.style.width = '100%';
            video.style.height = 'auto';
          } else {
            container.style.height = '100%';
            video.style.height = '100%';
            video.style.width = 'auto';
          }
          break;
        default:
          break;
      }
    });
  }, []);

  const handleAspectRatioChange = useCallback((ratio) => {
    setSelectedRatio(ratio);
    setShowAspectRatioOptions(false);
    applyAspectRatio(ratio);
  }, [applyAspectRatio]);

  const togglePlayPause = useCallback(() => {
    if (!videoRef.current) return;
    
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  const handleFilterChange = useCallback((filter) => {
    setSelectedFilter(filter);
    setShowFilterOptions(false);
  }, []);

  const handleDiscard = useCallback(() => {
    if (videoRef.current && isPlaying) {
      videoRef.current.pause();
    }
    if (videoSrc) {
      URL.revokeObjectURL(videoSrc);
    }
    onClose();
  }, [videoSrc, isPlaying, onClose]);

  // Format time to MM:SS
  const formatTime = useCallback((time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }, []);

  // Handle video time update
  const handleTimeUpdate = useCallback(() => {
    if (!isDragging && videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  }, [isDragging]);

  // Handle video metadata load
  const handleLoadedMetadata = useCallback(() => {
    if (videoRef.current) {
      setDuration(videoRef.current.duration);
      applyAspectRatio(selectedRatio);
    }
  }, [selectedRatio, applyAspectRatio]);

  // Handle seek bar change
  const handleSeekChange = useCallback((e) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
    }
  }, []);

  // Handle seek bar interaction
  const handleSeekStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleSeekEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  // Add rotate handler
  const handleRotate = useCallback(() => {
    setRotation((prev) => (prev + 90) % 360);
  }, []);

  // Return the JSX with optimized rendering
  return (
    <div 
      className="fixed inset-0 bg-[#000000] text-white z-50 safe-top safe-bottom" 
      style={{ 
        height: `calc((var(--vh, 1vh) * 100) - var(--safe-area-inset-top) - var(--safe-area-inset-bottom))`
      }}
    >
      <div className="h-full flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center p-3">
          <button onClick={handleDiscard} className="w-8 h-8 flex items-center justify-center rounded-full bg-[#1D1D1D] hover:bg-[#2D2D2D] transition-all duration-300">
            <svg className="w-5 h-5 text-white/90" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <button className="bg-[#1D1D1D] text-white/90 py-2 px-5 rounded-xl text-sm font-medium transition-all duration-300 hover:bg-[#2D2D2D]" onClick={onNext}>
            Next
          </button>
        </div>
        
        {/* Main Content */}
        <div className="flex-1 relative flex flex-col items-center justify-center mb-32 -mt-4">
          {/* Video Container */}
          <div className="relative w-full max-w-3xl px-4">
            <div ref={containerRef} className="relative w-full flex items-center justify-center max-h-[65vh]">
              <video 
                ref={videoRef} 
                src={videoSrc} 
                className="w-full h-full bg-black object-contain rounded-2xl shadow-2xl transition-all duration-300" 
                style={{ 
                  filter: filters[selectedFilter].style,
                  transform: `rotate(${rotation}deg)`
                }}
                controls={false}
                playsInline
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
              />

              {/* Filter Button */}
              <button 
                onClick={() => setShowFilterOptions(!showFilterOptions)}
                className="absolute left-6 top-1/2 -translate-y-1/2 text-white/90 p-3 rounded-xl w-12 h-12 flex items-center justify-center transition-all duration-300 hover:text-white"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
                </svg>
              </button>

              {/* Filter Options */}
              {showFilterOptions && (
                <div className="absolute left-24 top-1/2 -translate-y-1/2 backdrop-blur-xl bg-[#1D1D1D]/90 rounded-2xl p-6 w-72 shadow-2xl border border-white/10">
                  <div className="text-sm font-medium mb-4 text-white/90">Select Filter</div>
                  <div className="grid grid-cols-2 gap-4">
                    {Object.entries(filters).map(([key, { style, icon }]) => (
                      <button
                        key={key}
                        onClick={() => handleFilterChange(key)}
                        className={`flex flex-col items-center gap-2 transition-transform ${
                          selectedFilter === key ? 'scale-110' : ''
                        }`}
                      >
                        <div 
                          className={`w-12 h-12 rounded-full flex items-center justify-center ${
                            selectedFilter === key 
                              ? 'bg-[#FE2C55] ring-2 ring-white/20' 
                              : 'bg-[#353535] hover:bg-[#404040]'
                          }`}
                        >
                          {icon}
                        </div>
                        <span className="text-xs text-white/80">
                          {filterNames[key]}
                        </span>
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Bottom Controls */}
        <div className="fixed bottom-0 left-0 right-0 backdrop-blur-xl bg-[#1D1D1D]/90">
          {/* Progress Bar */}
          <div className="px-6 pt-4">
            <div className="flex items-center gap-3">
              <span className="text-xs font-medium text-white/60">{formatTime(currentTime)}</span>
              <input
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSeekChange}
                onTouchStart={handleSeekStart}
                onTouchEnd={handleSeekEnd}
                className="flex-1 h-1 bg-white/20 rounded-full appearance-none touch-manipulation 
                [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 
                [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:rounded-full 
                [&::-webkit-slider-thumb]:bg-white [&::-webkit-slider-thumb]:shadow-lg 
                [&::-webkit-slider-thumb]:border-2 [&::-webkit-slider-thumb]:border-black/10"
              />
              <span className="text-xs font-medium text-white/60">{formatTime(duration)}</span>
            </div>
          </div>

          {/* Control Buttons */}
          <div className="flex justify-around items-center p-6">
            <button 
              onClick={handleRotate}
              className="bg-[#2C2C2C] text-white p-3 rounded-full w-12 h-12 flex items-center justify-center hover:bg-[#353535]"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
              </svg>
            </button>

            <button 
              onClick={togglePlayPause} 
              className="bg-[#2C2C2C] text-white p-3 rounded-full w-12 h-12 flex items-center justify-center hover:bg-[#353535]"
            >
              {isPlaying ? (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M10 4H6v16h4V4zm8 0h-4v16h4V4z"></path>
                </svg>
              ) : (
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"></path>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              )}
            </button>

            <button 
              onClick={handleDiscard}
              className="bg-[#2C2C2C] text-[#FE2C55] p-3 rounded-full w-12 h-12 flex items-center justify-center hover:bg-[#353535]"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
              </svg>
            </button>
          </div>

          {/* Aspect Ratio Popup */}
          {showAspectRatioOptions && (
            <div className="absolute bottom-24 left-1/2 -translate-x-1/2 bg-[#2C2C2C] rounded-lg shadow-xl w-48 border border-white/10">
              <div className="px-4 py-2 text-xs font-medium border-b border-white/10">
                Aspect Ratio
              </div>
              <div className="p-2">
                {Object.entries(aspectRatios).map(([ratio, { label, icon }]) => (
                  <button
                    key={ratio}
                    onClick={() => handleAspectRatioChange(ratio)}
                    className={`w-full px-4 py-3 text-sm hover:bg-[#353535] rounded-md flex items-center justify-between transition-colors ${
                      selectedRatio === ratio ? 'bg-[#353535] text-[#FE2C55]' : 'text-white'
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      <div className={`${selectedRatio === ratio ? 'text-[#FE2C55]' : 'text-white'}`}>
                        {icon}
                      </div>
                      <div className="flex flex-col items-start">
                        <span className="font-medium">{label}</span>
                        <span className="text-xs text-white/60">{ratio}</span>
                      </div>
                    </div>
                    {selectedRatio === ratio && (
                      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// Apply memo after defining the component
const MemoizedVideoPreviewPage = React.memo(VideoPreviewPage);

export default MemoizedVideoPreviewPage; 