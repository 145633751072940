import React, { useState, useEffect } from 'react';
import { 
  Box,
  Alert,
  AlertTitle,
  CircularProgress,
  Container,
  Typography 
} from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

const MobileOnlyWrapper = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkDevice = () => {
      const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
      setIsMobile(mobile);
      setIsLoading(false);
    };

    checkDevice();
    window.addEventListener('resize', checkDevice);
    
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isMobile) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        bgcolor="background.default"
        p={2}
      >
        <Container maxWidth="sm">
          <Alert 
            severity="error"
            icon={<PhoneAndroidIcon />}
            sx={{
              '& .MuiAlert-message': {
                width: '100%'
              }
            }}
          >
            <AlertTitle>
              <Typography variant="h6" component="div">
                Mobile Access Only
              </Typography>
            </AlertTitle>
            <Typography variant="body1">
              This website is only accessible on mobile devices. Please visit using your smartphone or tablet.
            </Typography>
          </Alert>
        </Container>
      </Box>
    );
  }

  return children;
};

export default MobileOnlyWrapper;