import React, { useState, useEffect } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { X } from 'lucide-react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

function Cart() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [cartItems, setCartItems] = useState([]);
  const [address, setAddress] = useState(null);
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [couponCode, setCouponCode] = useState('');
  const [error, setError] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const emailid=auth.currentUser?.email;
  const [selectedItems, setSelectedItems] = useState(new Set());
  // Fetch cart items and other data
  const Navigate=useNavigate();
  const gotoaddress=()=>{
    Navigate('/AddAddress');
  }
  
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const cartRes = await fetch(`${backendURL}/userdetail/get/product/of/user/cart?email=${emailid}`);
        const cartData = await cartRes.json();
        setCartItems(cartData.items || []);
        // Select all items by default
        setSelectedItems(new Set(cartData.items?.map((_, index) => index) || []));
        
        const addressRes = await fetch(`${backendURL}/userdetail/get/user/of/address?email=${emailid}`);
        const addressData = await addressRes.json();
        setAddress(addressData.addresses || null);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchCartData();
  }, [navigate, auth]);

  // Handle remove item
  const handleRemoveItem = async (item) => {
    try {
    //   const token = await auth.currentUser?.getIdToken();
    const response = await fetch(`${backendURL}/userdetail/remove/product/cart?email=${emailid}`, {
      method: 'POST', // Use POST instead of GET
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: item.name,
        size: item.size }),
  });
      // console.log(data);
      setCartItems(prevItems => 
        prevItems.filter(cartItem => 
          !(cartItem.name === item.name && cartItem.size === item.size)
        )
      );
    } catch (err) {
      console.log("error occured while removing",err)
      setError('Failed to remove item');
    }
  };
  

  // Handle place order
  const handlePlaceOrder = () => {
   navigate('/ReviewAndPay')
  };

  // Handle apply coupon
  // const handleApplyCoupon = async () => {
  //   try {
  //     const token = await auth.currentUser?.getIdToken();
  //     const response = await fetch('http://localhost:3000/api/cart/apply-coupon', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${token}`
  //       },
  //       body: JSON.stringify({ couponCode })
  //     });
      
  //     const data = await response.json();
  //     if (data.success) {
  //       // Update cart with applied discount
  //       setCartItems(data.items);
  //     } else {
  //       setError('Invalid coupon code');
  //     }
  //   } catch (err) {
  //     setError('Failed to apply coupon');
  //   }
  // };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }
console.log(address)
const toggleItemSelection = (index) => {
  setSelectedItems(prev => {
    const newSelected = new Set(prev);
    if (newSelected.has(index)) {
      newSelected.delete(index);
    } else {
      newSelected.add(index);
    }
    return newSelected;
  });
};
  // Calculate total price
  const totalPrice = cartItems.reduce((sum, item, index) => 
    selectedItems.has(index) ? sum + (item.price * item.quantity) : sum, 0);

  return (
    <div className="min-h-screen bg-[#F5F5F7]">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-xl z-50">
        <div className="px-4 h-12 flex items-center max-w-[393px] mx-auto">
          <button 
            className="w-8 h-8 flex items-center justify-center"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack className="w-6 h-6 text-[#0066CC]" />
          </button>
          <h1 className="text-[17px] font-semibold ml-2">Your Cart</h1>
        </div>
      </header>

      <main className="pt-12 pb-24 max-w-[393px] mx-auto">
        {/* Error Message */}
        {error && (
          <div className="px-4 py-2 bg-red-100 text-red-600 text-sm">
            {error}
          </div>
        )}

        {/* Delivery Address */}
        <div className="px-4 py-3">
          <p className="text-[15px] text-gray-500">Deliver to :</p>
          <div className="mt-2 p-4 bg-white rounded-xl shadow-sm">
            <div className="flex flex-col space-y-2 justify-between items-start">
              {address.map((addr)=>(
              <div>
                <p className="text-[15px] font-medium">{addr?.addressLine1}</p>
                <p className="text-[13px] text-gray-600 mt-0.5">
                  {addr?.addressLine2}, {addr?.city}, {addr?.state} {addr?.pincode}, {addr?.country}
                </p>
                {address?.isDefault && (
                  <span className="inline-block px-2 py-0.5 bg-gray-100 text-[11px] font-medium rounded mt-1.5">
                    Default
                  </span>
                )}
              </div>))}
              <button className="text-[#FF2D55] text-[15px] font-medium">
                Change
              </button>
            </div>
          </div>
          <button className="mt-3 w-full flex items-center justify-center py-2 border-2 border-[#FF2D55] rounded-xl" onClick={gotoaddress}>
            <span className="text-[#FF2D55] text-[15px] font-medium" >+</span>
          </button>
        </div>

        {/* Selected Items */}
        <div className="mt-2">
        <div className="px-4 py-3 bg-white">
          <div className="flex items-center">
            <div className="w-5 h-5 rounded-full border-2 border-[#FF2D55] flex items-center justify-center">
              <div className="w-3 h-3 rounded-full bg-[#FF2D55]"></div>
            </div>
            <p className="ml-2 text-[15px] font-medium">
              {selectedItems.size} ITEMS SELECTED
              <span className="text-[#FF2D55]"> (${totalPrice.toFixed(2)})</span>
            </p>
          </div>
        </div>

        {cartItems.map((cartItem, index) => (
          <div key={index} className="px-4 py-3 bg-white border-t border-gray-100">
            <div className="flex gap-3">
              <button 
                onClick={() => toggleItemSelection(index)}
                className="w-5 h-5 mt-1 rounded-full border-2 border-[#FF2D55] flex items-center justify-center"
              >
                {selectedItems.has(index) && (
                  <div className="w-3 h-3 rounded-full bg-[#FF2D55]"></div>
                )}
              </button>
              <div className="flex-1">
                <div className="flex gap-3">
                  <div className="w-[72px] h-[96px] bg-gray-50 rounded-lg overflow-hidden">
                    <img 
                      src={cartItem.image} 
                      alt={cartItem.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex justify-between">
                      <div>
                        <p className="text-[13px] text-gray-500">{cartItem.sellerid}</p>
                        <h3 className="text-[15px] font-medium mt-0.5">{cartItem.name}</h3>
                      </div>
                      <button onClick={() => handleRemoveItem(cartItem)}>
                        <X className="w-5 h-5 text-gray-400" />
                      </button>
                    </div>
                    <div className="flex items-center gap-2 mt-2">
                      <span className="px-3 py-1 bg-gray-100 rounded-full text-[13px]">
                        Size: {cartItem.size}
                      </span>
                      <span className="px-3 py-1 bg-gray-100 rounded-full text-[13px]">
                        Qty: {cartItem.quantity}
                      </span>
                    </div>
                    <p className="text-[15px] font-medium mt-2">
                      ${cartItem.price.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
        {/* Coupon Code */}
        <div className="mt-4 px-4">
          <div className="flex gap-3">
            <input
              type="text"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              placeholder="Apply Coupon code"
              className="flex-1 px-4 py-3 bg-white rounded-xl text-[15px] placeholder:text-gray-400 focus:outline-none"
            />
            <button 
              // onClick={handleApplyCoupon}
              className="px-6 py-3 bg-black text-white font-medium rounded-xl"
            >
              Apply
            </button>
          </div>
        </div>

        {/* You may also like */}
        <div className="mt-6 px-4">
          <h2 className="text-[17px] font-semibold mb-3">You may also like :</h2>
          <div className="grid grid-cols-3 gap-4">
            {recommendedProducts.map(product => (
              <div key={product.id} className="space-y-2">
                <div className="aspect-square bg-white rounded-xl overflow-hidden">
                  <img 
                    src={product.image} 
                    alt={product.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h3 className="text-[13px] font-medium">{product.name}</h3>
                  <p className="text-[13px]">₹{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>

      {/* Bottom Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="max-w-[393px] mx-auto px-4 py-4">
          <div className="text-center mb-3">
            <p className="text-[15px] text-gray-600">
              {cartItems.length} item{cartItems.length !== 1 ? 's' : ''} selected for order
            </p>
          </div>
          <button 
            onClick={handlePlaceOrder}
            className="w-full py-3.5 bg-[#FF2D55] text-white font-medium rounded-xl"
          >
            PLACE ORDER
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;