import React, { useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { Home, Compass, Play, Video, User } from 'lucide-react';
import { getAuth } from 'firebase/auth';

// import scrollicon from '../Now.png'
const Navbar = () => {
  const admin=getAuth().currentUser?.email;
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  // console.log(admin)
  const Navigate=useNavigate();
  const [admindata,setAdmindata]=useState(null);
  const gettheadminname = async () => {
    if (!admin) {
        console.log("No user is logged in");
        return;
    }
    const url = `${backendURL}/userdetail/admin?email=${admin}`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Response status: ${response.status}`);
        }
        const adminjson = await response.json();
        const data = adminjson.data;
        setAdmindata(data.username);
        console.log(data.username);
        Navigate(`/userdetail/${data.username}`);
        
    } catch (error) {
        console.log("Error:", error);
    }
};
  const location = useLocation();
  
  const isActive = (path) => {
    return location.pathname === path;
  };

  const NavItem = ({ to, icon: Icon, label }) => (
    <Link 
      to={to} 
      className={`flex flex-col items-center space-y-1 transition-all duration-200 ${
        isActive(to) 
          ? 'text-white scale-110' 
          : 'text-gray-500 hover:text-gray-300'
      }`}
    >
      <Icon size={22} strokeWidth={2} />
      <span className="text-[10px] font-medium">{label}</span>
    </Link>
  );


  return (
    <div className="relative w-full ">
      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-t from-black via-black/95 to-transparent -z-10" />
      
      {/* Glass effect background */}
      <div className="p-3 rounded-2xl bg-[#1C1C1E]/70 backdrop-blur-lg">
        <nav className="flex justify-between items-center px-3">
          <NavItem to="/" icon={Home} label="Home" />
          <NavItem to="/discover" icon={Compass} label="Discover" />
          <NavItem to="/scroll" icon={Play} label="Scroll" />
          <NavItem to="/LiveStreamList" icon={Video} label="Live" />
          <div onClick={()=>gettheadminname()}><NavItem to="/profile" icon={User} label="Profile" /></div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
