import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';

const Wallet = () => {
  const navigate = useNavigate();
  const [showBetaMessage, setShowBetaMessage] = useState(true);

  return (
    <div className="min-h-screen bg-[#1c1c1e] text-white relative">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-[#1c1c1e] border-b border-white/[0.08]">
        <div className="flex items-center px-4 h-14">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 hover:bg-white/10 rounded-full transition-colors"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <h1 className="text-lg font-semibold ml-2">Wallet</h1>
        </div>
      </div>

      {/* Main Content - Blurred when popup is shown */}
      <div className={`p-6 ${showBetaMessage ? 'blur-sm' : ''}`}>
        <div className="text-center mb-8">
          <h2 className="text-[48px] font-bold mb-2">$0.00</h2>
          <p className="text-gray-400">Your current Scroll account Balance</p>
        </div>

        <div className="flex gap-4 mb-12">
          <button className="flex-1 bg-[#fe2c55] text-white py-3 rounded-lg font-medium">
            Cashout
          </button>
          <button className="flex-1 bg-white/10 text-white py-3 rounded-lg font-medium">
            Update account
          </button>
        </div>

        <div className="border-t border-white/[0.08] pt-6">
          <p className="text-gray-400 text-sm text-center">
            No transactions yet
          </p>
        </div>
      </div>

      {/* Beta Message Popup */}
      {showBetaMessage && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/70">
          <div className="bg-[#2c2c2e] rounded-xl p-6 max-w-sm w-full">
            <h3 className="text-xl font-semibold mb-3">Coming Soon!</h3>
            <p className="text-gray-300 mb-6">
              Wallet and checkouts are currently in beta testing phase. These features will be available soon. Stay tuned for updates!
            </p>
            <button
              onClick={() => navigate(-1)}
              className="w-full bg-[#fe2c55] text-white py-3 rounded-lg font-medium"
            >
              Got it
            </button>
          </div>
        </div>
      )}

      {/* Bottom Info */}
      <div className="absolute bottom-8 left-0 right-0 px-6">
        <p className="text-gray-400 text-sm text-center">
          You can use your scroll balance to buy products or cashout directly into your bank account.
        </p>
      </div>
    </div>
  );
};

export default Wallet; 