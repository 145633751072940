import React, { useState, useEffect } from 'react';
import { Heart, MessageCircle, Share2, ShoppingBag, ChevronLeft, ChevronRight, ChevronDown, X } from 'lucide-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';
import CommentSystemStyles from './CommentSystemStyles';


//stylecomponent
const StyleCard = ({ style,profiledetail,toggleLike,setShowComments,setActiveScrollId }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAllProducts, setShowAllProducts] = useState(false);

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === style.images.length - 1 ? 0 : prev + 1
    );
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? style.images.length - 1 : prev - 1
    );
  };

  const handleProductGridClick = (e) => {
    e.stopPropagation();
    setShowAllProducts(!showAllProducts);
  };
  const Navigate=useNavigate()
  const handleProductClick = (product) => {
    Navigate(`/ProductPage/${product.id}`)
    // Handle product click - navigate to product page or show details
    console.log('Product clicked:', product);
  };

  return (
    <div className="relative bg-black rounded-2xl overflow-hidden border border-white/10 mb-4">
      {/* Image Carousel */}
      <div className="aspect-[3/4] relative">
        <img
          src={style.images[currentImageIndex]}
          alt={`Style ${currentImageIndex + 1}`}
          className="w-full h-full object-cover transition-opacity duration-300"
        />
        
        {/* Navigation Arrows */}
        {style.images.length > 1 && (
          <>
            <button
              onClick={previousImage}
              className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
            >
              <ChevronLeft className="w-6 h-6 text-white" />
            </button>
            <button
              onClick={nextImage}
              className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
            >
              <ChevronRight className="w-6 h-6 text-white" />
            </button>

            {/* Image Indicators */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex gap-1">
              {style.images.map((_, index) => (
                <div
                  key={index}
                  className={`w-1.5 h-1.5 rounded-full transition-colors ${
                    currentImageIndex === index ? 'bg-white' : 'bg-white/50'
                  }`}
                />
              ))}
            </div>
          </>
        )}
        
        {/* Products Preview */}
        {style.products && style.products.length > 0 && (
          <div className="absolute bottom-4 left-4 right-4">
            <div 
              onClick={handleProductGridClick}
              className="bg-white/10 backdrop-blur-lg rounded-xl p-3 border border-white/20 cursor-pointer hover:bg-white/20 transition-colors"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <img
                    src={style.products[0].image}
                    alt={style.products[0].name}
                    className="w-12 h-12 rounded-lg object-cover"
                  />
                  <div className="flex-1 min-w-0">
                    <h4 className="text-white font-medium text-sm truncate">
                      {style.products[0].name}
                    </h4>
                    <p className="text-white/70 text-xs">
                      ₹{style.products[0].price}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-white/70 text-sm">
                    {style.products.length} items
                  </span>
                  <ChevronDown 
                    className={`w-5 h-5 text-white transition-transform ${
                      showAllProducts ? 'rotate-180' : ''
                    }`} 
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Full Product List Modal */}
        {showAllProducts && (
          <div 
            className="fixed inset-0 bg-black/80 z-50 flex items-end sm:items-center justify-center p-4"
            onClick={handleProductGridClick}
          >
            <div 
              className="bg-gray-900 rounded-2xl w-full max-w-lg max-h-[80vh] overflow-y-auto"
              onClick={e => e.stopPropagation()}
            >
              <div className="sticky top-0 bg-gray-900 p-4 border-b border-white/10 flex justify-between items-center">
                <h3 className="text-white font-semibold">Products in this style</h3>
                <button 
                  onClick={handleProductGridClick}
                  className="p-2 hover:bg-white/10 rounded-full transition-colors"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>
              <div className="p-4 space-y-3">
                {style.products.map((product, index) => (
                  <div 
                    key={index} 
                    className="bg-white/5 rounded-xl p-3 border border-white/10 hover:bg-white/10 transition-colors cursor-pointer"
                    onClick={() => handleProductClick(product)}
                  >
                    <div className="flex items-center gap-3">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-16 h-16 rounded-lg object-cover"
                      />
                      <div className="flex-1 min-w-0">
                        <h4 className="text-white font-medium text-sm truncate">
                          {product.name}
                        </h4>
                        <p className="text-white/70 text-xs mb-2">
                          ₹{product.price}
                        </p>
                        <button className="px-4 py-2 rounded-lg bg-white/10 hover:bg-white/20 transition-colors text-white text-sm flex items-center gap-2">
                          <ShoppingBag className="w-4 h-4" />
                          View Product
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Content */}
      <div className="p-4">
        <div className="flex items-center gap-3 mb-3">
          <img 
            src={profiledetail.profileimage} 
            alt={profiledetail.name}
            className="w-8 h-8 rounded-full object-cover"
          />
          <div>
            <h4 className="text-white font-medium text-sm">{profiledetail.username}</h4>
          </div>
        </div>

        <p className="text-white/90 text-sm mb-4">
          {style.description}
        </p>

        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <button 
              onClick={() => {toggleLike(); setIsLiked(!isLiked)}}
              className="group flex items-center gap-1"
            >
              <div className={`p-2 rounded-full ${
                isLiked 
                  ? 'bg-red-500/20' 
                  : 'bg-white/10 group-hover:bg-white/20'
              } transition-colors`}>
                <Heart className={`w-5 h-5 ${
                  isLiked ? 'text-red-500 fill-red-500' : 'text-white'
                }`} />
              </div>
              <span className="text-white/70 text-sm">{style.likeCount}</span>
            </button>

            <button 
              className="flex items-center gap-2 group"
              onClick={(e) => {
                e.stopPropagation();
                setActiveScrollId(style.id);
                setShowComments(true);
              }}
            >
              <MessageCircle className="w-6 h-6 text-white/80 group-hover:text-white" />
              <span className="text-white/80 group-hover:text-white text-sm font-medium">
                {style.commentCount || '0'}
              </span>
            </button>
          </div>

          <button className="p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors">
            <Share2 className="w-5 h-5 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Styles = () => {
  const [styleData, setStyleData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { username } = useParams();
  const ITEMS_PER_PAGE = 12;
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [profiledetail, setIsprofiledetail] = useState({});
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [itemId, setItemId] = useState(null);
  const [Likedata, setLikedata] = useState(null);
  const [showComments, setShowComments] = useState(false);
  const [activeScrollId, setActiveScrollId] = useState(null);
  const auth = getAuth();
 const firebase=useFirebase();
  // Profile page fetching
  const Profilepagefetching = async () => {
    const url = `${backendURL}/userdetail/${username}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const profilehtml = await response.json();
      setIsprofiledetail(profilehtml.userprofile);
    } catch (error) {
      console.error(error.message);
    }
  };
console.log(styleData);
  // Fetch styles data
  const fetchStyles = async () => {
    if (!isInitialLoad && !hasMore) {
      return;
    }

    const apiUrl = `${backendURL}/userdetail/stylesdata/${username}`;
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error("Failed to fetch styles");
      const maindata = await response.json();
      const data = maindata.scrolls;

      const stylesWithProducts = await Promise.all(
        data.map(async (style) => {
          setItemId(style.id);
          const products = await Promise.all(
            (style.productIds || []).map(async (productId) => {
              const productUrl = `${backendURL}/userdetail/Product_details?productid=${productId}`;
              const productResp = await fetch(productUrl);
              if (!productResp.ok) return null;
              const productData = await productResp.json();
              return {
                ...productData.productdetails,
                id: productId
              };
            })
          );

          return {
            ...style,
            products: products.filter(p => p !== null)
          };
        })
      );

      if (isInitialLoad) {
        setStyleData(stylesWithProducts);
        setIsInitialLoad(false);
      } else {
        const newStyles = stylesWithProducts.filter(
          newStyle => !styleData.some(existingStyle => existingStyle.id === newStyle.id)
        );
        if (newStyles.length > 0) {
          setStyleData(prev => [...prev, ...newStyles]);
        }
      }

      setHasMore(stylesWithProducts.length === ITEMS_PER_PAGE);
      setPage(prev => prev + 1);
    } catch (error) {
      console.error("Error fetching data:", error);
      setHasMore(false);
    }
  };

  // Toggle like functionality
  const toggleLike = async (e) => {
    if (!auth.currentUser) {
      alert('Please login to like this item');
      return;
    }
    const stylesId = itemId;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/userdetail/toggleLike/styles/${stylesId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: auth.currentUser.uid
        })
      });

      if (!response.ok) throw new Error('Failed to toggle like');
      const data = await response.json();
      setLikedata(data);
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };

  useEffect(() => {
    Profilepagefetching();
    setIsInitialLoad(true);
    setPage(1);
    setStyleData([]);
    setHasMore(true);
    fetchStyles();
  }, [username]);

  return (
    <div className="px-4 py-4 bg-black min-h-screen">
      <InfiniteScroll
        dataLength={styleData.length}
        next={fetchStyles}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center p-4">
            <div className="w-8 h-8 border-2 border-white/20 border-t-white rounded-full animate-spin"></div>
          </div>
        }
        endMessage={
          <div className="text-center text-white/50 py-4">
            No more styles to show
          </div>
        }
      >
        {styleData.map((style, index) => (
          <StyleCard 
            key={style.id || index} 
            style={style}
            profiledetail={profiledetail}
            toggleLike={toggleLike}
            setActiveScrollId={setActiveScrollId}
            setShowComments={setShowComments}
          />
        ))}
      </InfiniteScroll>
      {showComments && (
  <CommentSystemStyles
    stylesId={activeScrollId}
    currentUserId={firebase.currentId}
    onClose={() => setShowComments(false)}
  />
)}
    </div>
  );
};

export default Styles;