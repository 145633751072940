import React, { useEffect, useState } from 'react'
// import ProductCard from './ProductCard'
import logoimage from '../image (1).png'
import brandImage from '../brandimage.png';
import ProductCard from './ProductCard'
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
// import backgroundImage from '../assets/Rectangle 44.png'

const HomePageBrandProdcuts = ({brand}) => {
  const [isFollowing, setIsFollowing] = useState(false);
  const[productdata,setproductdata]=useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
    const fetchproductimage = async () => {
        try {
            // const serllerId=username;
            // console.log(serllerId)
            const url = `${backendURL}/userdetail/product_details/specificuser/${brand.username}`;
            const response = await fetch(url);
            if(!response.ok) {
                throw new Error('Response status');
            }
            const imagedatas = await response.json();
            console.log(imagedatas.productdetails);
            const arraofdata = imagedatas.productdetails;
            setproductdata(prevImages => {
                const uniqueImages = [...prevImages, ...arraofdata].filter(
                    (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                );
                return uniqueImages;
            });
            
        }catch(error) {
            console.log("error occured:", error);
        }
    }

    useEffect(() => {
        fetchproductimage();
    }, []);
    
   const auth=getAuth();
   const checkFollowStatus = async () => {
         const auth = getAuth();
         const currentUser = auth.currentUser?.email;
         if (!currentUser) return;
   
         try {
             const response = await fetch(`${backendURL}/userdetail/checkFollowStatus/${brand.username}`, {
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json',
                 },
                 body: JSON.stringify({ email: currentUser }),
             });
   
             if (!response.ok) throw new Error('Failed to check follow status');
             
             const data = await response.json();
             setIsFollowing(data.isFollowing);
         } catch (error) {
             console.error('Error checking follow status:', error);
         }
     };
    const handlefollowers = async () => {
      const currentUser = auth.currentUser?.email;
      if (!currentUser) return;
      // setIsLoading(true);
      try {
          const response = await fetch(`${backendURL}/userdetail/follower_following/${brand.username}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: currentUser }),
          });
  
          if (!response.ok) throw new Error('Failed to update follow status');
  
          const result = await response.json();
          
          if (result.success) {
              setIsFollowing(result.isFollowing);
              
              // Update the followers count locally
              // setIsprofiledetail(prev => ({
              //     ...prev,
              //     followers: result.isFollowing 
              //         ? [...prev.followers, auth.currentUser?.email]
              //         : prev.followers.filter(f => f !== auth.currentUser?.email)
              // }));
          }
      } catch (error) {
          console.error('Error updating follow status:', error);
      }
  };
  useEffect(()=>{checkFollowStatus()})
  console.log(isFollowing)
console.log(productdata)

  return (
    <div className='relative w-full aspect-[3/4] rounded-xl shadow-md overflow-hidden'>
      {/* Background Image */}
      <img 
        src={brand.bannerimage}
        alt="Background" 
        className='w-full h-full object-cover'
      />

      {/* Content */}
      <div className='absolute inset-0 flex flex-col'>
        {/* Brand Header */}
        <div className='flex flex-row justify-between items-start p-4'> 
          <div className='flex items-center gap-3'>
            <div className='w-12 h-12 rounded-xl overflow-hidden bg-white/90'>
              <img src={brand.profileimage} alt="Minimalist" className='w-full h-full object-contain'/>
            </div>
            <div className='flex flex-col'>
              <h3 className='text-lg font-medium text-black'>{brand.storename}</h3>
              <div className='flex items-center gap-1'>
                <span className='text-base font-medium text-black'>{brand.rating || 0}</span>
                <span className='text-lg text-yellow-400'>★</span>
              </div>
            </div>
          </div>
          <div className='flex flex-col  justify-center items-center'>
            <p className='text-black/70 text-sm mb-1'>
              {`${brand.followers.length}Followers`}
            </p>
            <button
              onClick={handlefollowers}
              className={`flex-1 py-2 px-6 rounded-full font-medium transition-all duration-300
                            ${isFollowing
                  ? 'bg-black text-white hover:bg-red-500/20 hover:text-red-500 border border-white/10'
                  : 'bg-black text-white hover:bg-[#fe2c55]/90 active:bg-[#fe2c55]/80'
                }`}
            >
              {isFollowing ? 'Unfollow' : 'Follow'}
            </button>
          </div>
        </div>

        {/* Products Scroll */}
        <div className='mt-auto p-4'>
          <div className='flex gap-3 overflow-x-auto pb-4
            [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden'>
             {productdata.map((data)=>(
              <Link to={`/ProductPage/${data.id}`}>
              <ProductCard productdata={data}/>
              </Link>
              ))}
             
                       </div>
        </div>
      </div>
    </div>
  )
}

export default HomePageBrandProdcuts
