import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { ZEGO_APP_ID, ZEGO_SERVER_SECRET } from '../config/zegoConfig';

const LiveStreamPag = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [streamCode, setStreamCode] = useState('');
  const [isJoining, setIsJoining] = useState(false);
  const [error, setError] = useState(null);
  const [showJoinPopup, setShowJoinPopup] = useState(true);

  const handleJoinStream = async () => {
    if (!streamCode.trim() || !streamCode.startsWith('stream-')) {
      setError('Please enter a valid stream code starting with "stream-"');
      return;
    }

    try {
      setIsJoining(true);
      setError(null);
      
      const userID = `viewer-${Date.now()}`;
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        ZEGO_APP_ID,
        ZEGO_SERVER_SECRET,
        streamCode,
        userID,
        'Viewer',
        3600
      );

      const zp = ZegoUIKitPrebuilt.create(kitToken);
      
      await zp.joinRoom({
        container: containerRef.current,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role: ZegoUIKitPrebuilt.Audience,
            liveStreamingMode: ZegoUIKitPrebuilt.LiveStreamingMode.RealTimeLive,
          },
        },
        showPreJoinView: false,
        showRoomTimer: false,
        showLeavingView: false,
        showUserList: false,
        showCameraButton: false,
        showMyCameraToggleButton: false,
        showMyMicrophoneToggleButton: false,
        showAudioVideoSettingsButton: false,
        showLayoutButton: false,
        showTextChat: false,
        showRemoveMeetingButton: false,
        showInviteMemberButton: false,
        showMeetingIDButton: false,
        showEndRoomButton: false,
        showScreenSharingButton: false,
        layout: "Grid",
        showNonVideoUser: false,
        showOnlyAudioUser: false,
        maxUsers: 2,
        videoConfig: {
          resolution: { width: 720, height: 1280 },
          bitrate: 1500,
          frameRate: 30,
        },
        audioConfig: {
          bitrate: 48,
          encoderConfig: {
            AEC: true,
            AGC: true,
            ANS: true,
          },
        },
        onJoinRoom: () => {
          setShowJoinPopup(false);
          setIsJoining(false);
        },
        onError: (error) => {
          console.error('Stream error:', error);
          setError('Failed to join stream. Please try again.');
          setIsJoining(false);
        }
      });
    } catch (error) {
      console.error('Error joining stream:', error);
      setError('Failed to join stream. Please try again.');
      setIsJoining(false);
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Stream Code Entry */}
      {showJoinPopup && (
        <div className="fixed inset-0 bg-black/90 backdrop-blur-lg flex items-center justify-center z-50 px-4">
          <div className="bg-white/10 backdrop-blur-xl rounded-3xl p-8 w-full max-w-sm border border-white/10">
            <h2 className="text-2xl font-semibold mb-2 text-white text-center">Join Live Stream</h2>
            <p className="text-white/70 text-sm mb-6 text-center">
              Enter the stream code to join
            </p>
            
            <div className="space-y-6">
              <div className="space-y-2">
                <input
                  type="text"
                  value={streamCode}
                  onChange={(e) => setStreamCode(e.target.value)}
                  placeholder="stream-123456789"
                  className="w-full px-6 py-4 rounded-2xl bg-white/5 border border-white/10 text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-white/20 transition-all"
                />
                <p className="text-xs text-white/50 px-2">
                  Format: stream-[numbers]
                </p>
              </div>
              
              <button
                onClick={handleJoinStream}
                disabled={isJoining || !streamCode.startsWith('stream-')}
                className="w-full bg-white/10 backdrop-blur-sm text-white py-4 rounded-2xl font-medium hover:bg-white/20 transition-all disabled:opacity-50 disabled:cursor-not-allowed border border-white/10"
              >
                {isJoining ? 'Joining...' : 'Join Stream'}
              </button>
              
              <button 
                onClick={() => navigate('/')}
                className="w-full bg-white/5 text-white/70 py-4 rounded-2xl font-medium hover:bg-white/10 transition-all border border-white/10"
              >
                Cancel
              </button>
            </div>

            {error && (
              <div className="mt-6 text-red-400/90 text-sm text-center bg-red-500/10 rounded-2xl p-4 backdrop-blur-sm">
                {error}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Video Container */}
      <div 
        ref={containerRef}
        className="w-full h-screen bg-black"
      />
    </div>
  );
};

export default LiveStreamPag;
