import React, { useState, memo } from 'react';
import UploadProfilePicture from './UploadProfilePicture';
import { useFirebase } from '../FirebaseContext/FIrebaseContext';

// Memoized form field component to prevent unnecessary re-renders

const FormField = memo(({ label, type = "text", placeholder, name, value, onChange, children }) => (
  <div className="mb-6">
    <label className="block text-gray-400 text-sm font-medium mb-2">{label}</label>
    {children ? (
      children
    ) : (
      <input 
        type={type} 
        className="w-full p-3 rounded-lg bg-[#353535] text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#FE2C55] transition-all duration-200"
        placeholder={placeholder}
        name={name}
        value={value || ''}
        onChange={onChange}
      />
    )}
  </div>
));

// Memoized UserForm component
const UserForm = memo(({ formData, handleChange, onSubmit }) => {
  const handleLocalSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  const firebase=useFirebase();

  return (
    <form className="space-y-4" onSubmit={handleLocalSubmit}>
      <input 
        type="text"
        className="w-full p-3 rounded-lg bg-[#353535] text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#FE2C55] transition-all duration-200"
        placeholder="Enter The Name"
        name="nameofperson"
        value={firebase.formData.nameofperson || ''}
        onChange={firebase.handleChange}
      />
      <p className="text-xs text-gray-400 -mt-2 mb-4">
        Add username or use our suggestions. You can change this at any time.
      </p>
      
      <div className="mb-6">
        <label className="block text-gray-400 text-sm font-medium mb-2">Username</label>
        <div className="relative">
          <input 
            type="text" 
            className="w-full p-3 rounded-lg bg-[#353535] text-white pr-10 focus:outline-none focus:ring-2 focus:ring-[#FE2C55] transition-all duration-200" 
            name="username"
            value={firebase.formData.username || ''}
            onChange={firebase.handleChange}
          />
          <svg 
            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-green-500" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
      </div>

      <FormField 
        label="State" 
        placeholder="Enter your state" 
        name="state" 
        value={firebase.formData.state} 
        onChange={firebase.handleChange}
      />
      
      <FormField 
        label="Mobile Number" 
        placeholder="+91" 
        name="mobilenumber" 
        value={firebase.formData.mobilenumber} 
        onChange={firebase.handleChange}
      />

      <div className="flex justify-center mt-8">
        <button 
          type="submit"
          className="w-1/3 bg-[#FE2C55] text-white py-3 rounded-lg hover:bg-[#e01942] transition-colors duration-200 font-medium"
        >
          Next
        </button>
      </div>
    </form>
  );
});

// Memoized SellerForm component
const SellerForm = memo(({ formData, handleChange, onSubmit }) => {
  const handleLocalSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  const firebase=useFirebase();
  return (
    <form className="space-y-6" onSubmit={handleLocalSubmit}>
      <div className="space-y-6">
        <FormField 
          label="Store Name" 
          placeholder="Enter your Store name"
          name="storename"
          value={firebase.formData.storename || ''}
          onChange={firebase.handleChange}
        />
        <p className="text-xs text-gray-400 -mt-4">
          Add store name or use our suggestions. You can change this at any time.
        </p>

        <div className="mb-6">
          <label className="block text-gray-400 text-sm font-medium mb-2">Username</label>
          <div className="relative">
            <input 
              type="text"
              name="username"
              value={firebase.formData.username || ''}
              onChange={firebase.handleChange}
              className="w-full p-3 rounded-lg bg-[#353535] text-white pr-10 focus:outline-none focus:ring-2 focus:ring-[#FE2C55] transition-all duration-200" 
            />
            <svg 
              className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-green-500" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
        </div>

        <FormField 
          label="Country" 
          name="country"
          value={firebase.formData.country || ''}
          onChange={firebase.handleChange}
          placeholder="Select your country" 
        />
        <FormField 
          label="Mobile Number" 
          name="mobilenumber"
          value={firebase.formData.mobilenumber || ''}
          onChange={firebase.handleChange}
          placeholder="+91" 
        />
      </div>

      <div className="pt-6 border-t border-gray-700">
        <h3 className="text-xl font-bold text-white mb-6">Business Information</h3>
        <div className="space-y-6">
          <FormField label="Business Type">
            <select 
              name="businesstype"
              value={firebase.formData.businesstype || ''}
              onChange={firebase.handleChange}
              className="w-full p-3 rounded-lg bg-[#353535] text-white focus:outline-none focus:ring-2 focus:ring-[#FE2C55] transition-all duration-200"
            >
              <option value="">Select Business Type</option>
              <option value="pvt-ltd">Pvt Ltd</option>
              <option value="partnership">Partnership</option>
              <option value="solo-creator">Solo Creator</option>
              <option value="llp">LLP</option>
              <option value="non-profit">Non-Profit</option>
            </select>
          </FormField>

          <FormField label="Category">
            <select 
              name="category"
              value={firebase.formData.category || ''}
              onChange={firebase.handleChange}
              className="w-full p-3 rounded-lg bg-[#353535] text-white focus:outline-none focus:ring-2 focus:ring-[#FE2C55] transition-all duration-200"
            >
              <option value="">Select Category</option>
              <option value="fashion">Fashion</option>
              <option value="electronics">Electronics</option>
              <option value="home">Home & Kitchen</option>
              <option value="beauty">Beauty & Personal Care</option>
              <option value="sports">Sports & Outdoors</option>
            </select>
          </FormField>
        </div>
      </div>

      <FormField 
        label="Store Address" 
        name="storeaddress"
        value={firebase.formData.storeaddress || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your store address" 
      />
      
      {/* Pickup Address Fields */}
      <FormField 
        label="Pickup Address Line 1" 
        name="line1"
        value={firebase.formData.line1 || ''}
        onChange={firebase.handleChange}
        placeholder="Line 1" 
      />
      <FormField 
        label="Pickup Address Line 2" 
        name="line2"
        value={firebase.formData.line2 || ''}
        onChange={firebase.handleChange}
        placeholder="Line 2" 
      />
      <FormField 
        label="City" 
        name="city"
        value={firebase.formData.city || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your city" 
      />
      <FormField 
        label="State" 
        name="state"
        value={firebase.formData.state || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your state" 
      />
      <FormField 
        label="Pincode" 
        name="pincode"
        value={firebase.formData.pincode || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your pincode" 
      />

      <p className="text-xs text-gray-400 text-left mb-4">GSTIN is optional, we don't believe in paperwork.</p>
      <FormField 
        label="GSTIN" 
        name="gstin"
        value={firebase.formData.gstin || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your GSTIN" 
      />

      {/* Bank Details Fields */}
      <FormField 
        label="Account Holder Name" 
        name="accountholdername"
        value={firebase.formData.accountholdername || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your account holder name" 
      />
      <FormField 
        label="Account Number" 
        name="accountnumber"
        value={firebase.formData.accountnumber || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your account number" 
      />
      <FormField 
        label="Bank Name" 
        name="bankname"
        value={firebase.formData.bankname || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your bank name" 
      />
      <FormField 
        label="IFSC Code" 
        name="ifsccode"
        value={firebase.formData.ifsccode || ''}
        onChange={firebase.handleChange}
        placeholder="Enter your IFSC code" 
      />

      <div className="mb-4">
        <label className="inline-flex items-center text-gray-400">
          <input type="checkbox" className="form-checkbox h-5 w-5 text-red-600" />
          <span className="ml-2">I agree to the terms of services and privacy policy</span>
        </label>
      </div>

      <div className="flex justify-center mt-8">
        <button 
          type="submit"
          className="w-1/3 bg-[#FE2C55] text-white py-3 rounded-lg hover:bg-[#e01942] transition-colors duration-200 font-medium"
        >
          Next
        </button>
      </div>
    </form>
  );
});

function SignUpForm() {
  const [userType, setUserType] = useState('User');
  const [isprofileadd, setIsprofileadd] = useState(false);
  const firebase = useFirebase();

  const handleSubmit = () => {
    // Add any validation logic here if needed
    setIsprofileadd(true);
  };

  return (
    <>
      {isprofileadd ? (
        <UploadProfilePicture />
      ) : (
        <div className="flex items-center justify-center min-h-screen bg-black p-4">
          <div className={`bg-[#2C2C2C] p-8 rounded-xl shadow-2xl w-full max-w-md relative ${userType === 'User' ? 'min-h-screen md:min-h-0' : ''}`}>
            <button className="absolute top-6 left-6 hover:opacity-75 transition-opacity">
              <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
              </svg>
            </button>

            <div className={`${userType === 'User' ? 'h-full flex flex-col' : ''}`}>
              <div className="mb-8">
                <h2 className="text-3xl font-bold text-white mb-6 text-center">Sign up as</h2>
                <div className="flex justify-center mb-4">
                  <button
                    type="button"
                    className={`py-2.5 px-6 rounded-l-full transition-all duration-200 ${userType === 'User' ? 'bg-black text-white' : 'bg-[#353535] text-gray-400'}`}
                    onClick={() => setUserType('User')}
                  >
                    User
                  </button>
                  <button
                    type="button"
                    className={`py-2.5 px-6 rounded-r-full transition-all duration-200 ${userType === 'Seller' ? 'bg-black text-white' : 'bg-[#353535] text-gray-400'}`}
                    onClick={() => setUserType('Seller')}
                  >
                    Seller
                  </button>
                </div>
                <p className="text-sm text-gray-400 text-center">
                  You can update it later, if you want to sell on our platform.
                </p>
              </div>

              <div className="flex-grow overflow-y-auto p-2">
                {userType === 'User' ? (
                  <UserForm 
                    formData={firebase.formData}
                    handleChange={firebase.handleChange}
                    onSubmit={handleSubmit}
                  />
                ) : (
                  <SellerForm 
                    formData={firebase.formData}
                    handleChange={firebase.handleChange}
                    onSubmit={handleSubmit}
                  />
                )}
              </div>

              <div className="text-xs text-gray-400 text-center mt-6 mb-4">
                By continuing, you agree to our{' '}
                <a href="#" className="text-[#FE2C55] hover:underline">Terms of Services</a>
                {' '}and acknowledge that you read our{' '}
                <a href="#" className="text-[#FE2C55] hover:underline">Privacy Policy</a>
              </div>

              <div className="mt-4 bg-black p-3 rounded-lg text-center">
                <a href="#" className="text-white hover:underline">
                  Already a user? <span className="text-[#FE2C55]">Login</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SignUpForm;