import React, { useState, useRef, useEffect } from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
import { collection, addDoc, updateDoc, doc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { ZEGO_APP_ID, ZEGO_SERVER_SECRET } from '../config/zegoConfig';

const LiveStreamHost = () => {
  const auth = getAuth();
  const db = getFirestore();
  const containerRef = useRef(null);
  const zegoInstanceRef = useRef(null);
  
  const [isStarting, setIsStarting] = useState(false);
  const [error, setError] = useState(null);
  const [streamData, setStreamData] = useState(null);
  const [deviceStatus, setDeviceStatus] = useState('checking');
  const [permissionErrors, setPermissionErrors] = useState({
    camera: false,
    microphone: false
  });

  const checkDevicePermissions = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasVideo = devices.some(device => device.kind === 'videoinput');
      const hasAudio = devices.some(device => device.kind === 'audioinput');

      if (!hasVideo || !hasAudio) {
        setPermissionErrors({
          camera: !hasVideo,
          microphone: !hasAudio
        });
        setDeviceStatus('missing');
        return;
      }

      // Request permissions
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: hasVideo, 
        audio: hasAudio 
      });
      
      // Stop tracks after checking
      stream.getTracks().forEach(track => track.stop());
      setDeviceStatus('granted');
      setPermissionErrors({ camera: false, microphone: false });
      
    } catch (error) {
      console.error('Permission check failed:', error);
      
      if (error.name === 'NotAllowedError') {
        setDeviceStatus('denied');
      } else if (error.name === 'NotFoundError') {
        setDeviceStatus('missing');
      } else {
        setDeviceStatus('error');
      }

      // Set specific error states
      setPermissionErrors({
        camera: error.name === 'NotAllowedError' || error.name === 'NotFoundError',
        microphone: error.name === 'NotAllowedError' || error.name === 'NotFoundError'
      });
    }
  };

  const requestDevicePermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ 
        video: true, 
        audio: true 
      });
      
      // Stop tracks after permission grant
      stream.getTracks().forEach(track => track.stop());
      
      setDeviceStatus('granted');
      setPermissionErrors({ camera: false, microphone: false });
    } catch (error) {
      console.error('Permission request failed:', error);
      handlePermissionError(error);
    }
  };

  const handlePermissionError = (error) => {
    if (error.name === 'NotAllowedError') {
      setDeviceStatus('denied');
      setError('Please enable camera and microphone access in your browser settings.');
    } else if (error.name === 'NotFoundError') {
      setDeviceStatus('missing');
      setError('No camera or microphone detected. Please connect your devices and try again.');
    } else {
      setDeviceStatus('error');
      setError('An unexpected error occurred while accessing your devices.');
    }
  };

  
  useEffect(() => {
    checkDevicePermissions();
    return () => {
      if (zegoInstanceRef.current) {
        zegoInstanceRef.current.destroy();
      }
      if (streamData?.id) {
        handleEndStream(streamData.id);
      }
    };
  }, []);

  const generateStreamToken = (streamId, userId, role) => {
    try {
      return ZegoUIKitPrebuilt.generateKitTokenForTest(
        ZEGO_APP_ID,
        ZEGO_SERVER_SECRET,
        streamId,
        userId,
        role,
        3600
      );
    } catch (error) {
      console.error('Token generation failed:', error);
      throw new Error('Failed to generate stream token');
    }
  };

  const createStreamDocument = async (zegoStreamId, isPrivate) => {
    try {
      return await addDoc(collection(db, 'streams'), {
        hostId: auth.currentUser.uid,
        hostName: auth.currentUser.displayName,
        startedAt: new Date(),
        isPrivate,
        status: 'live',
        viewerCount: 0,
        zegoStreamId,
        thumbnailUrl: '',
      });
    } catch (error) {
      console.error('Stream document creation failed:', error);
      throw new Error('Failed to create stream record');
    }
  };

  const initializeZegoStream = async (token, streamDoc) => {
    try {
      const zp = ZegoUIKitPrebuilt.create(token);
      zegoInstanceRef.current = zp;
      
      await zp.joinRoom({
        container: containerRef.current,
        scenario: {
          mode: ZegoUIKitPrebuilt.LiveStreaming,
          config: {
            role: ZegoUIKitPrebuilt.Host,
          }
        },
        showPreJoinView: false,
        onLeaveRoom: () => handleEndStream(streamDoc.id),
        onError: (error) => {
          console.error('Zego stream error:', error);
          setError('Stream initialization failed');
          handleEndStream(streamDoc.id);
        }
      });

      return zp;
    } catch (error) {
      console.error('Zego initialization failed:', error);
      throw new Error('Failed to initialize stream');
    }
  };

  const startStream = async (isPrivate) => {
    setError(null);
    setIsStarting(true);

    try {
      const zegoStreamId = `stream-${Date.now()}-${auth.currentUser.uid}`;
      
      // Generate token first to fail fast if config is invalid
      const token = generateStreamToken(
        zegoStreamId,
        auth.currentUser.uid,
        'Host'
      );

      // Create stream document
      const streamDoc = await createStreamDocument(zegoStreamId, isPrivate);

      // Initialize stream
      await initializeZegoStream(token, streamDoc);
      
      setStreamData({ id: streamDoc.id, zegoStreamId });
    } catch (error) {
      setError(error.message);
      console.error('Stream start failed:', error);
    } finally {
      setIsStarting(false);
    }
  };

  const handleEndStream = async (streamId) => {
    try {
      await updateDoc(doc(db, 'streams', streamId), {
        status: 'ended',
        endedAt: new Date()
      });
      
      if (zegoInstanceRef.current) {
        zegoInstanceRef.current.destroy();
        zegoInstanceRef.current = null;
      }
      
      setStreamData(null);
    } catch (error) {
      console.error('Failed to end stream:', error);
    }
  };

  if (deviceStatus === 'checking') {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="text-white">Checking device permissions...</div>
      </div>
    );
  }

  if (deviceStatus === 'denied' || deviceStatus === 'missing' || deviceStatus === 'error') {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center">
        <div className="max-w-md p-6 text-center">
          <h2 className="text-2xl text-white mb-4">Device Access Required</h2>
          <div className="space-y-4 mb-6">
            {permissionErrors.camera && (
              <p className="text-red-400">
                Camera access is {deviceStatus === 'missing' ? 'not detected' : 'blocked'}
              </p>
            )}
            {permissionErrors.microphone && (
              <p className="text-red-400">
                Microphone access is {deviceStatus === 'missing' ? 'not detected' : 'blocked'}
              </p>
            )}
          </div>
          <div className="space-y-4">
            <p className="text-gray-300">
              {deviceStatus === 'denied' 
                ? "Please allow access to your devices in your browser's settings"
                : deviceStatus === 'missing'
                ? "Please connect your camera and microphone"
                : "Please check your device connections and try again"}
            </p>
            <div className="flex space-x-4 justify-center">
              <button
                onClick={requestDevicePermissions}
                className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg"
              >
                Request Access
              </button>
              <button
                onClick={() => window.location.reload()}
                className="bg-gray-600 hover:bg-gray-700 text-white px-6 py-3 rounded-lg"
              >
                Reload Page
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900">
      {!streamData ? (
        <div className="max-w-md mx-auto p-6">
          <h2 className="text-2xl text-white mb-6">Start Streaming</h2>
          <div className="space-y-4">
            <button
              onClick={() => startStream(false)}
              className="w-full bg-blue-600 hover:bg-blue-700 transition-colors text-white p-3 rounded-lg disabled:opacity-50"
              disabled={isStarting}
            >
              {isStarting ? 'Starting Stream...' : 'Start Public Stream'}
            </button>
            <button
              onClick={() => startStream(true)}
              className="w-full bg-purple-600 hover:bg-purple-700 transition-colors text-white p-3 rounded-lg disabled:opacity-50"
              disabled={isStarting}
            >
              {isStarting ? 'Starting Stream...' : 'Start Private Stream'}
            </button>
          </div>
          {error && (
            <div className="mt-4 text-red-500 p-3 bg-red-100 rounded-lg">
              {error}
            </div>
          )}
        </div>
      ) : (
        <div ref={containerRef} className="w-full h-screen" />
      )}
    </div>
  );
};

export default LiveStreamHost;