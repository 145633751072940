import { getAuth } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward, IoMdShare, IoMdHeartEmpty, IoMdHeart, IoIosStar, IoIosStarOutline, IoIosClose } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
// import { useCart } from './contexts/CartContext';

function ProductPage() {
  
  const [productDetails, setproductdetails] = useState({
    name: '',
    rating: 0,
    price: 0,
    description: '',
    images: [],
    reviews: []
  });
  const [selectedSize, setSelectedSize] = useState('S');
  const [isSaved, setIsSaved] = useState(false);
  const [selectedType, setSelectedType] = useState('Classic');
  const [currentImage, setCurrentImage] = useState(0);
  const [showAllReviews, setShowAllReviews] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [products,setproducts]=useState({});
  const [reviews,setReviews]=useState([])
  const[cart,setcart]=useState(false);
//   const { addToCart } = useCart();
const {id}=useParams();
const {productid}=useParams();
const fetchproductdata=async ()=>{
try{
const url= `${backendURL}/userdetail/Product_details?productid=${productid}`
const resp=await fetch(url)
if(!resp.ok){
console.log("Error occured while fetching");
return;
}
const maindata=await resp.json();
// console.log(maindata);
setproductdetails(maindata.productdetails);
console.log(maindata);
setReviews(maindata.productdetails.reviews || []);
}catch(error){
  console.log("Error occured:",error);
  setReviews([]);
}
}
useEffect(()=>{fetchproductdata();},[]);
console.log(productDetails);
useEffect(() => {
  setproducts({
    id: productid,
    sellerid: productDetails.sellerId,
    name: productDetails.name,
    size: selectedSize,
    quantity: quantity,
    price: productDetails.price,  
    image: productDetails.images?.[0],
    style: selectedType,
    subtotal: productDetails.price * quantity,
    shipping: 3.00,
    total: (productDetails.price * quantity) + 3.00,
  });
}, [productDetails, selectedSize, quantity, selectedType, productid]);
// console.log(reviews[0].author);
// console.log(reviews.length);

  const sizes = ['XS', 'S', 'M', 'L', 'XL'];
  const types = [
    { name: 'Classic', image: 'https://images.unsplash.com/photo-1603252109303-2751441dd157?w=500' },
    { name: 'Slim Fit', image: 'https://images.unsplash.com/photo-1604695573706-53170668f6a6?w=500' },
    { name: 'Relaxed', image: 'https://images.unsplash.com/photo-1621072156002-e2fccdc0b176?w=500' },
  ];
  
  const images = [
    'https://images.unsplash.com/photo-1598033129183-c4f50c736f10?w=500',  // Main view
    'https://images.unsplash.com/photo-1594938298603-c8148c4dae35?w=500',  // Side view
    'https://images.unsplash.com/photo-1593030761757-71fae45fa0e7?w=500',  // Back view
    'https://images.unsplash.com/photo-1434389677669-e08b4cac3105?w=500'   // Detail view
  ];

  // const productDetails = {
  //   name: "Premium Cotton Oxford Shirt",
  //   rating: 4.8,
  //   reviews: 1247,
  //   price: 89.99,
  //   description: "Crafted from premium Egyptian cotton, this Oxford shirt combines timeless style with modern comfort. Perfect for both casual and formal occasions.",
  //   features: [
  //     "100% Egyptian Cotton",
  //     "Mother-of-pearl buttons",
  //     "Reinforced stitching",
  //     "Wrinkle-resistant fabric",
  //     "Available in Classic, Slim, and Relaxed fit"
  //   ]
  // };

  

  const handlePrevImage = () => {
    setCurrentImage(current => (current === 0 ? productDetails.images.length - 1 : current - 1));
  };

  const handleNextImage = () => {
    setCurrentImage(current => (current === productDetails.images.length - 1 ? 0 : current + 1));
  };


  const navigate = useNavigate();
  const gotoorder=()=>{
    navigate('/cart');
  }
  const renderStars = (rating) => {
    return (
      <div className="flex">
        {[...Array(5)].map((_, index) => (
          <span key={index} className={`${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}>
            ★
          </span>
        ))}
      </div>
    );
  };
// console.log(reviews.length);
  const ReviewModal = () => {
    const [rating, setRating] = useState(0);
    const [hoveredRating, setHoveredRating] = useState(0);
    const [title, setTitle] = useState('');
    const [review, setReview] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const[authdata,setauthdata]=useState(null);
    
    // const [ProductDetails,setProductDetails]=useState([]);
//fetch the data of reviews from the product collection



    const auth=getAuth();
      const fetchtheadmin=async()=>{
       const email=auth.currentUser?.email;
       console.log(email)
        const url=`${backendURL}/userdetail/admin?email=${email}`
        const response = await fetch(url);
                if(!response.ok) {
                    throw new Error('Response status');
                }
        const datas = await response.json();
        const maindata=datas.data;
        // console.log(maindata.username)
        setauthdata(maindata.username);
        // console.log(datas);
      }
      useEffect(()=>{fetchtheadmin();},[]);
      // console.log(authdata.username);
   console.log(authdata);
    const handleSubmitReview = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setError('');

      try {
        const response = await fetch(`${backendURL}/userdetail/writeareview`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            productid: productid,
            username: authdata || 'Anonymous', 
            text: review,
            rating: rating,
            title: title,
            content: review
          })
        });
        console.log(productid);

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error || 'Failed to submit review');
        }

        // Update local state with the new review
        const newReview = {
          id: data.review.id,
          author: data.review.author,
          rating: data.review.rating,
          date: 'Just now',
          title: data.review.title,
          content: data.review.content,
          helpful: 0,
          verified: true
        };

        // Add the new review to the reviews array
        setReview(prevReviews => [newReview, ...prevReviews]);

        // Close modal and reset form
        setShowReviewModal(false);
        setRating(0);
        setTitle('');
        setReview('');

        // Optionally update the product's overall rating and review count
        setproductdetails(prev => ({
          ...prev,
          reviews: prev.reviews + 1,
          rating: ((prev.rating * prev.reviews) + rating) / (prev.reviews + 1)
        }));

      } catch (error) {
        console.error('Error submitting review:', error);
        setError(error.message || 'Failed to submit review. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    };
    // console.log(reviews.length);

    return (
      <div className="fixed inset-0 bg-black/50 z-50 flex items-end">
        <div className="bg-white w-full rounded-t-2xl max-h-[85vh] overflow-auto">
          <div className="sticky top-0 bg-white px-4 py-3 border-b border-gray-200">
            <div className="flex justify-between items-center">
              <button 
                onClick={() => setShowReviewModal(false)}
                className="text-[#0066CC] text-base font-normal"
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <h3 className="text-lg font-medium">Write a Review</h3>
              <button 
                onClick={handleSubmitReview}
                disabled={!rating || isSubmitting}
                className={`text-base font-normal ${
                  rating && !isSubmitting
                    ? 'text-[#0066CC]' 
                    : 'text-gray-300'
                }`}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
          
          {error && (
            <div className="px-4 py-2 bg-red-50 text-red-600 text-sm">
              {error}
            </div>
          )}
          
          <div className="p-4 space-y-6">
            {/* Product Info */}
            <div className="flex gap-3">
              <div className="w-16 h-16 rounded-lg bg-gray-50 overflow-hidden">
                <img 
                  src={images[0]}
                  alt={productDetails.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div>
                <h4 className="font-medium">{productDetails.name}</h4>
                <p className="text-sm text-gray-600 mt-1">
                  Size: {selectedSize} • Style: {selectedType}
                </p>
              </div>
            </div>

            {/* Rating Selection */}
            <div className="space-y-2">
              <label className="block text-sm text-gray-600">Overall Rating</label>
              <div className="flex gap-2">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    onMouseEnter={() => setHoveredRating(star)}
                    onMouseLeave={() => setHoveredRating(0)}
                    onClick={() => setRating(star)}
                    className="text-2xl text-yellow-400"
                  >
                    {star <= (hoveredRating || rating) ? (
                      <IoIosStar />
                    ) : (
                      <IoIosStarOutline />
                    )}
                  </button>
                ))}
              </div>
            </div>

            {/* Review Title */}
            <div className="space-y-2">
              <label className="block text-sm text-gray-600">Title (Optional)</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Summary of your experience"
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#0066CC]"
              />
            </div>

            {/* Review Content */}
            <div className="space-y-2">
              <label className="block text-sm text-gray-600">Review</label>
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="What did you like or dislike? How was the fit?"
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#0066CC] resize-none"
              />
            </div>

            {/* Guidelines */}
            <div className="text-xs text-gray-500 space-y-1">
              <p>Your review should:</p>
              <ul className="list-disc pl-4 space-y-1">
                <li>Be about your own experience</li>
                <li>Focus on the product's features and quality</li>
                <li>Be honest and helpful to other shoppers</li>
                <li>Follow our community guidelines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  const handleAddToCart = async () => {
    try {
      console.log(products);
      // Create a product object based on selected detail
     const auth=getAuth();

      // Get the account holder's email from the current user's details (replace this as needed)
      const accountHolderEmail = auth.currentUser?.email; // Replace `currentUser` with your user context/provider
  
      // Make a POST request to your backend
      const response = await fetch(`${backendURL}/userdetail//add/product/cart?email=${accountHolderEmail}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(products),
      });
  
      if (!response.ok) {
        throw new Error('Failed to add item to cart');
      }
      setcart(true);
  
      const cartData = await response.json();
  
      // Log or handle the updated cart data
      console.log('Cart updated:', cartData);
  
      // Navigate to the cart page
      
    } catch (error) {
      console.error('Error adding item to cart:', error.message);
      alert('Failed to add item to cart. Please try again.');
    }
  };
  
  // console.log(reviews.length);

  return (
    <div className="min-h-screen bg-[#f5f5f7]">
      {/* Updated Header */}
      <header className="fixed top-0 left-0 right-0 bg-white/80 backdrop-blur-xl z-50 border-b border-gray-200">
        <div className="px-4 h-14 flex items-center max-w-screen-md mx-auto">
          <button 
            className="w-10 h-10 -ml-2 flex items-center justify-center"
            onClick={() => window.history.back()}
          >
            <IoIosArrowBack className="w-6 h-6 text-[#0066CC]" />
          </button>
          <div className="flex items-center gap-2 ml-1">
            <div className="w-8 h-8 rounded-lg overflow-hidden">
              <img 
                src={productDetails.image}
                alt="Brand Logo"
                className="w-full h-full object-cover"
              />
            </div>
            <span className="font-medium text-sm">{productDetails.name}</span>
          </div>
        </div>
      </header>

      {/* Updated Main Content */}
      <main className="pt-14 pb-24 max-w-screen-md mx-auto">
        {/* Updated Product Images Slider */}
        <div className="px-4 mt-4 space-y-3">
          {/* Main Image */}
          <div className="relative">
            <div className="bg-white rounded-3xl overflow-hidden aspect-square">
              <img 
                src={productDetails.images[currentImage]} 
                alt={`Product view ${currentImage + 1}`} 
                className="w-full h-full object-contain"
              />
              
              {/* Navigation Arrows */}
              <button 
                onClick={handlePrevImage}
                className="absolute left-4 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white/80 flex items-center justify-center shadow-lg"
              >
                <IoIosArrowBack className="w-6 h-6 text-gray-700" />
              </button>
              <button 
                onClick={handleNextImage}
                className="absolute right-4 top-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white/80 flex items-center justify-center shadow-lg"
              >
                <IoIosArrowForward className="w-6 h-6 text-gray-700" />
              </button>

              {/* Hot Deal Overlay */}
              <div className="absolute bottom-4 left-4">
                <div className="px-4 py-1.5 bg-red-500 text-white text-sm rounded-full">
                  Hot Deal 🔥
                </div>
              </div>
            </div>
          </div>

          {/* Thumbnail Gallery - Now outside and below the main image */}
          <div className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide">
            {productDetails.images.map((img, index) => (
              <button
                key={index}
                onClick={() => setCurrentImage(index)}
                className={`flex-shrink-0 rounded-xl overflow-hidden border-2 transition-all
                  ${currentImage === index ? 'border-[#0066CC] shadow-md' : 'border-gray-200'}`}
              >
                <div className="w-16 h-16">
                  <img 
                    src={img}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Product Info Section */}
        <div className="px-4 pt-6 space-y-6">
          <div className="flex items-start justify-between">
            <div>
              <h1 className="text-2xl font-semibold">{productDetails.name}</h1>
              <div className="flex items-center gap-2 mt-1">
                <div className="flex items-center">
                  <span className="text-sm text-gray-500">{productDetails.rating}</span>
                  <div className="flex text-yellow-400 ml-1">★★★★★</div>
                </div>
                <span className="text-sm text-gray-500"> {Array.isArray(reviews) ? reviews.length : 0} reviews</span>
              </div>
            </div>
            <div className="flex gap-2">
              <button 
                className="w-10 h-10 rounded-full bg-white shadow-sm flex items-center justify-center"
                onClick={() => setIsSaved(!isSaved)}
              >
                {isSaved ? 
                  <IoMdHeart className="w-6 h-6 text-red-500" /> : 
                  <IoMdHeartEmpty className="w-6 h-6 text-gray-400" />
                }
              </button>
              <button className="w-10 h-10 rounded-full bg-white shadow-sm flex items-center justify-center">
                <IoMdShare className="w-6 h-6 text-gray-400" />
              </button>
            </div>
          </div>

          {/* Price and Quantity */}
          <div className="flex items-center justify-between">
            <div className="flex items-baseline gap-2">
              <span className="text-2xl font-semibold">₹{productDetails.price}</span>
              <span className="text-sm text-green-600">Free Shipping</span>
            </div>
            {/* Quantity Selector */}
            <div className="flex items-center gap-3 bg-gray-100 px-3 py-1.5 rounded-full">
              <button 
                onClick={() => setQuantity(q => Math.max(1, q - 1))}
                className="w-6 h-6 flex items-center justify-center text-gray-500"
              >
                −
              </button>
              <span className="text-base min-w-[20px] text-center">{quantity}</span>
              <button 
                onClick={() => setQuantity(q => q + 1)}
                className="w-6 h-6 flex items-center justify-center text-gray-500"
              >
                +
              </button>
            </div>
          </div>

          {/* Size Selector */}
          <div>
            <h3 className="text-lg font-semibold mb-3">Select Size</h3>
            <div className="flex gap-3">
              {sizes.map(size => (
                <button
                  key={size}
                  className={`w-12 h-12 rounded-xl flex items-center justify-center border-2 transition-colors
                    ${selectedSize === size 
                      ? 'border-[#0066CC] bg-[#0066CC]/5 text-[#0066CC]' 
                      : 'border-gray-200 text-gray-600'
                    }`}
                  onClick={() => setSelectedSize(size)}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>

          {/* Type Selector */}
          {/* <div>
            <h3 className="text-lg font-semibold mb-3">Select Style</h3>
            <div className="flex gap-3 overflow-x-auto pb-2">
              {types.map(type => (
                <button
                  key={type.name}
                  className={`flex-shrink-0 rounded-xl border-2 transition-all
                    ${selectedType === type.name 
                      ? 'border-[#0066CC]' 
                      : 'border-gray-200'
                    }`}
                  onClick={() => setSelectedType(type.name)}
                >
                  <div className="w-24 h-24 rounded-t-lg overflow-hidden">
                    <img 
                      src={type.image}
                      alt={type.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="p-2 text-sm text-center">{type.name}</div>
                </button>
              ))}
            </div>
          </div> */}

          {/* Product Details */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Product Details</h3>
            <p className="text-gray-600 leading-relaxed">
              {productDetails.description}
            </p>
            {/* <ul className="space-y-2 text-gray-600">
              {productDetails.features.map((feature, index) => (
                <li key={index}>• {feature}</li>
              ))}
            </ul> */}
          </div>
        </div>
      </main>

      {/* Reviews Section - adjusted padding and spacing */}
      <div className="px-3 pt-6 pb-24">
        <div className="space-y-4">
          {/* Reviews Header */}
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-lg font-semibold">Customer Reviews</h3>
              <div className="flex items-center gap-2 mt-1">
                <div className="flex text-yellow-400 text-base">★★★★★</div>
                <span className="text-sm text-gray-500">
                  {productDetails.rating} out of 5
                </span>
              </div>
              <p className="text-sm text-gray-500 mt-1">
                Based on Based on {Array.isArray(reviews) ? reviews.length : 0} reviews
              </p>
            </div>
            <button 
              onClick={() => setShowAllReviews(!showAllReviews)}
              className="text-[#0066CC] text-sm font-medium"
            >
              {showAllReviews ? 'Show Less' : 'See All'}
            </button>
          </div>

          {/* Reviews List - adjusted spacing */}
          <div className="space-y-4">
            {/* {console.log(rev)} */}
          {!Array.isArray(reviews) || reviews.length === 0? (
    <p className="text-gray-500 text-center py-4">No reviews yet. Be the first to review!</p>
  ) :(showAllReviews ? reviews : reviews?.slice(0, 2)).map((review) => (
             <div key={review.id} className="space-y-2 pb-4 border-b border-gray-200 last:border-0">
             <div className="flex items-center justify-between">
               <div className="flex items-center gap-2">
                 <span className="font-medium text-sm">{review.author}</span>
                 {review.verified && (
                   <span className="text-xs px-1.5 py-0.5 bg-blue-50 text-blue-600 rounded-full">
                     Verified
                   </span>
                 )}
               </div>
               <span className="text-xs text-gray-500">{review.date}</span>
             </div>
             
             <div className="flex items-center gap-2">
               {renderStars(review.rating)}
               <h4 className="font-medium text-sm">{review.title}</h4>
             </div>
             
             <p className="text-gray-600 text-sm leading-relaxed">
               {review.content}
             </p>
             
             <div className="flex items-center gap-3 pt-1">
               <button 
                 className="flex items-center gap-1.5 text-xs text-gray-500 hover:text-gray-700"
                 onClick={() => {/* Add helpful functionality */}}
               >
                 <svg className="w-3.5 h-3.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                 </svg>
                 <span>Helpful ({review.helpful})</span>
               </button>
               <button className="text-xs text-[#0066CC]">
                 Report
               </button>
             </div>
           </div>
            ))}
          </div>

          {/* Write Review Button */}
          <button 
            onClick={() => setShowReviewModal(true)}
            className="w-full py-2.5 rounded-xl bg-[#0066CC] text-white text-sm font-medium hover:bg-[#0055AA] active:bg-[#004499]"
          >
            Write a Review
          </button>
        </div>
      </div>

      {/* Updated Bottom Bar */}
      <div className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-xl border-t border-gray-200">
        <div className="max-w-screen-md mx-auto px-4 py-3 flex gap-3">
          <button 
            onClick={handleAddToCart}
            className="flex-1 py-3 rounded-xl border-2 border-gray-300 text-gray-700 font-medium bg-gray-50 hover:bg-gray-100 active:bg-gray-200"
          >
            <p>Add to Cart</p>
            
          </button>
          {cart?<button 
            onClick={gotoorder}
            className="flex-1 py-3 rounded-xl border-2 border-gray-300 text-gray-700 font-medium bg-gray-50 hover:bg-gray-100 active:bg-gray-200"
          >
            <p>Go to Cart</p>
            
          </button>:null}
          <button 
            onClick={() => navigate('/ReviewAndPay', { 
              state: { 
                items: [{
                  id: Date.now(),
                  name: productDetails.name,
                  brand: "Louis Vuitton",
                  size: selectedSize,
                  quantity: quantity,
                  price: productDetails.price,
                  image: productDetails.images[0],
                  style: selectedType
                }],
                subtotal: productDetails.price * quantity,
                shipping: 3.00,
                total: (productDetails.price * quantity) + 3.00,
                fromCart: false
              }
            })}
            className="flex-1 py-3 rounded-xl bg-[#FE2C55] text-white font-medium hover:bg-[#ff1a45] active:bg-[#e01442]"
          >
            Buy Now
          </button>
        </div>
      </div>

      {showReviewModal && <ReviewModal />}
    </div>
  );
}

export default ProductPage;
